/**
 * Naming Pattern: REQUEST and RECEIVE namespaces
 *
 * @example REQUEST_SOMETHING
 * @example RECEIVE_SOMETHING_SUCCESS
 * @example RECEIVE_SOMETHING_ERROR
 * @example POST - createSomething
 * @example PUT - updateSomething
 * @example DELETE - deleteSomething
 *
 */

export const RECEIVE_PRODUCT = "RECEIVE_PRODUCT";
export const RECEIVE_GALLERY = "RECEIVE_GALLERY";
export const CLEAN_PRODUCT = "CLEAN_PRODUCT";
export const CLEAR_GALLERY = "CLEAR_GALLERY";
export const SET_REVIEWS = "SET_REVIEWS";
