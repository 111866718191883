class MetaInfo {
    constructor() {
        this._brandName = "";
        this._pagesMeta = {};
        this._domain = "ru";
    }

    /**
     * Set name of brand
     * @param { String } name
     */
    setBrandName(name) {
        this._brandName = name;
        this._domain = name === "zavtra" ? "co" : "ru";
    }

    /**
     * Set meta data for all pages
     * @param { Object } data
     */
    setMetaData(data) {
        this._pagesMeta = data;
    }

    /**
     * Get page meta info
     * @param { String } pageName
     * @returns { Object }
     */
    getMeta(pageName) {
        if (!this._pagesMeta[pageName]) {
            console.warn("Pages meta should not be empty");
            return;
        }
        const { title, description, slug } = this._pagesMeta[pageName];
        return {
            title,
            meta: [
                this._getTitleTemplate(title, "og:"),
                this._getDescriptionTemplate(description),
                this._getDescriptionTemplate(description, "og:"),
                this._getUrlTemplate(slug, "og:"),
                {
                    vmid: "og:type",
                    property: "og:type",
                    content: "website",
                },
                {
                    property: "og:locale",
                    content: "ru_RU",
                },
                this._getImageTemplate(
                    `https://${this._brandName}.${this._domain}/share.jpg`,
                    "og:"
                ),
                ...this._getTwitterMeta({ title, description }),
            ],
            ...this._getCanonicalUrl(slug),
        };
    }

    /**
     * Get meta info for product page
     * @param { String } title
     * @param { String } description
     * @param { String } slug
     * @param { String } imageUrl
     * @param { String } imageUrlForVk
     * @returns { Object }
     */
    getProductMeta({
        title,
        description,
        slug,
        imageUrl = `https://${this._brandName}.${this._domain}/share.jpg`,
        imageUrlForVk = imageUrl,
    }) {
        return {
            title,
            meta: [
                this._getTitleTemplate(title, "og:"),
                this._getDescriptionTemplate(description, ""),
                this._getDescriptionTemplate(description, "og:"),
                {
                    vmid: "og:type",
                    property: "og:type",
                    content: "article",
                },
                this._getUrlTemplate(slug, "og:", true),
                this._getImageTemplate(imageUrl, "og:"),
                ...this._getTwitterMeta({ title, description, imageUrl }),
                {
                    property: "vk:image",
                    content: imageUrlForVk,
                },
            ],
            ...this._getCanonicalUrl(slug),
        };
    }

    getErrorMeta() {
        const errorTitle = "Ошибка - 404";
        return {
            title: errorTitle,
            meta: [
                this._getTitleTemplate(errorTitle, "og:"),
                this._getImageTemplate(
                    `https://${this._brandName}.${this._domain}/share.jpg`,
                    "og:"
                ),
            ],
        };
    }

    /**
     * Get meta info for Twitter
     * @param { String } title
     * @param { String } description
     * @param { String } imageUrl
     * @private
     */
    _getTwitterMeta({
        title,
        description,
        imageUrl = `https://${this._brandName}.${this._domain}/share.jpg`,
    }) {
        return [
            {
                name: "twitter:card",
                content: `summary_large_image`,
            },
            this._getTitleTemplate(title, "twitter:"),
            this._getDescriptionTemplate(description, "twitter:"),
            this._getImageTemplate(imageUrl, "twitter:"),
        ];
    }

    /**
     * Get template for title meta
     * @param { String } title
     * @param { String } prefix ["og:", "twitter:"]
     * @returns { Object }
     * @private
     */
    _getTitleTemplate(title, prefix = "") {
        const key = prefix === "og:" ? "property" : "name";
        return {
            vmid: `${prefix}title`,
            [key]: `${prefix}title`,
            content: title,
        };
    }

    /**
     * Get template for description meta
     * @param { String } description
     * @param { String } prefix ["og:", "twitter:"]
     * @returns { Object }
     * @private
     */
    _getDescriptionTemplate(description, prefix = "") {
        const key = prefix === "og:" ? "property" : "name";
        return {
            vmid: `${prefix}description`,
            [key]: `${prefix}description`,
            content: description,
        };
    }

    /**
     * Get template for image url meta
     * @param { String } imageUrl
     * @param { String } prefix ["og:", "twitter:"]
     * @returns { Object }
     * @private
     */
    _getImageTemplate(imageUrl, prefix = "") {
        const key = prefix === "og:" ? "property" : "name";
        return {
            vmid: `${prefix}image`,
            [key]: `${prefix}image`,
            content: imageUrl,
        };
    }

    /**
     * Get template for url meta
     * @param { String } slug
     * @param { String } prefix ["og:", "twitter:"]
     * @returns { Object }
     * @private
     */
    _getUrlTemplate(slug, prefix = "") {
        return {
            vmid: `${prefix}url`,
            property: `${prefix}url`,
            content: `https://${this._brandName}.${this._domain}/${slug}`,
        };
    }

    /**
     * Get canonical url
     * @param { String } slug
     * @returns { Object }
     * @private
     */
    _getCanonicalUrl(slug) {
        return {
            link: [
                {
                    rel: "canonical",
                    href: `https://${this._brandName}.${this._domain}/${slug}`,
                },
            ],
        };
    }
}

export const metaInfo = new MetaInfo();
