<script>
    import TheSvgIcon from "@frontend/the-svg-icon";

    export default {
        name: "BaseButton",
        components: { TheSvgIcon },
        props: {
            icon: String,
            variant: {
                type: String,
                default: "v1",
                validator: (value) => {
                    return ["v1", "v2", "v3"].includes(value);
                },
            },
            size: {
                type: String,
                default: "sm",
                validator: (value) => {
                    return ["sm", "md", "lg"].includes(value);
                },
            },
            tag: {
                type: String,
                default: "button",
                validator: (v) => ["a", "button", "div"].includes(v),
            },
            href: String,
            disabled: Boolean,
            isLoading: Boolean,
            isSelected: Boolean,
        },
        computed: {
            className() {
                return this.componentClassNames("base-button").filter(Boolean);
            },
            hasSlots() {
                return Object.keys(this.$slots).length > 0;
            },
        },
        methods: {
            clickHandler(event) {
                this.$emit("click", event);
            },
            componentClassNames(className) {
                return [
                    this.variant ? `${className}--${this.variant}` : "",
                    this.size ? `${className}--${this.size}` : "",
                    this.disabled ? `${className}--disabled` : "",
                    this.isLoading ? `${className}--loading` : "",
                    this.isSelected ? `${className}--selected` : "",
                ];
            },
        },
    };
</script>

<template>
    <component
        :is="tag"
        :href="href"
        class="base-button outline"
        :class="className"
        @click="clickHandler"
    >
        <the-svg-icon
            v-if="icon"
            class="base-button__icon"
            :class="{ 'base-button__icon--single': !hasSlots }"
            :size-w="24"
            :name="icon"
        ></the-svg-icon>
        <slot></slot>
        <preloader :show="isLoading" sm-size></preloader>
    </component>
</template>

<style lang="scss">
    .base-button {
        position: relative;
        font-family: inherit;
        font-size: var(--btn-size);
        font-weight: var(--btn-weight);
        line-height: 24px;
        border: var(--btn-border-width) solid transparent;
        cursor: pointer;
        transition: opacity 0.2s ease-in, color 0.2s ease-in, border-color 0.2s ease-in,
            background-color 0.2s ease-in;

        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: var(--btn-border-radius);
    }

    .base-button__icon {
        margin-right: 4px;
    }

    //
    // --mod
    //

    // variant
    .base-button--v1 {
        border-color: var(--btn-accent-color--v1);
        color: var(--btn-color--v1);
        background-color: var(--btn-bg--v1);

        &:hover {
            border-color: var(--btn-accent-color--v1_hover);
            color: var(--btn-color--v1_hover);
            background-color: var(--btn-bg--v1_hover);
        }

        &:active {
            border-color: var(--btn-accent-color--v1_active);
            color: var(--btn-color--v1_active);
            background-color: var(--btn-bg--v1_active);
        }
    }

    .base-button--v2 {
        border-color: var(--btn-accent-color--v2);
        color: var(--btn-color--v2);
        background-color: var(--btn-bg--v2);

        &:hover {
            border-color: var(--btn-accent-color--v2_hover);
            color: var(--btn-color--v2_hover);
            background-color: var(--btn-bg--v2_hover);
        }

        &:active {
            border-color: var(--btn-accent-color--v2_active);
            color: var(--btn-color--v2_active);
            background-color: var(--btn-bg--v2_active);
        }
    }

    .base-button--v3 {
        border-color: var(--btn-accent-color--v3);
        color: var(--btn-color--v3);
        background-color: var(--btn-bg--v3);

        &:hover {
            border-color: var(--btn-accent-color--v3_hover);
            color: var(--btn-color--v3_hover);
            background-color: var(--btn-bg--v3_hover);
        }

        &:active {
            border-color: var(--btn-accent-color--v3_active);
            color: var(--btn-color--v3_active);
            background-color: var(--btn-bg--v3_active);
        }
    }

    // size
    .base-button--sm {
        font-size: var(--btn-size--sm);
        padding: var(--btn-padding--sm);
    }

    .base-button--md {
        padding: var(--btn-padding--md);
    }

    .base-button--lg {
        font-size: var(--btn-size--lg);
        padding: var(--btn-padding--lg);
    }

    // other
    .base-button__icon--single {
        margin-right: 0;
    }

    .base-button--disabled {
        opacity: 0.8;
        cursor: initial;
    }

    .base-button--selected {
        &.base-button--v1 {
            border-color: var(--btn-accent-color--v1_selected) !important;
            color: var(--btn-color--v1_hover) !important;
            background-color: var(--btn-accent-color--v1_selected) !important;

            &:hover {
                border-color: var(--btn-accent-color--v1_selected-hover) !important;
                color: var(--btn-color--v1_hover) !important;
                background-color: var(--btn-accent-color--v1_selected-hover) !important;
            }
        }

        &.base-button--v2 {
            border-color: var(--btn-accent-color--v2_selected) !important;
            color: var(--btn-color--v2_hover) !important;
            background-color: var(--btn-accent-color--v2_selected) !important;

            &:hover {
                border-color: var(--btn-accent-color--v2_selected-hover) !important;
                color: var(--btn-color--v2_hover) !important;
                background-color: var(--btn-accent-color--v2_selected-hover) !important;
            }
        }

        &.base-button--v3 {
            border-color: var(--btn-accent-color--v3_selected) !important;
            color: var(--btn-color--v3_hover) !important;
            background-color: var(--btn-accent-color--v3_selected) !important;

            &:hover {
                border-color: var(--btn-accent-color--v3_selected-hover) !important;
                color: var(--btn-color--v3_hover) !important;
                background-color: var(--btn-accent-color--v3_selected-hover) !important;
            }
        }
    }

    .base-button--loading {
        color: transparent !important;
        pointer-events: none;

        &.base-button--selected {
            color: transparent !important;
        }

        svg {
            fill: transparent;
        }
    }
</style>
