export default function ($api) {
    return {
        getCategoryNames() {
            return $api.get("api/section/all");
        },
        getBlog(data) {
            return $api.get(`api/article/all`, data);
        },
        getOtherBlog(id) {
            let customFilter = id ? `filters[id][neq]=${id}&` : "";
            return $api.get(`api/article/all?${customFilter}paging[per_page]=4`);
        },
        getDetailBlog(slug) {
            return $api.get(`api/article/one?filters[slug][eq]=${slug}`);
        },
        getProductsForBlog(ids) {
            let query = "";
            ids.forEach((id, i) => {
                query += `${i === 0 ? "" : "&"}filters[id][in][]=${id}`;
            });
            return $api.get(`api/product/all?${query}&filters[eol][null]=1`);
        },
        getCatalogForId({ id, sort }) {
            let options = sort ? `&sorts=${sort}` : "";
            return $api.get(
                `api/product/all?filters[categoriesIds][eq]=${id}&filters[available][eq]=1&filters[eol][null]=1${options}&paging[per_page]=30`
            );
        },
        getCatalogTB({ listCategories }) {
            //eol фильтр стоит на backend
            return $api.get(`api/product/categories-products/?${listCategories}`);
        },
        getSearch({ term }) {
            return $api.get(
                encodeURI(
                    `api/product/search?term=${term}&filters[available][eq]=1&paging[per_page]=30`
                )
            );
        },
        getBreadCrumbs({ mainCategory }) {
            return $api.get(
                `api/section/all?filters[depthLevel][lte]=${mainCategory.depthLevel}&filters[rightMargin][gte]=${mainCategory.rightMargin}&filters[leftMargin][lte]=${mainCategory.leftMargin}&sorts[leftMargin]=asc`
            );
        },
    };
}
