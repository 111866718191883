import RootApi from "./requests/root";
import OptionalApi from "./requests/optional";
import CartApi from "./requests/cart";
import ProductsApi from "./requests/products";
import OrderApi from "./requests/order";
import ProfileApi from "./requests/profile";
import XdApi from "./requests/xd";
import PicoocApi from "./requests/picooc";
import TbApi from "./requests/travel-blue";
import MiKuApi from "./requests/mi-ku";

export default {
    install({ store, api }) {
        // Регистрация api
        store.rootApi = RootApi(api);
        store.optionalApi = OptionalApi(api);
        store.cartApi = CartApi(api);
        store.productsApi = ProductsApi(api);
        store.xdApi = XdApi(api);
        store.orderApi = OrderApi(api);
        store.profileApi = ProfileApi(api);
        store.picoocApi = PicoocApi(api);
        store.tbApi = TbApi(api);
        store.mikuApi = MiKuApi(api);
    },
};
