import { isMobile } from "@/utils/index";

const stickyScroll = ({
    el,
    elWrap,
    topMargin = 0,
    classNameDivider = "js-divider-stop",
    calcWidth = true,
    startPoint = 0,
    isParentClass = false,
}) => {
    let classNameStop = "stop-scroll-block";
    let classNameFix = "fix-scroll-block";
    let classNameFixParent = "fix-scroll-block-parent";

    if (calcWidth) elWrap.style.cssText = `width: ${el.offsetWidth}px;`;

    el.style.height = elWrap.getBoundingClientRect().height + "px";

    let dividerStop = document
        .querySelector(`.${classNameDivider}`)
        .getBoundingClientRect().top;

    let positionEl = el.getBoundingClientRect(),
        R = Math.round(positionEl.top + elWrap.getBoundingClientRect().height - dividerStop);

    // if (positionEl.top - topMargin + startPoint <= -1)
    if (positionEl.top - topMargin + startPoint <= 0) {
        if (positionEl.top - topMargin <= R) {
            elWrap.classList.add(classNameStop);
            elWrap.classList.remove(classNameFix);
            if (isParentClass) el.parentNode.classList.remove(classNameFixParent);
            elWrap.style.top = -R + "px";
        } else {
            if (isParentClass) el.parentNode.classList.add(classNameFixParent);
            elWrap.classList.add(classNameFix);
            elWrap.classList.remove(classNameStop);
            elWrap.style.top = topMargin + "px";
        }
    } else {
        elWrap.classList.remove(classNameStop);
        elWrap.classList.remove(classNameFix);
        if (isParentClass) el.parentNode.classList.remove(classNameFixParent);
        elWrap.style.top = "";
    }
};

class ScrollState {
    constructor() {
        this.numberOpen = 0;
        this.timeout = 200;
    }

    disable() {
        if (typeof window !== "undefined") {
            if (isMobile()) {
                this.mobileDisable();
            } else {
                this.desktopDisable();
            }
        }
    }

    enable() {
        if (typeof window !== "undefined") {
            if (isMobile()) {
                this.mobileEnable();
            } else {
                this.desktopEnable();
            }
        }
    }

    desktopDisable() {
        const offsetY = window.pageYOffset || document.documentElement.scrollTop;
        window.onscroll = function () {
            window.scrollTo(0, offsetY);
        };
        this.addStyle();
    }

    desktopEnable() {
        window.onscroll = null;
        this.removeStyle();
    }

    increaseNumberOpen() {
        this.numberOpen++;
    }

    decreaseNumberOpen() {
        this.numberOpen--;
    }

    resetNumberOpen() {
        this.numberOpen = 0;
    }

    mobileDisable() {
        const offsetY =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            Math.abs(document.body.getBoundingClientRect().top);
        document.body.dataset.topPosition = offsetY;
        document.body.style.position = "fixed";
        document.body.style.top = `-${offsetY}px`;
        document.body.style.overflow = "hidden";
        document.body.style.width = "100%";
        this.increaseNumberOpen();
    }

    mobileEnable() {
        if (this.numberOpen > 1) {
            this.decreaseNumberOpen();
            return false;
        }
        this.resetNumberOpen();
        document.body.style.position = null;
        document.body.style.top = null;
        document.body.style.overflow = null;
        document.body.style.width = null;
        window.scrollTo(0, document.body.dataset.topPosition);
        setTimeout(() => {
            document.body.removeAttribute("data-top-position");
        }, 100);
    }

    addStyle() {
        const elements = document.querySelectorAll("[data-fix-on-scroll]");
        elements.forEach((elem) => {
            if (elem.classList.contains("fix-scroll-block")) {
                elem.style.width = `calc(100% - ${this.getScrollbarWidth()}px)`;
            }
        });
        // elements.forEach((element) => (element.style.right = this.getScrollbarWidth() + "px"));
        document.body.style.paddingRight = this.getScrollbarWidth() + "px";
        document.body.classList.add("modal-is-open");
    }

    removeStyle() {
        setTimeout(() => {
            const elements = document.querySelectorAll("[data-fix-on-scroll]");
            // elements.forEach((element) => (element.style.right = null));
            elements.forEach((elem) => (elem.style.width = "100%"));
            document.body.style.paddingRight = null;
            document.body.classList.remove("modal-is-open");
        }, this.timeout / 2);
    }

    getScrollbarWidth() {
        // Creating invisible container
        const outer = document.createElement("div");
        outer.style.visibility = "hidden";
        outer.style.overflow = "scroll"; // forcing scrollbar to appear
        outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
        document.body.appendChild(outer);

        // Creating inner element and placing it in the container
        const inner = document.createElement("div");
        outer.appendChild(inner);

        // Calculating difference between container's full width and the child width
        const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

        // Removing temporary elements from the DOM
        outer.parentNode.removeChild(outer);

        return scrollbarWidth;
    }
}
const scrollState = new ScrollState();

Math.easeInOutQuad = function (t, b, c, d) {
    t /= d / 2;
    if (t < 1) {
        return (c / 2) * t * t + b;
    }
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
};

// requestAnimationFrame for Smart Animating http://goo.gl/sx5sts
var requestAnimFrame = (function () {
    if (process.env.VUE_APP_ENV !== "client") return false;
    return (
        window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        function (callback) {
            window.setTimeout(callback, 1000 / 60);
        }
    );
})();

/**
 * Because it's so fucking difficult to detect the scrolling element, just move them all
 * @param {number} amount
 */
const move = (amount) => {
    document.documentElement.scrollTop = amount;
    document.body.parentNode.scrollTop = amount;
    document.body.scrollTop = amount;
};

const position = () => {
    return (
        document.documentElement.scrollTop ||
        document.body.parentNode.scrollTop ||
        document.body.scrollTop
    );
};

/**
 * @param {number} to
 * @param {number} duration
 * @param {Function} callback
 */
const scrollTo = (to, duration, callback) => {
    if (process.env.VUE_APP_ENV === "client") {
        const start = position();
        const change = to - start;
        const increment = 20;
        let currentTime = 0;
        duration = typeof duration === "undefined" ? 500 : duration;
        var animateScroll = function () {
            // increment the time
            currentTime += increment;
            // find the value with the quadratic in-out easing function
            var val = Math.easeInOutQuad(currentTime, start, change, duration);
            // move the document.body
            move(val);
            // do the animation unless its over
            if (currentTime < duration) {
                requestAnimFrame(animateScroll);
            } else {
                if (callback && typeof callback === "function") {
                    // the animation is done so lets callback
                    callback();
                }
            }
        };
        animateScroll();
    }
};

export { scrollState, stickyScroll, scrollTo };
