/**
 * Склонение числительных
 *
 * @param  { Number } number - число
 * @param  { Array } titles - массив склонений
 * @return { String }
 */
const declineOfNumber = (number, titles) => {
    let cases = [2, 0, 1, 1, 1, 2];
    return titles[
        number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
    ];
};

/**
 * Удаление дублированных значений
 *
 * @param  { Array } arr - массив с дублированными значениями
 * @return { Array }
 */
const removeDuplicates = (arr) => {
    const result = [];
    const duplicatesIndices = [];

    // Перебираем каждый элемент в исходном массиве
    arr.forEach((current, index) => {
        if (duplicatesIndices.includes(index)) return;

        result.push(current);

        // Сравниваем каждый элемент в массиве после текущего
        for (
            let comparisonIndex = index + 1;
            comparisonIndex < arr.length;
            comparisonIndex++
        ) {
            const comparison = arr[comparisonIndex];
            const currentKeys = Object.keys(current);
            const comparisonKeys = Object.keys(comparison);

            // Проверяем длину массивов
            if (currentKeys.length !== comparisonKeys.length) continue;

            // Проверяем значение ключей
            const currentKeysString = currentKeys.sort().join("").toLowerCase();
            const comparisonKeysString = comparisonKeys.sort().join("").toLowerCase();
            if (currentKeysString !== comparisonKeysString) continue;

            // Проверяем индексы ключей
            let valuesEqual = true;
            for (let i = 0; i < currentKeys.length; i++) {
                const key = currentKeys[i];
                if (current[key] !== comparison[key]) {
                    valuesEqual = false;
                    break;
                }
            }
            if (valuesEqual) duplicatesIndices.push(comparisonIndex);
        } // Конец цикла
    });
    return result;
};

// TODO: переделать numberFormat
const numberFormat = (number, decimals, dec_point, thousands_sep) => {
    number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
    let n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = typeof thousands_sep === "undefined" ? "," : thousands_sep,
        dec = typeof dec_point === "undefined" ? "." : dec_point,
        s = "",
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return "" + (Math.round(n * k) / k).toFixed(prec);
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || "").length < prec) {
        s[1] = s[1] || "";
        s[1] += new Array(prec - s[1].length + 1).join("0");
    }
    return s.join(dec);
};

const getNewDate = (days_count, only_dates, short = false) => {
    let result = "",
        new_date = new Date(),
        time = new_date.getTime() + days_count * 86400 * 1000,
        months = [
            "января",
            "февраля",
            "марта",
            "апреля",
            "мая",
            "июня",
            "июля",
            "августа",
            "сентября",
            "октября",
            "ноября",
            "декабря",
        ];

    days_count = parseInt(days_count);
    new_date.setTime(time);

    if (days_count === 0) {
        result = "сегодня";
    }

    if (days_count === 1) {
        result = short
            ? "завтра"
            : `завтра (${new_date.getDate()} ${months[new_date.getMonth()]})`;
    }

    if (days_count === 2) {
        result = short
            ? "послезавтра"
            : `послезавтра (${new_date.getDate()} ${months[new_date.getMonth()]})`;
    }

    if (days_count > 2 || only_dates) {
        result = new_date.getDate() + " " + months[new_date.getMonth()];
    }

    return result;
};

/**
 * Проверка массива на уникальность
 *
 * @param { any } value
 * @param { number } index
 * @param { array } self
 * @returns { boolean }
 */
const uniqueArray = (value, index, self) => {
    return self.indexOf(value) === index;
};

/**
 * Увеличение первого символа строки
 *
 * @param { String } string - передаваемая строка
 * @returns { String }
 */
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export {
    declineOfNumber,
    removeDuplicates,
    numberFormat,
    getNewDate,
    uniqueArray,
    capitalizeFirstLetter,
};
