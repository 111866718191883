import * as types from "./mutation_types";

function state() {
    return {
        site: null,
        cart: {},
        city: "",
        banners: [],
        media: [],
        brands: [],
        catalog: [],
        allCatalog: [],
        faq: [],
        stateFreeDeliveryModal: false,
    };
}

const mutations = {
    [types.SET_DATA_SITE](state, data) {
        state.site = data;
    },
    [types.SET_CART](state, data) {
        state.cart = data;
    },
    [types.SET_CITY](state, { cityName }) {
        state.city = cityName;
        try {
            window.localStorage.setItem("ya_city_name", cityName);
        } catch (err) {
            return "";
        }
        // TODO: изменить решение на cookie
    },
    [types.CHECK_CITY](state) {
        const cityName = window.localStorage.getItem("ya_city_name");

        if (cityName && cityName !== "undefined") {
            state.city = cityName;
            window.localStorage.setItem("ya_city_name", cityName);
        }
    },
    [types.SET_BANNERS](state, { items }) {
        state.banners = items;
    },
    [types.SET_MEDIA](state, { items }) {
        state.media = items;
    },
    [types.SET_BRANDS](state, { items }) {
        state.brands = items;
    },
    [types.SET_CATALOG](state, items) {
        state.catalog = items;
    },
    [types.SET_ALL_CATALOG](state, { items }) {
        state.allCatalog = items;
    },
    [types.SHOW_FREE_DELIVERY_MODAL](state) {
        state.stateFreeDeliveryModal = true;
    },
    [types.HIDE_FREE_DELIVERY_MODAL](state) {
        state.stateFreeDeliveryModal = false;
    },
    [types.SET_FAQ](state, faq) {
        state.faq = faq;
    },
    RESET_CART(state) {
        state.cart.number = 0;
    },
    RESET_CATALOG(state) {
        state.catalog = [];
    },
};

const actions = {
    getDataSite({ commit }) {
        return this.rootApi.getDataSite().then((response) => {
            commit(types.SET_DATA_SITE, response);
        });
    },
    getDefaultCity({ commit, state }) {
        commit(types.CHECK_CITY);
        if (state.city) return false;

        return this.rootApi.getDefaultCity().then((response) => {
            commit(types.SET_CITY, response);
        });
    },
    getCart({ commit }) {
        return this.cartApi.getCart().then((response) => {
            commit(types.SET_CART, response);
        });
    },
    addProductInCart({ commit }, { quantity, productId }) {
        commit(types.HIDE_FREE_DELIVERY_MODAL);

        return this.cartApi.addCart({ quantity, productId }).then((response) => {
            commit(types.SET_CART, response);
            commit(types.SHOW_FREE_DELIVERY_MODAL);
            return response;
        });
    },
    removeProductInCart({ commit }, { quantity, productId }) {
        return this.cartApi.changeQuantityCart({ quantity, productId }).then((response) => {
            commit(types.SET_CART, response);
        });
    },
    getBanners({ commit }) {
        return this.optionalApi.getBanners().then((response) => {
            commit(types.SET_BANNERS, response);
        });
    },
    getMedia({ commit }) {
        return this.optionalApi.getMediaAboutUs().then((response) => {
            commit(types.SET_MEDIA, response);
        });
    },
    getBrands({ commit }) {
        return this.optionalApi.getWhereBuy().then((response) => {
            commit(types.SET_BRANDS, response);
        });
    },
    getCatalog({ commit }, query) {
        commit("RESET_CATALOG");
        return this.productsApi.getCatalog(query).then((response) => {
            commit(types.SET_CATALOG, response);
        });
    },
    getAllCatalog({ commit }, query) {
        return this.productsApi.getAllCatalog(query).then((response) => {
            response.items = response.items.sort((a, b) => a.eol - b.eol);
            commit(types.SET_ALL_CATALOG, response);
        });
    },
    getProduct({ commit }, { slug }) {
        return this.productsApi.getProduct({ slug }).then((response) => {
            commit(types.SET_CATALOG, response);
        });
    },
    setNewCity({ commit }, { cityName }) {
        commit(types.SET_CITY, { cityName });
    },
    getFaq({ commit }) {
        return this.optionalApi.getFaq().then((response) => {
            commit("SET_FAQ", response);
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
