<script>
    export default {
        name: "LinkWithAnalytics",
        props: {
            type: String,
            text: String,
        },
        computed: {
            createHref() {
                let delta = "";
                if (this.type === "email") delta = "mailto";
                if (this.type === "phone") delta = "tel";
                return `${delta}:${this.text}`;
            },
            createEvents() {
                return `dataLayer.push({'event': 'contacts'}); ym(${this.$_ymId},'reachGoal','contacts');`;
            },
        },
    };
</script>

<template>
    <a :href="createHref" :onmousedown="createEvents" :ontouchstart="createEvents">
        <slot>{{ text }}</slot>
    </a>
</template>
