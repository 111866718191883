<script>
    import { mapGetters, mapState } from "vuex";
    import LinkWithAnalytics from "@/components/LinkWithAnalytics";
    import FormSubscribe from "@tb/components/SubscribeForm";

    export default {
        name: "TheFooterMain",
        components: {
            LinkWithAnalytics,
            FormSubscribe,
        },
        computed: {
            ...mapState("Root", {
                topMenu: (state) => state.site.topMenu,
                freeDelivery: (state) => state.site.freeDelivery,
                phoneCallCenter: (state) => state.site.options.phoneCallCenter,
            }),
            ...mapGetters("Catalog", ["mainSection"]),
            companyAddress() {
                return "129226, г. Москва, ул.&nbsp;Сельскохозяйственная,\n дом&nbsp;11, корп.&nbsp;3, эт.&nbsp;1, пом.&nbsp;II, ком.&nbsp;1&nbsp;(РМ47)";
            },
            getCurrentYear() {
                return new Date().getFullYear();
            },
        },
    };
</script>

<template>
    <div class="the-footer-main">
        <div class="the-footer-main__b-logo">
            <router-link :to="{ name: 'Home' }" class="the-footer-main__logo"></router-link>
            <div class="the-footer-main__company">
                <div class="the-footer-main__company-top">
                    <span>Оператор магазина</span> ООО «Проект Ы» <br />
                    ОГРН: 1187746848607
                </div>
                <div class="the-footer-main__company-bottom" v-html="companyAddress"></div>
                <div class="the-footer-main__company-copy">
                    Все права защищены. {{ getCurrentYear }} © Travel-blue.ru
                </div>
            </div>
        </div>
        <div class="the-footer-main__inner">
            <div class="the-footer-main__col">
                <h3>Каталог</h3>
                <ul class="the-footer-main__list">
                    <li v-for="section in mainSection" :key="section.id">
                        <router-link
                            :to="{
                                name: 'CatalogCategory',
                                params: { slug: section.slug },
                            }"
                            exact
                            >{{ section.name }}
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="the-footer-main__col mobile-hidden">
                <h3>Покупателю</h3>
                <ul class="the-footer-main__list">
                    <li v-for="(menu, i) in topMenu" :key="i">
                        <router-link :to="{ name: menu.link }">
                            {{ menu.name }}
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="the-footer-main__col mobile-hidden">
                <h3>Контакты</h3>
                <ul class="the-footer-main__list">
                    <li>
                        <link-with-analytics
                            type="phone"
                            :text="phoneCallCenter"
                        ></link-with-analytics>
                        <span>бесплатный звонок по России</span>
                    </li>
                    <li>
                        <link-with-analytics
                            type="email"
                            text="b2b@travel-blue.ru"
                        ></link-with-analytics>
                        <span>отдел корпоративных продаж</span>
                    </li>
                    <li>
                        <link-with-analytics
                            type="email"
                            text="retail@travel-blue.ru"
                        ></link-with-analytics>
                        <span>оптовые поставки в розничные магазины</span>
                    </li>
                    <li>
                        <link-with-analytics
                            type="email"
                            text="support@travel-blue.ru"
                        ></link-with-analytics>
                        <span>по техническим вопросам</span>
                    </li>
                </ul>
            </div>
            <div class="the-footer-main__newsletter">
                <form-subscribe variant-button="v1"></form-subscribe>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .the-footer-main {
        display: flex;
        flex-wrap: wrap;
        padding: 24px 0 16px;

        @include bp($bp-desktop-sm) {
            padding: 40px 0;
            flex-wrap: nowrap;
        }
    }

    .the-footer-main__b-logo {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 24px;
        background-color: #03338a;
        border-radius: 3px;
        flex-shrink: 0;

        @include bp($bp-desktop-sm) {
            width: 300px;
            height: 244px;
            padding: 32px;
        }
    }

    .the-footer-main__logo {
        width: 131px;
        height: 27px;
        background-image: url("~@tb/assets/icons/logo--white.svg");
        margin-bottom: 12px;
    }

    .the-footer-main__inner {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        @include bp($bp-desktop-sm) {
            flex-wrap: nowrap;
            margin-left: 32px;
            flex-grow: 1;
        }

        @include bp($bp-desktop-md) {
            margin-left: 48px;
        }
    }

    .the-footer-main__newsletter {
        width: 100%;
        margin-top: 24px;
        .form-subscribe__field {
            max-width: 100%;
        }

        @include bp($bp-tablet-sm) {
            .form-subscribe__field {
                flex-grow: 2;
            }
            .form-subscribe__button {
                flex-grow: 1;
                width: auto;
                margin-top: 0;
                margin-left: 8px;
            }
        }

        @include bp($bp-tablet-md) {
            .form-subscribe {
                max-width: 500px;
            }
        }

        @include bp($bp-desktop-sm) {
            position: absolute;
            width: calc(288px + 129px + 8px);
            left: 0;
            bottom: 0;
        }
    }

    .the-footer-main__col {
        margin-top: 24px;
        color: #fff;
        margin-right: 20px;
        padding: 0 24px;

        @include bp($bp-desktop-sm) {
            margin-top: 0;
            flex-grow: 1;
            padding: 0;
        }

        @include bp($bp-desktop-md) {
            margin-right: 46px;
        }

        &:nth-child(3) {
            margin-right: 0;

            @include bp($bp-desktop-sm) {
                margin-right: 20px;
            }

            @include bp($bp-desktop-md) {
                margin-right: 46px;
            }
        }

        &:nth-child(4) {
            width: 100%;

            @include bp($bp-desktop-sm) {
                width: auto;
                margin-right: 0;
            }

            .the-footer-main__list {
                li {
                    &:not(:first-child) {
                        margin-top: 8px;
                        @include bp($bp-desktop-sm) {
                            margin-top: 16px;
                        }
                    }
                }
            }
        }

        h3 {
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 4px;

            @include bp($bp-desktop-sm) {
                margin-bottom: 16px;
            }
        }
    }

    .the-footer-main__list {
        li {
            &:not(:first-child) {
                margin-top: 6px;
            }
        }

        a {
            display: block;
            font-size: 15px;
            line-height: 24px;
            color: #fff;
            transition: 0.3s ease-in-out all;

            &:hover {
                color: var(--secondary);
            }
        }

        span {
            color: #dedede;
            font-size: 13px;
            line-height: 16px;
        }
    }

    .the-footer-main__company {
        color: #fff;
        font-size: 12px;
        line-height: 15px;

        @include bp($bp-desktop-sm) {
            font-size: 14px;
            line-height: 17px;
        }
    }

    .the-footer-main__company-top {
        margin-bottom: 12px;
        @include bp($bp-desktop-sm) {
            span {
                display: block;
            }
        }
    }

    .the-footer-main__company-bottom {
        @include bp($bp-desktop-sm) {
            max-width: 190px;
        }
    }

    .the-footer-main__company-copy {
        margin-top: 12px;
        @include bp($bp-desktop-sm) {
            display: none;
        }
    }
</style>
