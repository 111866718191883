import {
    mapBanners,
    mapArticles,
    mapBrands,
    mapFaq,
    mapReviewsInstagram,
    mapStretchings,
} from "../mapping/optional";

export default function ($api) {
    return {
        /**
         * Получить баннеры
         *
         * @return { Object }
         */
        getBanners() {
            return $api.get("api/banner/all?sorts[sort]=asc").then(mapBanners);
        },
        /**
         * Получить "Где купить" (список компаний, где можной купить текущий товар)
         *
         * @return { Object }
         */
        getBrands() {
            return $api.get("api/where-buy/all?sorts[sort]=asc");
        },
        /**
         * Получить статьи (на монобрендах это "Медиа о нас")
         *
         * @return { Object }
         */
        getMediaAboutUs() {
            return $api.get("api/article/all?sorts[sort]=asc").then(mapArticles);
        },
        /**
         * Получить бенды, где можно купить продукцию
         *
         * @return { Object }
         */
        getWhereBuy() {
            return $api.get("api/where-buy/all").then(mapBrands);
        },
        /**
         * Получение вопросов и ответов
         * @returns { Object }
         */
        getFaq() {
            return $api.get("api/faq/all").then(mapFaq);
        },
        /**
         * Получение отзывов из инстаграма
         * @returns { Object }
         */
        getReviewsInstagram() {
            return $api.get("api/site-review/all").then(mapReviewsInstagram);
        },
        /**
         * Подписка на рассылку
         * @returns { Object }
         */
        subscribeTo({ email }) {
            return $api.post("api/subscribe/add/", {
                email,
            });
        },
        /**
         * Получение растяжек для определенного города или региона
         * @returns { Object }
         */
        getStretchingsByCity(city) {
            return $api.post("api/stretching/getbycity", { city }).then(mapStretchings);
        },
    };
}
