<script>
    export default {
        name: "TheNavCatalog",
        props: {
            catalogs: Array,
        },
    };
</script>

<template>
    <div class="nav-catalog">
        <div class="l-container">
            <nav class="nav-catalog__list">
                <router-link
                    v-for="catalog in catalogs"
                    :key="catalog.id"
                    class="nav-catalog__link"
                    :to="{
                        name: 'CatalogCategory',
                        params: { slug: catalog.slug },
                    }"
                    exact
                >
                    {{ catalog.name }}
                </router-link>
            </nav>
        </div>
    </div>
</template>

<style lang="scss">
    .nav-catalog {
        display: none;

        @include bp($bp-desktop-sm) {
            display: block;
            border-top: 1px solid var(--primary-border--light);
            border-bottom: 1px solid var(--primary-border--light);
            background-color: #fcfcfc;
        }
    }

    .nav-catalog__list {
        display: flex;
    }

    .nav-catalog__link {
        position: relative;
        padding: 14px 0;
        font-size: 15px;
        line-height: 24px;
        color: var(--primary);
        margin-right: 24px;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            border-radius: 2px;
            transition: 0.2s ease-in-out all;
        }

        &.active {
            &:after {
                background-color: var(--primary);
            }
        }
    }
</style>
