<script>
    import { mapActions, mapState, mapGetters } from "vuex";
    import TheModal from "@main/packages/TheModal";
    import TheTopBanner from "@main/packages/TheTopBanner";
    import TheHeader from "@tb/components/TheHeader";
    import TheFooter from "@tb/components/TheFooter";
    import TheNavCatalog from "@tb/components/TheNavCatalog";
    import TheNavMobile from "@tb/components/TheNavMobile";

    export default {
        components: {
            TheNavMobile,
            TheNavCatalog,
            TheModal,
            TheTopBanner,
            TheHeader,
            TheFooter,
        },
        data() {
            return {
                isOrderPage:
                    this.$router.history.current.name === "Order" ||
                    this.$router.history.current.name.startsWith("TheCheckout"),
                isVisibleTopBanner: false,
            };
        },
        computed: {
            ...mapState("Root", {
                topBanner: (state) => state.site.topBanner,
                city: (state) => state.city,
                phone: (state) => state.site.phone,
            }),
            ...mapGetters("Catalog", ["mainSection"]),
        },
        watch: {
            $route() {
                this.isOrderPage =
                    this.$router.history.current.name === "Order" ||
                    this.$router.history.current.name.startsWith("TheCheckout");
            },
        },
        serverPrefetch() {
            return Promise.all([this.getDataSite(), this.getCategoryNames()]);
        },
        mounted() {
            this.getDefaultCity();
            this.getCart();
            if (!this.$route.name.startsWith("ThePersonal")) {
                this.getProfileUser();
            }

            if (this.$route.query.modal === "status-order") {
                this.$popup.show("modal-status-order");
            }
        },
        methods: {
            ...mapActions("Root", ["getDataSite", "getDefaultCity", "getCart"]),
            ...mapActions("Profile", ["getProfileUser"]),
            ...mapActions("Catalog", ["getCategoryNames"]),
        },
    };
</script>

<template>
    <div id="app">
        <the-nav-mobile v-show="!isOrderPage"></the-nav-mobile>

        <the-top-banner
            :banner="topBanner"
            :city="city"
            @on-change-visibility="isVisibleTopBanner = $event"
        ></the-top-banner>

        <the-header
            :is-top-banner="isVisibleTopBanner"
            :is-order-page="isOrderPage"
        ></the-header>

        <the-nav-catalog :catalogs="mainSection"></the-nav-catalog>

        <router-view />

        <the-footer></the-footer>

        <the-modal></the-modal>

        <!--        <div class="l-container" :class="{ 'l-full': isOrder }">-->
        <!--            <router-view />-->
        <!--        </div>-->
        <!--        <app-footer></app-footer>-->
        <!--        <free-delivery></free-delivery>-->
        <!--        <modal></modal>-->

        <div itemscope itemtype="https://schema.org/Organization" style="display: none">
            <meta itemprop="name" content="Travel Blue Russia" />
            <meta
                itemprop="alternateName"
                content="Travel Blue Russia - аксессуары для путешествий"
            />
            <meta itemprop="legalName" content="ООО 'Проект Ы'" />
            <meta
                itemprop="description"
                content="Магазин аксессуаров для путешественний Travel Blue"
            />
            <link itemprop="url" href="https://travel-blue.ru/" />

            <meta itemprop="email" content="support@travel-blue.ru" />
            <div itemprop="address" itemscope itemtype="https://schema.org/PostalAddress">
                <meta itemprop="addressLocality" content="Москва, Россия" />
                <meta itemprop="postalCode" content="129226" />
                <meta itemprop="streetAddress" content="ул. Сельскохозяйственная, дом 11" />
            </div>
            <meta itemprop="telephone" :content="phone" />
        </div>
    </div>
</template>

<style lang="scss">
    @import "~@/assets/sass/main.scss";
    @import "~@/assets/sass/custom-properties.scss";
    @import "~@tb/assets/sass/vars.scss";
    @import "~@tb/style/TheSlider.scss";

    #app {
        overflow: hidden;
    }

    #usedesk-messenger {
        .uw__messenger-layout__buttons {
            bottom: 70px !important;
            right: 16px;

            @include bp($bp-desktop-sm) {
                bottom: 20px !important;
            }
        }
    }
</style>
