import Vue from "vue";

Vue.use({
    install(Vue) {
        // let submit = function() {};

        // this.$eventBus.$on("popup-closed", function(data) {
        //     if (submit) submit(data);
        // });

        Vue.prototype.$popup = {
            show(nameOrComponent, props, options) {
                Vue.prototype.$eventBus.$emit("popup-show", {
                    nameOrComponent,
                    props,
                    options,
                });
                return new Promise(function () {
                    // submit = resolve;
                    // console.log(resolve);
                });
            },
            close() {
                Vue.prototype.$eventBus.$emit("popup-close");
            },
            // submit(data) {
            //     this.$eventBus.$emit("popup-close", data);
            // }
        };
    },
});
