<script>
    import LinkWithAnalytics from "@/components/LinkWithAnalytics";
    import TopNavigationMenu from "@/components/TopNavigationMenu";

    export default {
        name: "TheHeaderTop",
        components: {
            LinkWithAnalytics,
            TopNavigationMenu,
        },
        props: {
            phoneCallCenter: String,
            topMenu: Array,
            city: String,
        },
    };
</script>

<template>
    <div class="the-header-top">
        <div class="l-container">
            <div class="the-header-top__wrapper">
                <top-navigation-menu :menu="topMenu" :city="city"></top-navigation-menu>

                <div class="the-header-top__links">
                    <link-with-analytics
                        class="the-header-top__phone"
                        type="phone"
                        :text="phoneCallCenter"
                    ></link-with-analytics>

                    <button
                        class="the-header-top__callback"
                        @click="$popup.show('modal-callback')"
                    >
                        Заказать звонок
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .the-header-top {
        display: none;
        @include bp($bp-desktop-sm) {
            display: block;
            background-color: var(--primary);
        }
    }

    .the-header-top__wrapper {
        @include bp($bp-desktop-sm) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 48px;
        }
    }

    .the-header-top__links {
        @include bp($bp-desktop-sm) {
            padding: 8px 12px;
            background-color: var(--primary--dark);
        }
    }

    .the-header-top__phone {
        @include bp($bp-desktop-sm) {
            display: block;
            color: #fff;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            white-space: nowrap;
        }
    }

    .the-header-top__callback {
        @include bp($bp-desktop-sm) {
            display: block;
            color: #fff;
            font-size: 11px;
            font-weight: 400;
            line-height: 13px;
            white-space: nowrap;
            transition: 0.3s ease-in-out color;
            &:hover {
                color: var(--secondary);
            }
        }
    }
</style>
