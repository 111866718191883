<script>
    export default {
        name: "PaymentIcons",
        data() {
            return {
                icons: [
                    { title: "Наличные", image: "cash.png" },
                    { title: "Visa", image: "visa.svg" },
                    { title: "Mastercard", image: "mastercard.svg" },
                    { title: "Mir", image: "mir.svg" },
                    { title: "Google Pay", image: "google-pay.svg" },
                    { title: "Apple Pay", image: "apple-pay.svg" },
                ],
            };
        },
    };
</script>

<template>
    <div class="payment-icons">
        <div class="payment-icons__list">
            <div class="payment-icons__item" v-for="(icon, index) in icons" :key="index">
                <img :src="`/static/payment/${icon.image}`" :alt="icon.title" />
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .payment-icons {
        max-width: 330px;
        text-align: center;
    }

    .payment-icons__list {
        display: flex;
        justify-content: center;
        margin: 0 -4px;
    }

    .payment-icons__item {
        flex-basis: calc(100% / 6);
        max-width: 48px;
        margin: 0 4px;
        box-sizing: border-box;
    }
</style>
