<script>
    import { isMobile, scrollState, stickyScroll } from "@/utils";
    import TheSvgIcon from "@main/packages/TheSvgIcon";
    import LinkWithAnalytics from "@/components/LinkWithAnalytics";
    import HeaderBurger from "@/components/HeaderBurger";
    import TheHeaderMobileMenu from "@tb/components/TheHeaderMobileMenu";
    import HeaderMenu from "@tb/components/HeaderMenu";

    export default {
        name: "TheHeaderMobile",
        components: {
            TheSvgIcon,
            LinkWithAnalytics,
            HeaderBurger,
            TheHeaderMobileMenu,
            HeaderMenu,
        },
        props: {
            topMenu: Array,
            isOrderPage: Boolean,
            cart: Object,
            freeDeliveryCount: Number,
            city: String,
            phoneCallCenter: String,
            isTopBanner: Boolean,
        },
        data() {
            return {
                showMobileMenu: false,
                showMobileCatalog: false,
                isSearchScroll: false,
                isFixBugScrollTopMenu: false,
            };
        },
        watch: {
            $route() {
                scrollState.enable();
                this.showMobileMenu = false;
                this.showMobileCatalog = false;
                this.isSearchScroll = false;
            },
            city: {
                handler() {
                    if (isMobile()) {
                        scrollState.enable();
                        this.showMobileMenu = false;
                    }
                },
            },
            isSearchScroll(value) {
                this.$emit("on-change-search-state", value);
            },
        },
        mounted() {
            let scrollPos = 0;

            if (isMobile(992)) {
                let el = this.$refs.scrollHeader;
                let elWrap = this.$refs.scrollHeaderWrap;

                window.addEventListener("scroll", () => {
                    if (window.pageYOffset > 0) {
                        this.isFixBugScrollTopMenu = true;
                    }
                    stickyScroll({
                        el,
                        elWrap,
                        classNameDivider: "js-footer-stop-divider",
                        calcWidth: false,
                    });
                    if (!this.showMobileMenu) {
                        this.isSearchScroll =
                            document.body.getBoundingClientRect().top > scrollPos &&
                            el.getBoundingClientRect().top <= 0;
                    }
                    scrollPos = document.body.getBoundingClientRect().top;
                });
            }
        },
        methods: {
            closeMobileMenuFromLinks() {
                this.showMobileMenu = false;
                scrollState.enable();
            },
            openMobileCatalog() {
                this.showMobileCatalog = true;
            },
            closeMobileCatalog() {
                this.showMobileCatalog = false;
            },
            scrollToHeader() {
                this.$refs.scrollHeader.scrollIntoView(true);
            },
            checkForScrolling() {
                const heightToBanner = 28;
                return window.pageYOffset < heightToBanner;
            },
            openMobileMenu() {
                if (this.isTopBanner && this.checkForScrolling()) {
                    this.scrollToHeader();
                }
                this.showMobileMenu = !this.showMobileMenu;
                this.isSearchScroll =
                    this.showMobileMenu || document.body.getBoundingClientRect().top !== 0;
                this.showMobileMenu ? scrollState.disable() : scrollState.enable();
                if (!this.showMobileMenu) {
                    this.isFixBugScrollTopMenu = true;
                    // баг скрол вверх из-за scrollState.enable()
                }
                this.showMobileCatalog = false;
            },
            closeMobileMenu() {
                this.showMobileMenu = false;
                scrollState.enable();
            },
        },
    };
</script>

<template>
    <div class="the-header-mobile" ref="scrollHeader">
        <div
            class="the-header-mobile__wrapper js-mobile-header"
            :class="{
                'hide-border': isSearchScroll,
                'fix-scroll-block': isFixBugScrollTopMenu,
                'fix-position-top': isTopBanner,
            }"
            ref="scrollHeaderWrap"
            data-fix-on-scroll
        >
            <div class="l-container">
                <div class="the-header-mobile__scroll">
                    <div class="c-header__mobile-phone">
                        <link-with-analytics type="phone" :text="phoneCallCenter">
                            <the-svg-icon name="phone" :size-w="24"></the-svg-icon>
                        </link-with-analytics>
                    </div>

                    <div class="the-header-mobile__logo-wrapper">
                        <router-link
                            :to="{ name: 'Home' }"
                            class="the-header-mobile__logo"
                        ></router-link>
                    </div>

                    <div class="the-header-mobile__burger" @click="openMobileMenu">
                        <header-burger :state="showMobileMenu"></header-burger>
                    </div>
                </div>
            </div>
        </div>

        <div class="the-header-mobile__menu" :class="{ 'is-opened': showMobileMenu }">
            <the-header-mobile-menu
                :top-menu="topMenu"
                :city="city"
                :phone-call-center="phoneCallCenter"
                @on-open-catalog="openMobileCatalog"
            ></the-header-mobile-menu>
        </div>

        <transition name="fade">
            <header-menu
                v-show="showMobileCatalog"
                @close-mobile-catalog="closeMobileCatalog"
            ></header-menu>
        </transition>
    </div>
</template>

<style lang="scss">
    .the-header-mobile {
        height: 56px;
        @include bp($bp-desktop-sm) {
            display: none;
        }
    }

    .the-header-mobile__wrapper {
        position: relative;
        //z-index: 50;
        z-index: 70;
        left: 0;
        width: 100%;
        padding: 5px 0;
        background-color: #fff;
        &::after {
            content: "";
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0;
            height: 1px;
            background-color: var(--primary-border--light);
            opacity: 0;
        }
        &.hide-border {
            &:after {
                opacity: 0 !important;
            }
        }
        &.fix-scroll-block {
            position: fixed;
            &:after {
                opacity: 1;
            }
        }
        &.fix-position-top {
            top: 0;
        }
    }

    .the-header-mobile__scroll {
        position: relative;
        z-index: 30;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
    }

    .the-header-mobile__burger {
        display: flex;
        justify-content: center;
        align-items: center;
        @include box(46px);
        margin-left: auto;
        margin-right: -12px;
    }

    .the-header-mobile__logo-wrapper {
        width: 200px;
    }

    .the-header-mobile__logo {
        @include center-absolute(0);
        display: block;
        width: 117px;
        height: 27px;
        margin: auto;
        background-image: url("~@tb/assets/icons/logo.svg");
        background-size: contain;
        background-repeat: no-repeat;
    }

    .the-header-mobile__menu {
        position: fixed;
        top: 57px;
        left: 0;
        width: 100%;
        height: calc(100vh - 57px);
        height: calc((var(--vh, 1vh) * 100) - 57px);
        padding-top: 56px;
        z-index: 40;
        overflow: auto;
        transform: translateY(-112%);
        transition: 0.3s ease-in-out transform;
        &.is-opened {
            transform: translateY(0);
        }
    }
</style>
