class SendAnalytics {
    constructor() {
        if (SendAnalytics.instance) {
            return SendAnalytics.instance;
        }
        SendAnalytics.instance = this;

        this._metricId = null;
        this.events = {
            call: "call",
            promocode: "promocode",
            preorder: "preorder",
            orderStatus: "order-status",
            delivery: "delivery",
            payment: "payment",
            compare: "compare",
            checkout: "checkout",
            pageview: "pageview",
            addToCart: ["add-to-cart", "addToCart"],
            removeFromCart: "removeFromCart",
            productDetail: "productDetail",
            productClick: "productClick",
            orderSuccess: ["order-success", "purchase"],
            productList: "productList",
            certificate: "certificate",
            buyOneSuccess: "buy-one-success",
            feedback: "feedback",
            subscribe: "subscribe",
            catalogProduct: "catalog-product",
        };

        this.firstStepCheckoutEvents = {
            cartClear: "1-cart-clear",
            cartItemDelete: "1-cart-item-delete",
            cartChangeVariant: "1-cart-change-variant",
            cartChangeCount: "1-cart-change-count",
            cartUsePromocode: "1-cart-use-promocode",
            cartUseMadcoin: "1-cart-use-madcoin",
            cartUseCertificate: "1-cart-use-certificate",
            cartAddToOrder: "1-cart-add-to-order-", // [Название товара транслит]
            cartGoToOrderButton: "1-cart-go-to-order-button",
        };
        this.secondStepCheckoutEvents = {
            orderChangeCity: "2-order-change-city",
            orderChangeDeliveryType: "2-order-change-delivery-type",
            orderSelectCourier: "2-order-select-courier",
            orderEditAddress: "2-order-edit-address-", // [name атрибут поля ввода]
            orderOpenPickupPoint: "2-order-open-pickup-point",
            orderSelectPickupPoint: "2-order-select-pickup-point",
            orderSelectGiftPackage: "2-order-select-gift-package",
            orderUsePromocode: "2-order-use-promocode",
            orderUseMadcoin: "2-order-use-madcoin",
            orderUseCertificate: "2-order-use-certificate",
            orderGoToPaymentButton: "2-order-go-to-payment-button",
        };
        this.thirdStepCheckoutEvents = {
            orderSelectPayment: "3-order-select-payment",
            orderEditContacts: "3-order-edit-contacts-", // [name атрибут поля ввода]
            orderSettings: "3-order-settings-", // [name атрибут чекбокса]-[on/off]
            orderUsePromocode: "3-order-use-promocode",
            orderUseMadcoin: "3-order-use-madcoin",
            orderUseCertificate: "3-order-use-certificate",
            orderGoToThankyou: "3-order-go-to-thankyou",
            // orderGoToPay: "3-order-go-to-pay-", // [TYPE OF PAYMENT] пока невозможен
        };
        this.otherCheckoutEvents = {
            cartEditLink: "cart-edit-link",
            cartDeliveryLink: "cart-edit-delivery-link",
        };

        this._checkoutEvents = [
            ...Object.values(this.firstStepCheckoutEvents),
            ...Object.values(this.secondStepCheckoutEvents),
            ...Object.values(this.thirdStepCheckoutEvents),
            ...Object.values(this.otherCheckoutEvents),
        ];

        this._simpleEvents = [
            this.events.call,
            this.events.promocode,
            this.events.preorder,
            this.events.orderStatus,
            this.events.delivery,
            this.events.payment,
            this.events.compare,
            this.events.certificate,
            this.events.buyOneSuccess,
            this.events.feedback,
            this.events.subscribe,
            this.events.catalogProduct,
        ];
        this._pageViewEvents = [this.events.pageview];
        this._withPayloadEvents = [this.events.addToCart, this.events.orderSuccess];
        this._onlyPayloadEvents = [
            this.events.productList,
            this.events.removeFromCart,
            this.events.checkout,
            this.events.productDetail,
            this.events.productClick,
        ];

        return this;
    }

    /**
     * Установка идентификатора Яндекс метрики
     * @param id { String, Number }
     */
    updateMetricId(id) {
        this._metricId = id;
    }

    /**
     * Отправка аналитики
     * @param eventType { String } тип события (выбор из списка "events" у экземпляра класса)
     * @param payload { Object } входные данные для отправки
     * @param options { Object } дополнительные парматеры
     */
    send(eventType, payload = {}, options = {}) {
        if (this._checkoutEvents.includes(eventType)) {
            this._sendForCheckout(eventType, payload);
            return false;
        }
        if (this._simpleEvents.includes(eventType)) {
            this._sendSimple(eventType);
            return false;
        }
        if (this._pageViewEvents.includes(eventType)) {
            this._sendPageView(payload);
            return false;
        }
        if (this._withPayloadEvents.includes(eventType)) {
            this._sendWithPayload(eventType, payload);
            return false;
        }
        if (this._onlyPayloadEvents.includes(eventType)) {
            this._sendOnlyPayload(eventType, payload, options);
            return false;
        }
    }

    /**
     * Отправка аналитики для чекаута
     * @param eventType { String } тип события
     * @param payload {Object} входные данные
     * @private
     */
    _sendForCheckout(eventType, payload = null) {
        try {
            window.dataLayer.push({
                eventLabel: eventType + (payload.dynamicName || ""),
                eventAction: "checkout",
                eventCategory: "order",
                event: "GAEvent",
            });
        } catch (e) {
            console.log(
                "%c EventTypeForCheckout - ",
                "color: green; font-style: bold; font-size: 16px;",
                eventType,
                "\n",
                e
            );
        }
    }

    /**
     * Отправка аналитики без входных данных
     * @param eventType { String } тип события
     * @private
     */
    _sendSimple(eventType) {
        try {
            this._push(eventType);
        } catch (e) {
            console.log(
                "%c eventType - ",
                "color: green; font-style: bold; font-size: 16px;",
                eventType,
                "\n",
                e
            );
        }
    }

    /**
     * Отправка аналитики просмотра страницы
     * @param payload {Object} входные данные
     * @private
     */
    _sendPageView(payload = {}) {
        try {
            window.dataLayer.push({
                event: "pageview",
                pageurl: payload.toFullPath,
                pagetitle: document.title,
            });
            window.ym(this._metricId, "hit", payload.toFullPath, {
                referer: payload.fromFullPath,
                title: document.title,
            });
        } catch (e) {
            console.log(
                "%c eventType - pageview ",
                "color: green; font-style: bold; font-size: 16px;",
                "\n",
                e
            );
        }
    }

    /**
     * Отправка аналитики с входными данными
     * @param eventType { String } тип события
     * @param payload {Object} входные данные
     * @private
     */
    _sendWithPayload(eventType, payload = {}) {
        try {
            const event = Array.isArray(eventType) ? eventType[0] : eventType;
            this._push(event);
            window.dataLayer.push({
                event: Array.isArray(eventType)
                    ? eventType[1] || eventType[0] || eventType
                    : eventType,
                ...this._constructPayload(event, payload),
            });
        } catch (e) {
            console.log(
                "%c eventType - ",
                "color: green; font-style: bold; font-size: 16px;",
                eventType,
                "\n",
                e
            );
        }
        // isPicooc
        if (
            (eventType === "add-to-cart" || eventType === "addToCart") &&
            this._metricId === "47703889"
        ) {
            try {
                window.fbq("track", "AddToCart", this._getProductForFbq(payload));
            } catch (e) {
                console.log(e);
            }
        }
    }

    /**
     * Отправка аналитики. Передаются только входные данные
     * @param eventType { String } тип события
     * @param payload { Object } входные данные
     * @param options { Object } дополнительные параметры
     * @private
     */
    _sendOnlyPayload(eventType, payload = {}, options) {
        try {
            window.dataLayer.push({
                event: eventType,
                ...this._constructPayload(eventType, payload, options),
            });
        } catch (e) {
            console.log(
                "%c eventType - ",
                "color: green; font-style: bold; font-size: 16px;",
                eventType,
                "\n",
                e
            );
        }
    }

    /**
     * Вспомогательный метод для отправки
     * @param event { String } тип события
     * @private
     */
    _push(event) {
        window.dataLayer.push({ event });
        window.ym(this._metricId, "reachGoal", event);
    }

    /**
     * Формирование объекта для передачи в аналитики
     * @param eventType { String } тип события
     * @param payload { Object } входные данные
     * @param options { Object } дополнительный параметры
     * @returns { Object } сформированный объект для передачи в аналитики
     * @private
     */
    _constructPayload(eventType, payload = {}, options) {
        let currentProductsForCheckout = [];

        if (payload.basketItems) {
            for (let key in payload.basketItems) {
                let currentItem = null;
                let basketItem = payload.basketItems[key];

                currentItem = this._getProductForEC({
                    name: basketItem.name, //название товара*
                    id: basketItem.productId, //уникальный идентификатор товара, обычно артикул*
                    price: parseInt(basketItem.price), //цена товара за шт.(ед.изм)*
                    quantity: parseInt(basketItem.quantity), //количество товара*,
                    brand: basketItem.brand,
                });

                currentProductsForCheckout.push(currentItem);
            }
        }

        switch (eventType) {
            case "add-to-cart":
                return {
                    ecommerce: {
                        currencyCode: "RUB",
                        add: {
                            products: [this._getProductForEC(payload)],
                        },
                    },
                };
            case "removeFromCart":
                return {
                    ecommerce: {
                        remove: {
                            products: [this._getProductForEC(payload)],
                        },
                    },
                };
            case "productDetail":
                return {
                    ecommerce: {
                        detail: {
                            products: [this._getProductForEC(payload)],
                        },
                    },
                };
            case "productClick":
                return {
                    ecommerce: {
                        click: {
                            actionField: {
                                list: options.type ? options.type : "catalog-list",
                            },
                            products: [
                                this._getProductForEC({
                                    ...payload,
                                    list: options.type ? options.type : "catalog-list",
                                }),
                            ],
                        },
                    },
                };
            case "checkout":
                return {
                    ecommerce: {
                        checkout: {
                            actionField: {
                                step: payload.step + 1,
                                coupon: payload.coupons, // промокод если есть
                                discount: payload.sumDiscountCoupon, // сумма скидки в корзине если есть
                            },
                            products: currentProductsForCheckout,
                        },
                    },
                };
            case "order-success": {
                return {
                    ecommerce: {
                        purchase: {
                            actionField: {
                                id: payload.orderId, // ID заказа*
                                affiliation: "madrobots.ru", //филиал, магазин, например регион, если интернет-магазин имеет поддомены для регионов
                                revenue: parseInt(payload.orderPrice), //конечная стоимость заказа с учетом скидок, доставки, налогов*
                                tax: "0.00", // налог, зачастую 0.00
                                shipping: parseInt(payload.deliveryPrice), //стоимость доставки,
                                coupon: payload.coupons, //купон или номер дисконтной карты если есть
                            },
                            products: currentProductsForCheckout,
                        },
                    },
                };
            }
            case "productList":
                // eslint-disable-next-line no-case-declarations
                let impressionsList = payload.map((product, index) => {
                    let { id, name, discountPrice } = product.offers[0];

                    return this._getProductForEC({
                        id,
                        name,
                        discountPrice,
                        list: options.type ? options.type : "catalog-list",
                        position: index + 1,
                        mainCategory: product.mainCategory,
                        mainSection: options.mainSection ? options.mainSection : "",
                    });
                });

                return {
                    ecommerce: {
                        currencyCode: "RUB",
                        impressions: impressionsList,
                    },
                };
            default:
                console.error(`Event type "${eventType}" does not exist`);
        }
    }

    /**
     * Получение товара для e-commerce
     * @param payload { Object } входные данные
     * @returns { Object } сформированный продукт
     * @private
     */
    _getProductForEC(payload = {}) {
        const id = payload.productId || payload.offerId || payload.id;
        const price = payload.price || payload.discountPrice;
        let currentSection = "-";

        if (payload.mainCategory && payload.mainSection) {
            currentSection = payload.mainSection.filter(
                (section) => section.id === payload.mainCategory.sectionId || 0
            )[0].name;
        }

        const product = {
            id,
            price,
            name: payload.name,
            brand: payload.brand || window.__NAME_SITE__,
            list: payload.list || "",
            position: payload.position,
            quantity: payload.quantity || 1,
            category: `${currentSection}/${(payload.mainCategory || "").name || ""}`,
        };

        Object.keys(product).forEach(
            (key) => (product[key] === "" || product[key] === undefined) && delete product[key]
        );

        return product;
    }
    /**
     * Получение товара для facebook
     * @param payload { Object } входные данные
     * @returns { Object } сформированный продукт
     * @private
     */
    _getProductForFbq(payload = {}) {
        const id = payload.productId || payload.offerId || payload.id;
        const price = payload.price || payload.discountPrice;
        //
        // const product = {
        //     id,
        //     price,
        //     name: payload.name,
        //     brand: payload.brand || window.__NAME_SITE__,
        //     list: payload.list || "",
        //     position: payload.position,
        //     quantity: payload.quantity || 1,
        // };
        //
        // Object.keys(product).forEach(
        //     (key) => (product[key] === "" || product[key] === undefined) && delete product[key]
        // );
        //
        // return product;
        return {
            content_name: payload.name,
            content_category: "",
            content_ids: [id],
            content_type: "product",
            value: price,
            currency: "RUB",
        };
    }
}

export const sendAnalytics = new SendAnalytics();
