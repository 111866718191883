<script>
    export default {
        name: "HeaderStatusOrder",
        methods: {
            openModal() {
                this.$popup.show("modal-status-order");
            },
        },
    };
</script>

<template>
    <button class="header-order-status outline" @click="openModal">
        <slot></slot>
        <span class="header-order-status__text">Статус заказа</span>
    </button>
</template>
