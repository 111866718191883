<script>
    import TheSvgIcon from "@main/packages/TheSvgIcon";
    import TopNavigationMenu from "@/components/TopNavigationMenu";
    import LinkWithAnalytics from "@/components/LinkWithAnalytics";

    export default {
        name: "TheHeaderMobileMenu",
        components: {
            LinkWithAnalytics,
            TopNavigationMenu,
            TheSvgIcon,
        },
        props: {
            centerMenu: Array,
            topMenu: Array,
            city: String,
            phoneCallCenter: String,
            uniqueLink: String,
        },

        methods: {
            openCallbackModal() {
                this.$popup.show("modal-callback");
            },
            openMobileCatalog() {
                this.$emit("on-open-catalog");
            },
        },
    };
</script>

<template>
    <div class="the-header-mobile-menu">
        <div class="the-header-mobile-menu__top">
            <div class="the-header-mobile-menu__category" @click="openMobileCatalog">
                <the-svg-icon name="menu_list" :size-w="24"></the-svg-icon>
                Категории
                <the-svg-icon
                    name="arrow_right"
                    is-common
                    :size-w="16"
                    :size-h="24"
                ></the-svg-icon>
            </div>

            <top-navigation-menu :menu="topMenu" :city="city"> </top-navigation-menu>
        </div>

        <div class="the-header-mobile-menu__contacts">
            <link-with-analytics
                class="the-header-mobile-menu__phone"
                type="phone"
                :text="phoneCallCenter"
            ></link-with-analytics>

            <button class="the-header-mobile-menu__callback" @click="openCallbackModal">
                Заказать звонок
            </button>

            <div class="the-header-mobile-menu__time">
                <the-svg-icon name="clock" is-common :size-w="16"></the-svg-icon>
                Ежедневно 9:00–21:00 МСК
            </div>
        </div>

        <ul class="the-header-mobile-menu__links">
            <li>
                <span>оптовые поставки в розничные магазины</span>
                <link-with-analytics
                    type="email"
                    text="retail@travel-blue.ru"
                ></link-with-analytics>
            </li>
            <li>
                <span>отдел корпоративных продаж</span>
                <link-with-analytics
                    type="email"
                    text="b2b@travel-blue.ru"
                ></link-with-analytics>
            </li>
            <li>
                <span>по техническим вопросам</span>
                <link-with-analytics
                    type="email"
                    text="support@travel-blue.ru"
                ></link-with-analytics>
            </li>
            <li>
                <span>бесплатный звонок по России</span>
                <link-with-analytics
                    type="phone"
                    :text="phoneCallCenter"
                ></link-with-analytics>
            </li>
        </ul>
    </div>
</template>

<style lang="scss">
    .the-header-mobile-menu {
        background-color: #fff;
    }

    .the-header-mobile-menu__category {
        font-size: 15px;
        line-height: 24px;
        display: flex;
        justify-content: flex-start;
        font-weight: 600;
        color: var(--primary);
        border-top: 1px solid var(--primary-border--light);
        border-bottom: 1px solid var(--primary-border--light);
        background-color: var(--bg-light);
        margin: 0 -16px;
        padding: 12px 16px;
        svg {
            &.svg-icon--menu_list {
                margin-right: 8px;
            }
            &.svg-icon--arrow_right {
                margin-left: auto;
            }
        }
    }

    .the-header-mobile-menu__top {
        padding: 0 16px 20px;
    }

    .the-header-mobile-menu__phone {
        display: block;
        margin-bottom: 5px;
        color: #182028;
        font-size: 17px;
        font-weight: 600;
        line-height: 18px;
        white-space: nowrap;
    }

    .the-header-mobile-menu__callback {
        font-size: 14px;
        line-height: 24px;
        color: var(--info-text);
        white-space: nowrap;
    }

    .the-header-mobile-menu__time {
        display: flex;
        align-items: center;
        margin-top: 8px;
        font-size: 12px;
        line-height: 16px;
        svg {
            margin-right: 5px;
        }
    }

    .the-header-mobile-menu__contacts {
        padding: 16px;
        border-bottom: 1px solid var(--neutral);
    }

    .the-header-mobile-menu__links {
        padding: 16px 16px 32px;

        li {
            &:not(:first-child) {
                margin-top: 16px;
            }
        }
        span {
            display: block;
            font-weight: 600;
            font-size: 12px;
            line-height: 14px;
            color: var(--primary-text);
            margin-bottom: 8px;
        }
        a {
            color: var(--info-text--dark);
            font-size: 12px;
            line-height: 14px;
        }
    }
</style>
