<script>
    import { scrollState } from "@/utils";
    import TheSvgIcon from "@frontend/the-svg-icon";

    export default {
        name: "TheModal",
        data() {
            return {
                opened: false,
                name: null,
                props: null,
                isModalGift: false,
                isModalVideo: false,
                isModalGallery: false,
                isModalRules: false,
            };
        },
        methods: {
            show({ nameOrComponent, props }) {
                this.name = nameOrComponent;
                this.props = props;
                this.opened = true;

                window.addEventListener("keyup", this.onEscapeKeyUp);
                scrollState.disable();

                // if (nameOrComponent === "modal-gift") {
                //     this.isModalGift = true;
                // }

                if (nameOrComponent === "modal-video") {
                    this.isModalVideo = true;
                }

                if (nameOrComponent === "modal-gallery") {
                    this.isModalGallery = true;
                }

                if (nameOrComponent === "modal-safety-rules") {
                    this.isModalRules = true;
                }
            },
            close(action) {
                this.opened = false;
                window.removeEventListener("keyup", this.onEscapeKeyUp);
                scrollState.enable();
                this.isModalGift = false;
                this.isModalVideo = false;
                this.isModalGallery = false;
                this.isModalRules = false;

                if (
                    this.name === "modal-profile-auth" &&
                    this.$router.history.current.name.startsWith("ThePersonal")
                ) {
                    if (action === "actionFromUser") {
                        window.location.href = "/";
                    } else {
                        window.location.reload();
                    }
                }
            },
            onEscapeKeyUp(event) {
                if (event.which === 27) {
                    this.close("actionFromUser");
                }
            },
            onMouseDown({ target }) {
                if (target === this.$refs.popupContainer) {
                    this.close("actionFromUser");
                }
            },
        },
        mounted() {
            this.$eventBus.$on("popup-show", this.show);
            this.$eventBus.$on("popup-close", this.close);
            window.addEventListener("popstate", () => {
                this.close();
            });
        },
        beforeDestroy() {
            this.$eventBus.$off("popup-show", this.show);
            this.$eventBus.$off("popup-close", this.close);
            window.removeEventListener("popstate ", () => {});
        },
        components: {
            TheSvgIcon,
        },
    };
</script>

<template>
    <transition name="fade">
        <div v-if="opened" class="base-popup-overlay">
            <div class="base-popup-container" @mousedown="onMouseDown" ref="popupContainer">
                <div
                    class="base-popup"
                    :class="{
                        'base-popup--gift': isModalGift,
                        'base-popup--video': isModalVideo,
                        'base-popup--gallery': isModalGallery,
                        'base-popup--rules': isModalRules,
                    }"
                >
                    <button class="base-popup__close" @click="close('actionFromUser')">
                        <the-svg-icon name="close" :size-w="24"></the-svg-icon>
                    </button>
                    <div class="base-popup__content">
                        <div :is="name" v-bind="props"></div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="scss" scoped>
    .base-popup-overlay {
        --transition-duration: 0.25s;
        position: fixed;
        z-index: 2101;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(#000000, 0.7);
    }

    .base-popup-container {
        width: 100%;
        height: 100%;
        padding: 10px;
        outline: none;
        overflow-y: auto;
        box-sizing: border-box;
    }

    .base-popup {
        position: relative;
        z-index: 20;
        max-width: var(--modal-width);
        margin: 30px auto 10px;
        padding: var(--modal-padding);
        background-color: var(--base-white);
        border: 1px solid var(--primary-border);
        border-radius: var(--modal-radius);
        box-sizing: border-box;
        -webkit-overflow-scrolling: touch;
        @include bp($bp-desktop-sm) {
            &::before {
                content: "";
                @include center-absolute(-10px);
                z-index: -1;
            }
        }
    }

    .base-popup--gift {
        max-width: 650px;
    }

    .base-popup--rules {
        max-width: 700px;
        background-color: #fff7f7;
    }

    .base-popup--video {
        max-width: 800px;
        margin-top: 60%;
        padding: 0;
        background-color: #000000;
        border: none;
        .base-popup__close {
            top: -30px;
            right: 0;
            svg {
                width: 24px;
                height: 24px;
                filter: brightness(0) invert(100%);
            }
            @include bp($bp-desktop-sm) {
                top: -30px;
                right: -30px;
            }
        }
        @include bp($bp-desktop-sm) {
            margin-top: 10%;
        }
    }

    .base-popup--gallery {
        background-color: transparent;
        border: none;
        @include bp($bp-desktop-sm) {
            background-color: var(--base-white);
            border: 1px solid var(--primary-border);
        }
    }

    .base-popup__close {
        position: absolute;
        z-index: 1000;
        top: var(--modal-close-position);
        right: var(--modal-close-position);

        &:after {
            content: "";
            @include center-absolute(0);
            margin: -4px;
        }

        svg {
            display: block;
            width: var(--modal-close-size);
            height: var(--modal-close-size);
        }
    }

    .base-popup__header {
        padding-right: 10px;
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 24px;
    }

    .base-popup__body {
        margin-bottom: 10px;
    }
</style>
