import { mapArray, mapObject } from "@/core/mapping";

export function mapProfileUser({ user }) {
    return mapObject(user, {
        id: "id",
        name: "name",
        lastName: "lastName",
        birthday: "birthday",
        gender: "gender",
        photo: "photo",
        phone: "phone",
        email: "email",
        hash: "hash",
    });
}

export function mapProfileOrders(response) {
    return mapObject(response, {
        totalOrders: "total",
        lastPage: "last_page",
        orders: ["items", (items) => mapProfileOrder(items)],
    });
}

export function mapProfileUserAddresses({ items }) {
    return mapArray(items, {
        id: "id",
        name: "name",
        city: "city",
        street: "street",
        house: "house",
        flat: "flat",
        favorite: "favorite",
    });
}

export function mapProfileLoyaltyAccount({ account }) {
    return mapObject(account, {
        balance: "amount",
        levelId: "levelId",
        ordersAmount: "ordersAmount",
        ordersCount: "ordersCount",
    });
}

export function mapProfileLoyaltyTransactions({ items }) {
    return mapArray(items, {
        id: "id",
        type: "type",
        amount: "amount",
        date: "date",
        accrualDate: "accrualDate",
        orderId: "orderId",
        giftCard: "giftCard",
        description: "description",
    });
}

export function mapProfilePromoCoupons(coupons) {
    return mapArray(coupons, {
        id: "id",
        code: "code",
        personalOffer: "personalOffer",
    });
}

export function mapProfilePromoSales(sales) {
    return mapArray(sales, {
        id: "id",
        name: "name",
        defaultPage: "defaultPage",
        pages: "pages",
        targetBlank: "targetBlank",
        image: "mobileImage",
    });
}

function mapProfileOrder(items) {
    const orders = mapArray(items, {
        id: "id",
        site: ["site", (site) => site.name],
        number: "number",
        date: "date",
        totalPrice: "totalPrice",
        products: "positions",
        payments: ["payments", (data) => data[0]],
        buyer: ["buyer", (buyer) => mapProfileOrderBuyer(buyer)],
        delivery: ["delivery", (delivery) => mapProfileOrderDelivery(delivery)],
        tracking: "delivery",
        history: ["history", (history) => mapProfileOrderHistory(history)],
        paid: "paid",
        finished: "finished",
        status: ["status", (status) => status.name],
    });

    return orders.map((order) => {
        order.buyer.address = order.delivery.address;
        order.delivery.payment = order.payments.name;
        return order;
    });
}

function mapProfileOrderBuyer(data) {
    return mapObject(data, {
        name: "name",
        email: "email",
        phone: "phone",
    });
}

function mapProfileOrderDelivery(data) {
    return mapObject(data, {
        type: ["method", (method) => mapProfileOrderDeliveryMethod(method)],
        price: ["price", (price) => (price > 0 ? price + " ₽" : "Бесплатно")],
        payment: "price",
        address: ["address", (address) => Object.values(address).filter(Boolean).join(", ")],
    });
}

function mapProfileOrderDeliveryMethod(method) {
    if (method.type && method.company) {
        const types = { pickup: "Самовывоз", courier: "Курьером", post: "Почта" };
        return `${types[method.type.toLowerCase()]} (${method.company})`;
    }
    return "";
}

function mapProfileOrderHistory(data) {
    return mapArray(data, {
        date: "date",
        status: ["status", (status) => status.name],
    });
}
