/**
 * Naming Pattern: REQUEST and RECEIVE namespaces
 *
 * @example REQUEST_SOMETHING
 * @example RECEIVE_SOMETHING_SUCCESS
 * @example RECEIVE_SOMETHING_ERROR
 * @example POST - createSomething
 * @example PUT - updateSomething
 * @example DELETE - deleteSomething
 *
 */

export const SET_DATA_SITE = "SET_DATA_SITE";
export const SET_CART = "SET_CART";
export const SET_CITY = "SET_CITY";
export const CHECK_CITY = "CHECK_CITY";
export const SET_BANNERS = "SET_BANNERS";
export const SET_MEDIA = "SET_MEDIA";
export const SET_BRANDS = "SET_BRANDS";
export const SET_CATALOG = "SET_CATALOG";
export const RESET_CATALOG = "RESET_CATALOG";
export const SET_ALL_CATALOG = "SET_ALL_CATALOG";
export const SET_FAQ = "SET_FAQ";
export const SHOW_FREE_DELIVERY_MODAL = "SHOW_FREE_DELIVERY_MODAL";
export const HIDE_FREE_DELIVERY_MODAL = "HIDE_FREE_DELIVERY_MODAL";
