<script>
    import { apiUrl } from "@/utils";
    import TheSvgIcon from "@main/packages/TheSvgIcon";

    export default {
        name: "HeaderCartProduct",
        components: { TheSvgIcon },
        props: {
            product: Object,
            index: [Number, String],
        },
        data() {
            return {
                deleted: false,
            };
        },
        computed: {
            productName() {
                return this.product.shortName ? this.product.shortName : this.product.name;
            },
        },
        methods: {
            apiUrl,
            remove() {
                this.deleted = true;
                this.$emit("remove-product", this.product.id);
            },
        },
    };
</script>

<template>
    <div class="c-cart__item" :class="{ deleted: deleted }">
        <a :href="product.url" class="c-cart__item-link">
            <img class="c-cart__item-img" alt="" :src="apiUrl(product.src)" />
            <div class="c-cart__item-title">
                {{ productName }}
            </div>
            <div class="c-cart__item-price">{{ product.price | formatPrice }}₽</div>
        </a>

        <button class="c-cart__item-delete-wrap outline" @click="remove">
            <the-svg-icon
                name="trash"
                is-common
                :size-w="24"
                class="c-cart__item-delete"
            ></the-svg-icon>
        </button>
    </div>
</template>

<style lang="scss">
    .c-cart__item {
        position: relative;
        padding: 12px 16px;
        border-bottom: 1px solid var(--primary-border);
        transition: opacity 0.3s ease-in-out;

        &.deleted {
            opacity: 0.4;
        }

        &.disabled {
            .c-cart__item-link {
                pointer-events: none;
            }
        }
    }

    .c-cart__item-link {
        display: flex;
        align-items: center;
        font-size: 13px;
        line-height: 18px;
        padding-right: 32px;
        box-sizing: border-box;
        color: var(--primary-text);
    }

    .c-cart__item-img {
        display: block;
        width: 48px;
        align-self: center;
        flex-shrink: 0;
    }

    .c-cart__item-title {
        max-height: 54px;
        margin-left: 8px;
        flex-grow: 1;
        padding-right: 10px;
        box-sizing: border-box;
        overflow: hidden;
    }

    .c-cart__item-price {
        font-weight: 600;
        font-size: 16px;
        flex-shrink: 0;
    }

    .c-cart__item-delete-wrap {
        @include box(24px);
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        right: 16px;
        cursor: pointer;
        transition: fill 0.3s ease-in-out;
    }

    .c-cart__item-delete {
        fill: var(--primary--lightest);

        &:hover {
            fill: var(--danger);
        }
    }
</style>
