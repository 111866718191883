<script>
    export default {
        name: "ThePreloader",
        props: {
            show: Boolean,
            lgSize: Boolean,
            smSize: Boolean,
            isCatalog: Boolean,
            isColor: Boolean,
        },
    };
</script>

<template>
    <div
        class="global-preloader"
        :class="{
            'global-preloader--lg': lgSize,
            'global-preloader--sm': smSize,
            'global-preloader--catalog': isCatalog,
            'global-preloader--color': isColor,
        }"
        v-show="show"
    >
        <span></span>
    </div>
</template>

<style lang="scss">
    .global-preloader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: block;
        @include box(32px);
        z-index: 5;

        span {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            @include box(32px);

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                @include box(32px);
                border: 3px solid var(--primary);
                border-bottom: 3px solid transparent;
                border-radius: 50%;
                -webkit-animation: loader-3-1 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
                animation: loader-3-1 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                width: 6px;
                height: 6px;
                background: var(--primary);
                border-radius: 50%;
                -webkit-animation: loader-3-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
                animation: loader-3-2 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
            }
        }
    }

    .global-preloader--lg {
        @include box(48px);

        span {
            @include box(48px);

            &:before {
                @include box(48px);
                border-width: 5px;
            }

            &:after {
                @include box(7px);
            }
        }
    }

    .global-preloader--sm {
        @include box(20px);

        span {
            @include box(20px);

            &:before {
                @include box(20px);
                border-width: 2px;
            }

            &:after {
                @include box(4px);
            }
        }
    }

    .global-preloader--catalog {
        top: 200px;
        transform: translate(-50%, 0);
    }

    //.global-preloader--color {
    //    span {
    //        &:before {
    //            border-color: $yellow;
    //            border-bottom-color: transparent;
    //        }
    //
    //        &:after {
    //            background: $yellow;
    //        }
    //    }
    //}

    @keyframes loader-3-1 {
        0% {
            transform: rotate(0deg);
        }
        40% {
            transform: rotate(180deg);
        }
        60% {
            transform: rotate(180deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes loader-3-2 {
        0% {
            transform: translate3d(0, -32px, 0) scale(0, 2);
            opacity: 0;
        }
        50% {
            transform: translate3d(0, 0, 0) scale(1.25, 1.25);
            opacity: 1;
        }
        100% {
            transform: translate3d(0, 8px, 0) scale(0, 0);
            opacity: 0;
        }
    }
</style>
