import { mapArray, mapObject } from "@/core/mapping";

export function mapCheckoutResult({ data }) {
    return mapObject(data, {
        orderPrice: "PRICE",
        basketPrice: "BASKET_PRICE",
        orderDiscount: "DISCOUNT_PRICE",
        orderFullPrice: "BASE_PRICE",
        upSaleList: ["UP_SALE", (upSaleList) => mapObjectWithKeys(upSaleList, mapUpSales)],
        upSaleCart: ["UP_SALE_CART", (upSale) => mapUpSales(upSale)],
        basketItems: ["BASKET_ITEMS", (product) => mapObjectWithKeys(product, mapBasketList)],
        itemProperties: [
            "ITEM_PROPERTIES",
            (property) => mapObjectWithKeys(property, mapProperties),
        ],
        parentItems: ["PARENT_ITEMS", (parent) => mapObjectWithKeys(parent, mapParents)],
        // for pay
        paySystems: "PAY_SYSTEMS",
        paySystemId: "PAY_SYSTEM_ID",
        couponDiscountSum: "COUPON_DISCOUNT_SUM",
        // for success order
        success: "SUCCESS",
        html: "HTML",
        //for packing,
        packingPrice: "PACKING_PRICE",
        packingItems: "PACKING_ITEMS",
        packingCart: "PACKING_CART",
        // for order
        errors: "ERRORS",
        orderId: "ORDER_ID",
        userEmail: "USER_EMAIL",
        sitePhone: "SITE_PHONE",
        paramsForPay: ["INITIALIZED_PAY_PARAMS", (params) => mapPayParams(params)],
        orderPriceForSend: "ORDER_PRICE",
        deliveryPrice: "DELIVERY_PRICE",
        // for madRobots
        userData: ["PROPERTIES", (params) => mapUserData(params)],
        freeDeliveryCount: "FREE_SHIPPING_SUM",
        // for bonuses
        isErrorCertificate: "CARD_NOTICE",
        coupons: "COUPONS",
        couponDiscountPercent: "COUPON_DISCOUNT_PERCENT",
        giftCards: "GIFT_CARDS",
        isApplyBonuses: "IS_APPLY_BONUSES",
        bonusTotal: "BONUS_TOTAL",
        bonusBalance: "BONUS_BALANCE",
        isLoginUser: ["USER", (user) => (user ? Object.keys(user).length > 0 : false)],
        discountsData: ["APPLIED_DISCOUNTS", (discounts) => mapDiscountsData(discounts)],
        dimensions: ["DIMENSIONS", (dimensions) => mapProductDimensions(dimensions)],
    });
}

export function mapBasket(data) {
    return mapObject(data, {
        orderPrice: "order_total",
        basketPrice: "basket_total",
        orderDiscount: "order_discount",
        orderFullPrice: "order_full_total",
        total: "total",
        basketItems: ["cart", (product) => mapObjectWithKeys(product, mapBasketList)],
        upSaleCart: ["upsale", (upSaleList) => mapUpSales(upSaleList)],
    });
}

function mapUserData(data) {
    return mapObject(data, {
        city: ["CITY", (key) => key.VALUE],
        street: ["STREET", (key) => key.VALUE],
        house: ["HOUSE", (key) => key.VALUE],
        build: ["BUILD", (key) => key.VALUE],
        housing: ["HOUSING", (key) => key.VALUE],
        flat: ["FLAT", (key) => key.VALUE],
        name: ["NAME", (key) => key.VALUE],
        lastName: ["LAST_NAME", (key) => key.VALUE],
        phone: ["PHONE", (key) => key.VALUE],
        email: ["EMAIL", (key) => key.VALUE],
    });
}

function mapProductDimensions(data) {
    return mapArray(data, {
        w: "0",
        h: "1",
        l: "2",
    });
}

function mapProperties(data) {
    return mapObject(data, {
        color: "COLOR",
        parentId: "PARENT",
        quantity: "QUANTITY",
        size: "SIZE",
    });
}

function mapPayParams(data) {
    return mapObject(data, {
        accountId: "accountId",
        invoiceId: "invoiceId",
        publicId: "publicId",
        amount: "amount",
        currency: "currency",
        description: "description",
        email: "email",
    });
}

function mapBasketList(data) {
    return mapObject(data, {
        id: "ID",
        url: "DETAIL_PAGE_URL",
        name: "NAME",
        parentId: "PARENT",
        productId: "PRODUCT_ID",
        price: "PRICE",
        basePrice: "BASE_PRICE",
        discountPrice: "DISCOUNT_PRICE",
        quantity: "QUANTITY",
        sectionName: "SECTION_NAME",
        brand: "VENDOR",
        isAdultPacking: "IS_ADULT_PACKING",
    });
}

function mapParents(data) {
    return mapObject(data, {
        colorsList: ["COLORS_LIST", (colorList) => mapObjectWithKeys(colorList, mapColorList)],
        sizesInColor: ["SIZES_IN_COLOR", (colorSizesList) => colorSizesList],
        images: "IMAGES",
        sizes: "SIZES",
        map: "MAP",
    });
}

function mapColorList(data) {
    return mapObject(data, {
        image: "image",
        name: "name",
        offerId: "offerId",
    });
}

function mapUpSales(data) {
    return mapObject(data, {
        id: "id",
        url: "code",
        name: "name",
        parentId: "parent",
        productId: "id",
        price: "price",
        basePrice: "old_price",
        discountPrice: ["old_price", "price", (oldPrice, price) => oldPrice - price],
        quantity: "quantity",
        picture: "picture",
        inCart: "in_cart",
        discountPercent: "discount_percent",
        monoSiteCategory: "monoSiteCategory",
        monoSiteCategoryParentId: "monoSiteCategoryParentId",
        sectionName: "category",
        isUpSale: [() => true],
        brand: "brand",
        sameSite: "sameSite",
    });
}

function mapDiscountsData(data) {
    return mapArray(data, {
        result: "RESULT",
        isCatalogDiscount: "IS_CATALOG_DISCOUNT",
        isCouponDiscount: "IS_COUPON_DISCOUNT",
        isGift: "IS_GIFT",
    });
}

//
// Helper
//

function mapObjectWithKeys(obj, mappingCall) {
    if (!obj) return false;
    let currentObj = {};
    Object.keys(obj).forEach((key) => {
        currentObj[key] = mappingCall(obj[key]);
    });
    return currentObj;
}
