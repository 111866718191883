import {
    mapCatalog,
    mapProduct,
    mapGallery,
    mapDelivery,
    mapStoresAmount,
    mapVideoProduct,
    mapReviews,
    mapPartnerLinks,
} from "../mapping/products";

export default function ($api) {
    function getItems({ items }) {
        let extendedCardList = [];
        let cardsList = {};
        let cloneProductsList = [...items];

        items.forEach((product, keyProduct) => {
            if (!product.displayOnMain) return;

            let countOffer = 0;
            let listOfferIds = [];
            product.offers.forEach((offer, keyOffer) => {
                if (offer.displayOnMain) {
                    countOffer++;
                    let cloneProduct = Object.assign({}, product);
                    cloneProduct.offers = [product.offers[keyOffer]];
                    cloneProduct.isClone = true;
                    extendedCardList.push(cloneProduct);
                    cardsList[keyProduct] = extendedCardList;
                    listOfferIds.push(offer.id);
                }
            });
            if (!countOffer) return;

            if (countOffer === Object.keys(product.offers).length) {
                cloneProductsList.splice(keyProduct, 1, ...cardsList[keyProduct]);
            } else {
                let currentProduct = cloneProductsList.find(
                    (innerProduct) => product.id === innerProduct.id
                );
                [...product.offers].forEach((innerOffer) => {
                    if (listOfferIds.includes(innerOffer.id)) {
                        currentProduct.offers.splice(
                            currentProduct.offers.findIndex((e) => e.id === innerOffer.id),
                            1
                        );
                    }
                });
                cloneProductsList.splice(keyProduct + 1, 0, ...cardsList[keyProduct]);
            }
        });
        return cloneProductsList;
    }
    return {
        /**
         * Получить список товаров
         *
         * @return { Object }
         */
        getCatalog(query = "") {
            return $api
                .get("api/product/all?sorts=popular&filters[eol][null]=1" + query)
                .then(mapCatalog)
                .then(getItems);
        },

        /**
         * Получить список всех товаров (в том числе "EOL")
         *
         * @return { Object }
         */
        getAllCatalog(query = "") {
            return $api.get("api/product/all?sorts=popular" + query).then(mapCatalog);
        },
        /**
         * Получить список товаров по идентификатору
         *
         * @param  { Array } listIds - список идентификаторов для поиска
         * @param  { Number } excludedId - исключенный идентификатор
         * @param  { Number } categoryId - идентификатор категории товара
         * @param  { Boolean } isClone - развертка карточки на офферы
         * @param  { Boolean } filterAvailable - фильтровать по доступности
         * @param  { Boolean } filterEol - фильтровать по eol
         * @return { Object }
         */
        getProductsListById({
            listIds,
            excludedId,
            categoryId,
            isClone = false,
            filterAvailable = true,
            filterEol = true,
        }) {
            let optionalParams = "";
            if (categoryId) optionalParams += `filters[categoriesIds][eq]=${categoryId}&`;
            if (excludedId) optionalParams += `filters[id][neq]=${excludedId}&`;
            if (!categoryId) {
                listIds.forEach((id) => (optionalParams += `filters[id][in][]=${id}&`));
            }
            if (filterAvailable) optionalParams += "filters[available][eq]=1&";
            if (filterEol) optionalParams += "filters[eol][null]=1&";

            return $api
                .get(`api/product/all?${optionalParams}sorts=popular`)
                .then(mapCatalog)
                .then((res) => {
                    return isClone ? getItems(res) : res;
                });
        },
        /**
         * Получить страницу товара
         *
         * @param  { String } slug - уникальная строка идентификатор
         * @return { Object }
         */
        getProduct({ slug }) {
            return $api.get(`api/product/one?filters[slug][eq]=${slug}`).then(mapProduct);
        },
        /**
         * Получить галерею товара
         *
         * @param  { String } type - productsIds или offersIds тип фильтрации
         * @param  { String } id - уникальная идентификатор
         * @return { Object }
         */
        getProductGallery({ type, id }) {
            return $api
                .get(`api/product-image/all?filters[${type}][eq]=${id}`)
                .then(mapGallery);
        },
        /**
         * Способы доставки для товара
         *
         * @param  { String } city - город
         * @param  { Number } offerId - id торгового предложения
         * @return { Object }
         */
        getDeliveryForProduct({ city, offerId }) {
            return $api
                .get("api/delivery/product-items", {
                    city,
                    offerId,
                })
                .then(mapDelivery);
        },
        /**
         * Получение отзывов о товаре
         *
         * @param { Number } id - идентификатор товара
         * @returns { Object }
         */
        getProductReview({ id }) {
            return $api
                .get(`api/product-review/all?filters[productId][eq]=${id}`)
                .then(mapReviews);
        },
        /**
         * Получить остаток товара по складам
         *
         * @param  { Number } offerId - offerId товара
         * @return { Object }
         */
        getProductStoresAmount({ offerId }) {
            return $api
                .get(
                    `api/stores-amount/all?filters[productId][eq]=${offerId}&sorts[amount]=desc`
                )
                .then(mapStoresAmount);
        },
        /**
         * Отправить предзаказ
         *
         * @param  { String } name - наименование товра
         * @param  { String } phone - телефон
         * @param  { String } email - id offer товара
         * @param  { Number } productId - id товара
         * @param  { String } city - город
         * @return { Object }
         */
        sendPreOrder({ name, phone, email, productId, city }) {
            return $api.post("api/order/pre-order/", {
                name,
                phone,
                email,
                product: productId,
                city,
            });
        },
        /**
         * Получение видосов о продукте
         *
         * @param  { Number } productId - наименование товра
         * @return { Object }
         */
        getVideoAboutProduct({ productId }) {
            const url = `api/product-video/all?filters[productId][eq]=${productId}&filters[active][eq]=true&sorts[sort]=asc`;
            return $api.get(url).then(mapVideoProduct);
        },

        /**
         * Отправка данных для покупки в один клик
         * @param data
         * @returns { Object }
         */
        sendOneClickForm(data) {
            return $api.post("api/order/buy-one-click/", data);
        },
        /**
         * Получить список товаров с исключением
         *
         * @param  { Number } excludedId - исключенный идентификатор
         * @return { Object }
         */
        // getProductsListWithExcluded({ excludedId }) {
        //     return $api
        //         .get(`api/product/all?filters[id][neq]=${excludedId}&filters[eol][null]=1`)
        //         .then(mapCatalog);
        // },
        /**
         * Получить ссылки на товар у партнеров
         *
         * @param  { Number } productId - исключенный идентификатор
         * @return { Object }
         */
        getProductPartnerLinks({ productId }) {
            return $api
                .get(`api/product/partner-links?productId=${productId}`)
                .then(mapPartnerLinks);
        },
    };
}
