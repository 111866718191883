import Vue from "vue";
import "@main/packages/TheModal/Plugin";

const ModalStatusOrder = () =>
    import(
        /* webpackChunkName: "Modal_Status_Order" */ "@main/packages/TheModal/components/ModalStatusOrder"
    );
const ModalSelectCity = () =>
    import(
        /* webpackChunkName: "Modal_Select_City" */ "@main/packages/TheModal/components/ModalSelectCity"
    );
const ModalPreOrder = () =>
    import(
        /* webpackChunkName: "Modal_Pre_Order" */ "@main/packages/TheModal/components/ModalPreOrder"
    );
const ModalCallback = () =>
    import(
        /* webpackChunkName: "Modal_Call_back" */ "@main/packages/TheModal/components/ModalCallback"
    );
const ModalOneClick = () =>
    import(
        /* webpackChunkName: "Modal_One_Click" */ "@main/packages/TheModal/components/ModalOneClick"
    );

const ModalVideo = () =>
    import(
        /* webpackChunkName: "Modal_Video" */ "@main/packages/TheModal/components/ModalVideo"
    );
const ModalProfileAuth = () =>
    import(
        /* webpackChunkName: "Modal_Profile_Login" */ "@main/packages/TheModal/components/ModalProfileAuth"
    );

Vue.component("modal-select-city", ModalSelectCity);
Vue.component("modal-pre-order", ModalPreOrder);
Vue.component("modal-callback", ModalCallback);
Vue.component("modal-one-click", ModalOneClick);
Vue.component("modal-status-order", ModalStatusOrder);
Vue.component("modal-video", ModalVideo);
Vue.component("modal-profile-auth", ModalProfileAuth);
