import { mapArray, mapPage } from "@/core/mapping";
import { removeDuplicates } from "@/utils";

export function mapBanners(banners) {
    return mapPage(banners, {
        id: "id",
        name: "name",
        src: "picture",
        srcMobile: "mobilePicture",
        link: "link",
        active: "active",
    });
}

export function mapArticles(article) {
    return mapPage(article, {
        id: "id",
        name: "name",
        previewDescription: "previewDescription",
        previewImage: "previewImage",
        externalUrl: "externalUrl",
        isMedia: "media",
        active: "active",
        tags: "tags",
        idVideo: ["externalUrl", (url) => getYtId(url)],
    });
}

function getYtId(url) {
    let regexp = /(youtu\.be\/|youtube\.com\/(watch\?(.*&)?v=|(embed|v)\/))([^\\?&"'>]+)/;
    let matches = url.match(regexp);
    return matches ? matches[5] : undefined;
}

export function mapBrands(brand) {
    return mapPage(brand, {
        id: "id",
        name: "name",
        externalUrl: "link",
        previewImage: "logo",
    });
}

//TODO: PETONEER
export function mapReviewsInstagram({ items }) {
    return mapArray(items, {
        id: "id",
        link: "link",
        photo: "photo",
        author: "author",
        picture: "picture",
        text: "text",
    });
}

export function mapStretchings(response) {
    return mapArray(response, {
        backgroundColor: "background",
        link: "link",
        picture: "image",
        city: "city",
    });
}

export function mapFaq({ items }) {
    const faqSections = removeDuplicates(
        mapArray(items, {
            id: ["section", (section) => section.id],
            name: ["section", (section) => section.name],
        })
    );
    const faqElements = mapArray(items, {
        sectionId: ["section", (section) => section.id],
        id: "id",
        title: "question",
        desc: "answer",
    });
    return faqSections.map((section) => {
        return {
            ...section,
            elements: faqElements.filter((element) => element.sectionId === section.id),
        };
    });
}
