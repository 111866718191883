<script>
    import { isMobile, apiUrl } from "@/utils";
    import TheSvgIcon from "@main/packages/TheSvgIcon";

    export default {
        name: "HeaderMenuList",
        components: {
            TheSvgIcon,
        },
        props: {
            dropDownMenu: Array,
        },
        data() {
            return {
                isDropdown: false,
            };
        },
        computed: {
            firstEl() {
                return this.dropDownMenu[0];
            },
            listCategory() {
                return this.dropDownMenu.slice(1);
            },
        },

        methods: {
            openDropdown() {
                if (isMobile()) {
                    this.isDropdown = true;
                }
            },
            apiUrl,
        },
    };
</script>

<template>
    <div class="header-menu-list">
        <router-link
            tag="div"
            :to="{
                name: 'CatalogCategory',
                params: { slug: firstEl.slug },
            }"
            class="header-menu-list__image"
            exact
        >
            <img :src="apiUrl(firstEl.menuPicture)" alt="" />
        </router-link>
        <div class="header-menu-list__title" @click="openDropdown">
            {{ firstEl.name }}
            <router-link
                :to="{
                    name: 'CatalogCategory',
                    params: { slug: firstEl.slug },
                }"
                class="header-menu-list__title-text-clone"
                exact
            >
            </router-link>
            <the-svg-icon
                v-if="!isDropdown"
                name="arrow_right"
                is-common
                :size-w="16"
                :size-h="24"
                class="header-menu-list__title-arrow"
            ></the-svg-icon>
            <router-link
                v-else
                :to="{
                    name: 'CatalogCategory',
                    params: { slug: firstEl.slug },
                }"
                class="header-menu-list__title-text"
                exact
            >
                Перейти
            </router-link>
        </div>
        <transition name="slide">
            <ul v-show="isDropdown" class="header-menu-list__list">
                <li
                    class="header-menu-list__item"
                    v-for="category in listCategory"
                    :key="category.id"
                >
                    <router-link
                        :to="{
                            name: 'CatalogCategory',
                            params: { slug: category.slug },
                        }"
                        class="header-menu-list__link"
                        exact
                    >
                        {{ category.name }}
                        <the-svg-icon
                            name="arrow_right"
                            is-common
                            :size-w="16"
                            :size-h="24"
                        ></the-svg-icon>
                    </router-link>
                </li>
            </ul>
        </transition>
    </div>
</template>

<style lang="scss">
    .header-menu-list {
        padding: 0 16px;
        border-bottom: 1px solid var(--primary-border--light);

        @include bp($bp-desktop-sm) {
            margin: 0 12px;
            padding: 0;
            border-bottom: none;
        }
    }

    .header-menu-list__image {
        display: none;

        @include bp($bp-desktop-sm) {
            display: block;
            margin-bottom: 16px;
        }
    }

    .header-menu-list__title {
        position: relative;
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        line-height: 24px;
        color: var(--primary-text);
        padding: 12px 0;

        @include bp($bp-desktop-sm) {
            font-weight: 600;
            padding: 0;

            svg {
                display: none;
            }
        }
    }

    .header-menu-list__title-text {
        @include center-absolute(0);
        margin: auto;
        font-size: 12px;
        line-height: 24px;
        padding: 12px 0;
        color: var(--primary);
        text-align: right;
        z-index: z("default");
    }

    .header-menu-list__title-text-clone {
        display: none;

        @include bp($bp-desktop-sm) {
            display: block;
            @include center-absolute(0);
            margin: auto;
            font-size: 12px;
            line-height: 24px;
            padding: 12px 0;
            color: var(--primary);
            text-align: right;
            z-index: z("default");
        }
    }

    .header-menu-list__title-arrow {
        transform: rotate(90deg);
    }

    .header-menu-list__list {
        background-color: var(--neutral--light);
        margin: 0 -16px;

        @include bp($bp-desktop-sm) {
            display: block !important;
            background-color: transparent;
            margin: 0;
        }
    }

    .header-menu-list__item {
        padding: 0 16px;

        @include bp($bp-desktop-sm) {
            margin-top: 8px;
            padding: 0;
        }
    }

    .header-menu-list__link {
        display: flex;
        justify-content: space-between;
        font-size: 15px;
        line-height: 24px;
        color: var(--primary-text);
        padding: 12px 0;

        @include bp($bp-desktop-sm) {
            font-size: 13px;
            line-height: 17px;
            color: var(--secondary-text);
            padding: 0;
            outline-offset: 3px;

            svg {
                display: none;
            }
        }
    }
</style>
