// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@tb/assets/icons/logo.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".the-header-mobile{height:56px}@media only screen and (min-width:992px){.the-header-mobile{display:none}}.the-header-mobile__wrapper{position:relative;z-index:70;left:0;width:100%;padding:5px 0;background-color:#fff}.the-header-mobile__wrapper:after{content:\"\";position:absolute;bottom:-1px;left:0;right:0;height:1px;background-color:var(--qgtf1y);opacity:0}.the-header-mobile__wrapper.hide-border:after{opacity:0!important}.the-header-mobile__wrapper.fix-scroll-block{position:fixed}.the-header-mobile__wrapper.fix-scroll-block:after{opacity:1}.the-header-mobile__wrapper.fix-position-top{top:0}.the-header-mobile__scroll{position:relative;z-index:30;display:flex;align-items:center;justify-content:space-between;background-color:#fff}.the-header-mobile__burger{display:flex;justify-content:center;align-items:center;width:46px;height:46px;margin-left:auto;margin-right:-12px}.the-header-mobile__logo-wrapper{width:200px}.the-header-mobile__logo{position:absolute;top:0;bottom:0;left:0;right:0;display:block;width:117px;height:27px;margin:auto;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-repeat:no-repeat}.the-header-mobile__menu{position:fixed;top:57px;left:0;width:100%;height:calc(100vh - 57px);height:calc(var(--vh, 1vh)*100 - 57px);padding-top:56px;z-index:40;overflow:auto;transform:translateY(-112%);transition:transform .3s ease-in-out}.the-header-mobile__menu.is-opened{transform:translateY(0)}", ""]);
// Exports
module.exports = exports;
