import { mapCheckoutResult, mapBasket } from "@/api/mapping/order";
import { mapCart } from "@/api/mapping/root";

export default function ($api) {
    return {
        /**
         * Чекаута, результат работы компонента
         *
         * @returns {Object} deliveryItems, deliveryService, deliveryServices, paySystems
         */
        checkoutResult(data) {
            return $api.post("api/checkout/result/", data).then(mapCheckoutResult);
        },

        /**
         * Изменение позиций корзины в чекауте
         *
         * @returns {Object}
         */
        modifyQuantityOrder({ quantity, product_id, PAY_SYSTEM_ID, isUpSale }) {
            let path = isUpSale ? "add-up-sale" : "modify-order";

            return $api
                .post(`api/basket/${path}/`, {
                    quantity,
                    product_id,
                    PAY_SYSTEM_ID,
                })
                .then(mapBasket);
        },

        /**
         * Изменение позиций корзины в чекауте
         *
         * @returns {Object}
         */
        replaceProductCheckout({ product_id, basket_id, PAY_SYSTEM_ID, isUpSale }) {
            let path = isUpSale ? "add-up-sale" : "replace";
            // TODO: использовать как и в кол-во, он заменит автоматом
            return $api
                .post(`api/basket/${path}/`, {
                    basket_id,
                    product_id,
                    PAY_SYSTEM_ID,
                })
                .then(mapBasket);
        },
        /**
         * Удаление апсейла из корзину
         *
         * @return { Object }
         */
        removeUpSale() {
            return $api.post("api/basket/remove-up-sale/");
        },
        /**
         * Удаление всех товаров из корзины
         *
         * @return { Object }
         */
        removeAllProducts() {
            return $api.post("api/basket/remove-all/").then(mapCart);
        },

        /**
         * Получить данные о способе доставках, агентов, платежной системы
         *
         *  убрна deliveryId по факту это был сервис id
         * @returns {Object} deliveryItems, deliveryService, deliveryServices, paySystems
         */
        getDeliveryCheckout({ city, address, paySystemId }) {
            return $api.get("api/delivery/basket-items/", {
                city,
                address,
                paySystemId,
            });
        },
    };
}
