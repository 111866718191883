import Vue from "vue";

/**
 * Формирование адреса api
 *
 * @param  { String } url - адрес
 * @return { Object }
 */
const apiUrl = (url) => Vue.prototype.$_url + url;

export { apiUrl };
