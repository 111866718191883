/**
 * Монтирование скрипта YouTube Iframe API
 *
 * @param { number|string } videoId - Уникальный идентификатор видео
 * @param { string } name - Название скрипта. Используется для избегания дублирования
 * @param { function } onLoad - Функция выполняемая при загрузке скрипта
 */
const createYouTubeScript = ({ videoId, name }, onLoad) => {
    let tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    tag.dataset.id = videoId;
    if (name) {
        tag.dataset.name = name;
    }
    let firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    tag.addEventListener("load", () => {
        if (typeof onLoad === "function") {
            onLoad();
        }
    });
};

/**
 * Удаление скрипта YouTube Iframe API из DOM
 *
 * @param { number|string } name - название скрипта (videoId при создании)
 */
const destroyYouTubeScript = (name) => {
    const scriptTagsSelector = `[data-name="${name}"]`;
    const youtubeApiScripts = document.head.querySelectorAll(scriptTagsSelector);
    if (youtubeApiScripts.length) {
        youtubeApiScripts.forEach((script) => {
            script.parentNode.removeChild(script);
        });
    }
};

/**
 * Создание YouTube плеера
 *
 * @param { number|string } videoId - Уникальный идентификатор видео
 * @param { number|string } id - Уникальный идентификатор плеера
 * @param { function } onReady - Функция выполняемая при готовности плеера
 */
const onYouTubeIframeAPIReady = ({ videoId, id }, onReady) => {
    window.YT.ready(function () {
        new window.YT.Player(`${id}`, {
            height: "472",
            width: "840",
            videoId: `${videoId}`,
            events: {
                onReady: (event) => {
                    if (typeof onReady === "function") {
                        onReady(event);
                    }
                },
            },
        });
    });
};

export { createYouTubeScript, destroyYouTubeScript, onYouTubeIframeAPIReady };
