// import { mapCart } from "../mapping/root";

import { mapCompareProducts, mapColorsList, mapCompareIds } from "../mapping/compare";

export default function ($api) {
    return {
        /**
         * СРАВНЕНИЕ
         */

        /**
         * Получить список товаров добавленных для сравнения
         *
         * @return { Object }
         */
        getComparison() {
            return $api.get("api/comparison/get/").then(mapCompareProducts);
        },
        /**
         * Получить список идентификатор товаров добавленных для сравнения
         *
         * @return { Object }
         */
        getComparisonIds() {
            return $api.get("api/comparison/get-comparisons/").then(mapCompareIds);
        },
        /**
         * Добавить товар в список сравнения
         *
         * @param { Number } id - уникальный идентификатор товара
         * @returns {Object}
         */
        addToComparison(id) {
            return $api.post("api/comparison/add/", { productId: id });
        },
        /**
         * Удалить товар из списка сравнения
         *
         * @param { Number } id - уникальный идентификатор товара
         * @returns {Object}
         */
        removeFromComparison(id) {
            return $api.post("api/comparison/remove/", { productId: id });
        },
        /**
         * Очистить список сравнения
         *
         * @returns {Object}
         */
        clearComparison() {
            return $api.post("api/comparison/remove-all/");
        },
        /**
         * Получить все цвета товаров
         *
         * @returns {Object}
         */
        getColorsList() {
            return $api.get("api/product/colors/").then(mapColorsList);
        },
        /**
         * Получить историю товаров бренда
         *
         * @returns {Object}
         */
        getProductsHistory() {
            return $api.get("api/product-history/all/").then((response) => {
                return response;
            });
        },
    };
}
