/**
 * Получение переменной из Local Storage
 *
 * @param { string } name - Имя переменной
 * @returns { string }
 */
const getVariable = (name) => {
    try {
        return window.localStorage.getItem(name);
    } catch (err) {
        return "";
    }
};

/**
 * Запись переменной в Local Storage
 *
 * @param { string } key - Имя переменной
 * @param { string } value - Записываемое значение
 * @returns { string }
 */
const setVariable = (key, value) => {
    try {
        window.localStorage.setItem(key, value);
    } catch (err) {
        return "";
    }
};

/**
 * Удаление переменной из Local Storage
 *
 * @param { string } key - Имя удаляемой переменной
 * @returns { string }
 */
const removeVariable = (key) => {
    try {
        window.localStorage.removeItem(key);
    } catch (err) {
        return "";
    }
};

export { getVariable, setVariable, removeVariable };
