import { mapSiteData, mapStatusOrders } from "../mapping/root";

export default function ($api) {
    return {
        /**
         * Получить данные о сайте
         *
         * @return { Object }
         */
        getDataSite() {
            return $api.get("api/site/current/").then(mapSiteData);
        },

        /**
         * ГОРОД
         */
        /**
         * Автоопределение города
         *
         * @return { Object } { 'cityName' }
         */
        getDefaultCity() {
            return $api.get("api/city/get-default/");
        },
        /**
         * Получение списка городов
         *
         * @param  { String } term - строка ввода названия города
         * @return { Array }
         */
        getListCity({ term }) {
            return $api.get("api/city/get/", { term });
        },
        /**
         * Сохранение выбранного города
         *
         * @param  { String } city - город
         * @return { Array }
         */
        saveSelectedCity({ city }) {
            return $api.post("api/city/save/", { city });
        },

        /**
         * ДРУГОЕ...
         */
        /**
         * Получение статуса заказа
         *
         * @param  { Number } orderId - id заказа(трек-номер)
         * @param { String } phone - номер телефона
         * @return { Object }
         */
        getStatusOrder({ orderId, phone }) {
            return $api
                .post("api/order/status/", {
                    orderId,
                    phone,
                })
                .then(mapStatusOrders);
        },
        /**
         * Заказать обратный звонок
         *
         * @param  { String } name - имя
         * @param  { String } phone - телефон
         * @return { Object }
         */
        requestCallback({ name, phone }) {
            return $api.post("api/form/tell-me/", {
                name,
                phone,
            });
        },
    };
}
