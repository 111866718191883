<script>
    export default {
        name: "HeaderBurger",
        props: {
            state: Boolean,
        },
        data() {
            return {
                clickDelayTimerBurger: null,
                stateClosing: false,
                clickDelayBurger: 500,
            };
        },
        watch: {
            state: {
                handler(val) {
                    if (!val) {
                        this.stateClosing = true;
                    }

                    this.clickDelayTimerBurger = setTimeout(() => {
                        this.stateClosing = false;
                        clearTimeout(this.clickDelayTimerBurger);
                        this.clickDelayTimerBurger = null;
                    }, this.clickDelayBurger);
                },
            },
        },
    };
</script>

<template>
    <span class="header-burger" :class="{ 'is-open': state, 'is-closing': stateClosing }">
        <span class="header-burger__wrap">
            <span class="header-burger__line"></span>
            <span class="header-burger__line"></span>
            <span class="header-burger__line"></span>
        </span>
    </span>
</template>

<style lang="scss">
    $menu-animation-duration: 400ms;
    $menu-animation-timing: ease-out;

    .header-burger {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &.is-open {
            .header-burger__line {
                &:nth-child(1) {
                    animation: burger-open-top $menu-animation-duration $menu-animation-timing
                        forwards;
                }

                &:nth-child(2) {
                    opacity: 0;
                    transition: transform $menu-animation-duration $menu-animation-timing,
                        opacity 0ms linear ($menu-animation-duration, 2);
                }

                &:nth-child(3) {
                    animation: burger-open-bot $menu-animation-duration $menu-animation-timing
                        forwards;
                }
            }
        }

        &.is-closing {
            .header-burger__line {
                &:nth-child(1) {
                    animation: burger-close-top $menu-animation-duration $menu-animation-timing
                        forwards;
                }

                &:nth-child(3) {
                    animation: burger-close-bot $menu-animation-duration $menu-animation-timing
                        forwards;
                }
            }
        }
    }

    .header-burger__wrap {
        position: relative;
        width: 18px;
        height: 14px;
    }

    .header-burger__line {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 1px;
        background-color: #182028;
        transform-origin: 50% 50%;
        transition: transform $menu-animation-duration $menu-animation-timing;

        &:nth-child(1) {
            top: 0;
        }
        &:nth-child(2) {
            top: 6px;
            opacity: 1;
            transition: transform $menu-animation-duration $menu-animation-timing,
                opacity 0ms linear ($menu-animation-duration, 2);
        }
        &:nth-child(3) {
            top: 12px;
        }
    }

    //
    // animation
    //

    @keyframes burger-open-top {
        50% {
            transform: translate3d(0, 6px, 0);
        }
        100% {
            transform: translate3d(0, 6px, 0) rotate(45deg);
        }
    }

    @keyframes burger-open-bot {
        50% {
            transform: translate3d(0, -6px, 0);
        }
        100% {
            transform: translate3d(0, -6px, 0) rotate(-45deg);
        }
    }

    @keyframes burger-close-top {
        0% {
            transform: translate3d(0, 6px, 0) rotate(45deg);
        }
        50% {
            transform: translate3d(0, 6px, 0) rotate(0deg);
        }
        100% {
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes burger-close-bot {
        0% {
            transform: translate3d(0, -6px, 0) rotate(-45deg);
        }
        50% {
            transform: translate3d(0, -6px, 0) rotate(0deg);
        }
        100% {
            transform: translate3d(0, 0, 0);
        }
    }
</style>
