import Vue from "vue";

export function initVueVariables(config) {
    Vue.prototype.$_name = config.name;
    Vue.prototype.$_nameOfficial = config.nameOfficial;
    Vue.prototype.$_ymId = config.ymId;
    Vue.prototype.$_url = config.urlApi; //Vue.config.productionTip ? "prod" : "dev";
    Vue.prototype.$_documents = config.documents;
    Vue.prototype.$_nameDev = config.name; //"xd-design"; //TODO: удалить, заменить в компонентах  &_nameDev на &_name
}
