import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

Vue.use(VueRouter);
Vue.use(VueMeta, {
    ssrAppId: 1, // https://vue-meta.nuxtjs.org/guide/caveats.html#duplicated-tags-after-hydration-with-ssr
});

export const createRouter = (routes) => {
    return new VueRouter({
        mode: "history",
        routes,
        linkActiveClass: "active",
        linkExactActiveClass: "exact-active",

        scrollBehavior(to, from, savedPosition) {
            if (to.hash && document.querySelector(to.hash)) {
                return window.scrollTo({
                    top: document.querySelector(to.hash).offsetTop - 65,
                    behavior: "smooth",
                });
            } else {
                return savedPosition || { x: 0, y: 0 };
            }
        },
    });
};
