<script>
    import TheSvgIcon from "@main/packages/TheSvgIcon";
    import { mapActions } from "vuex";

    export default {
        name: "TheHeaderSearch",
        components: {
            TheSvgIcon,
        },
        data() {
            return {
                searchVal: this.$route.query.term || "",
                focus: false,
            };
        },
        computed: {
            activeSearch() {
                return this.searchVal.length > 0;
            },
        },
        watch: {
            $route() {
                if (this.$router.history.current.name !== "Search") {
                    this.searchVal = "";
                }
            },
        },
        methods: {
            ...mapActions("Search", ["getSearch"]),

            async search(e) {
                e.preventDefault();
                if (!this.activeSearch) return;
                if (this.$router.history.current.name === "Search") {
                    await this.getSearch({ term: this.searchVal });
                } else {
                    this.$router.push({ name: "Search", query: { term: this.searchVal } });
                }
            },
        },
    };
</script>

<template>
    <form
        @submit="search"
        class="c-search"
        :class="{ 'c-search--focus': focus || activeSearch }"
    >
        <label class="c-search__field">
            <input
                v-model="searchVal"
                class="c-search__input"
                placeholder="Я ищу..."
                type="text"
                @focus="focus = true"
                @blur="focus = false"
            />
        </label>
        <button @click="search" class="c-search__btn outline">
            <the-svg-icon name="search" :size-w="24"></the-svg-icon>
        </button>
    </form>
</template>

<style lang="scss">
    .c-search {
        display: flex;
        align-items: center;
        flex-grow: 1;
        border: 2px solid var(--primary-border--light);
        border-radius: 2px;
        transition: 0.3s ease-in-out all;

        @include bp($bp-desktop-sm) {
            margin-left: 24px;
            &:focus-within {
                border-color: var(--secondary);

                .c-search__btn {
                    background: var(--secondary);
                }
            }
        }
    }

    .c-search--focus {
        border-color: var(--secondary);

        .c-search__btn {
            background: var(--secondary);
        }
    }

    .c-search__field {
        flex-grow: 1;
    }

    .c-search__input {
        width: 100%;
        font-size: 15px;
        line-height: 24px;
        padding: 10px 14px;
        border: none;

        @include input-placeholder {
            color: #a2a2a2;
        }
    }

    .c-search__btn {
        flex-shrink: 0;
        @include box(40px);
        margin-right: 2px;
        background: var(--primary-border--light);
        border-radius: 2px;
        transition: 0.3s ease-in-out background-color;

        svg {
            vertical-align: middle;
        }
    }
</style>
