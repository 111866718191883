/**
 * Lazy loading with IntersectionObserver
 *
 * @param  { String } url - адрес
 * @return { Object }
 */
const options = {
    parentClass: ".js-lazy-load > img",
    lazyParentClass: ".js-lazy-load",
    lazyItemClass: "img",
};
const optionsObserver = {
    threshold: 0.6,
};

const lazyLoad = () => {
    if (process.env.VUE_APP_ENV === "client") {
        const notSupportIntersectionObserver =
            !("IntersectionObserver" in window) ||
            !("IntersectionObserverEntry" in window) ||
            !("intersectionRatio" in window.IntersectionObserverEntry.prototype);

        if (notSupportIntersectionObserver) {
            let loadParents = document.querySelectorAll(options.parentClass);
            const interval = 120;
            loadParents.forEach((item, index) => {
                setTimeout(() => {
                    let path = item.getAttribute("data-src");
                    item.setAttribute("src", path);
                    item.classList.add("loaded");
                }, interval * index);
            });
        } else {
            let lazyLoadParents = document.querySelectorAll(options.lazyParentClass);
            // console.log(lazyLoadParents);
            const lazyLoad = new IntersectionObserver((entries) => {
                entries.map((entry) => {
                    if (!entry.isIntersecting) return false;
                    const images = entry.target.querySelectorAll(options.lazyItemClass);
                    if (images.length) {
                        images.forEach((img) => {
                            if (img.dataset.src) {
                                img.src = img.dataset.src;
                                img.removeAttribute("data-src");
                            }
                            img.onload = () => {
                                img.setAttribute("lazy", "loaded");
                            };
                        });
                    }
                });
            }, optionsObserver);

            lazyLoadParents.forEach((item) => {
                lazyLoad.observe(item);
            });
        }
    }
};

export { lazyLoad };
