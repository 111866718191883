import { mapArray, mapObject } from "@/core/mapping";
import { mapOffers } from "./products";

export function mapCompareProducts(products) {
    return mapArray(products, {
        id: ["product", (product) => mapProduct(product).id],
        name: ["product", (product) => mapProduct(product).name],
        shortName: ["product", (product) => mapProduct(product).shortName],
        slug: ["product", (product) => mapProduct(product).slug],
        price: ["product", (product) => mapProduct(product).price],
        discountPrice: ["product", (product) => mapProduct(product).discountPrice],
        inCart: ["product", (product) => mapProduct(product).inCart],
        previewImage: ["product", (product) => mapProduct(product).image],
        eol: ["product", (product) => mapProduct(product).eol],
        offers: ["product", (product) => mapProduct(product).offers],
        specs: ["groups", (specs) => mapProductSpecs(specs)],
    });

    // return products.map((product) => {
    //     return {
    //         id: product.product.id,
    //         name: product.product.name,
    //         slug: product.product.slug,
    //         price: product.product.price,
    //         discountPrice: product.product.discountPrice,
    //         inCart: product.product.inCart,
    //         image: mapImage(product.product.previewImage),
    //         offers: product.product.offers,
    //         specs: mapProductSpecs(product.groups),
    //     };
    // });
}

function mapProduct(product) {
    return mapObject(product, {
        id: "id",
        name: "name",
        shortName: "shortName",
        slug: "slug",
        price: "price",
        discountPrice: "discountPrice",
        inCart: "inCart",
        image: ["previewImage", (image) => mapImage(image)],
        offers: ["offers", (offer) => mapOffers(offer)],
        eol: "eol",
    });
}

function mapProductSpecs(specs) {
    return mapArray(specs, {
        name: "name",
        code: "code",
        sort: "sort",
        subCategory: "fields",
    });
}

function mapImage(data) {
    return mapObject(data, {
        original: "original",
        medium: "medium",
        small: "small",
    });
}

export function mapColorsList(colors) {
    return mapArray(colors, {
        value: "id",
        text: "name",
        code: "code",
    });
}

export function mapCompareIds(ids) {
    return mapArray(ids, {
        id: "productId",
    });
}
