<script>
    import { mapGetters } from "vuex";
    import TheSvgIcon from "@main/packages/TheSvgIcon";
    import HeaderMenuList from "@tb/components/HeaderMenuList";

    export default {
        name: "HeaderMenu",
        components: {
            HeaderMenuList,
            TheSvgIcon,
        },
        computed: {
            ...mapGetters("Catalog", ["formattedCategoryNames"]),
        },
        methods: {
            close() {
                this.$emit("close-mobile-catalog");
            },
        },
    };
</script>

<template>
    <div class="header-menu">
        <div class="l-container">
            <div class="header-menu__wrap">
                <div class="header-menu__mobile-back" @click="close">
                    <the-svg-icon
                        name="arrow_right"
                        is-common
                        :size-w="16"
                        :size-h="24"
                        class="header-menu__mobile-arrow"
                    ></the-svg-icon>
                    Назад
                </div>
                <header-menu-list
                    :dropDownMenu="dropDownMenu"
                    v-for="(dropDownMenu, i) in formattedCategoryNames"
                    :key="i"
                ></header-menu-list>
            </div>
        </div>

        <router-link :to="{ name: 'Catalog' }" class="header-menu__more">
            Все товары
        </router-link>
    </div>
</template>

<style lang="scss">
    .header-menu {
        position: fixed;
        top: 113px;
        left: 0;
        width: 100%;
        height: calc(100vh - 113px);
        height: calc((var(--vh, 1vh) * 100) - 113px);
        background-color: #fff;
        z-index: 45;
        overflow: auto;

        @include bp($bp-desktop-sm) {
            top: 96px;
            padding-top: 0;
            padding-bottom: 0;
            position: absolute;
            box-shadow: 0px 40px 44px rgba(0, 0, 0, 0.4);
            height: auto;
            z-index: z("header");
        }
    }

    .header-menu__wrap {
        margin: 0 -16px;

        @include bp($bp-desktop-sm) {
            display: flex;
            margin: 0 -12px;
        }
    }

    .header-menu__more {
        display: none;

        @include bp($bp-desktop-sm) {
            display: block;
            margin-top: 64px;
            width: 100%;
            font-weight: 600;
            font-size: 17px;
            line-height: 24px;
            text-align: center;
            padding: 15px;
            background-color: var(--secondary);
            color: var(--primary-text);
            outline-offset: -2px;
        }
    }

    .header-menu__mobile-back {
        display: flex;
        padding: 12px 16px;
        font-weight: 600;
        font-size: 15px;
        line-height: 24px;
        background-color: var(--neutral--light);
        border-top: 1px solid var(--primary-border--light);
        border-bottom: 1px solid var(--primary-border--light);

        @include bp($bp-desktop-sm) {
            display: none;
        }
    }

    .header-menu__mobile-arrow {
        transform: rotate(180deg);
        margin-right: 8px;
    }
</style>
