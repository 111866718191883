<script>
    import { setVariable } from "@/utils";
    import TheSvgIcon from "@main/packages/TheSvgIcon";
    import BaseButton from "@main/packages/BaseButton";

    export default {
        name: "SelectCityConfirmation",
        components: {
            BaseButton,
            TheSvgIcon,
        },
        props: {
            city: String,
            variantSubmitButton: {
                type: String,
                default: "v2",
            },
            variantCancelButton: {
                type: String,
                default: "v3",
            },
        },
        methods: {
            close() {
                this.$emit("on-close");
            },
            clickHandler(type) {
                this.close();
                if (type === "confirm") {
                    setVariable("city_selected", true);
                }
                if (type === "select") {
                    setTimeout(() => {
                        this.$popup.show("modal-select-city");
                    }, 200);
                }
            },
        },
    };
</script>

<template>
    <transition name="fade">
        <div class="confirm-city-modal-wrap">
            <div class="confirm-city-modal">
                <div class="confirm-city-modal__overlay" @click="close"></div>
                <div class="confirm-city-modal__inner">
                    <div class="confirm-city-modal__content">
                        <button class="confirm-city-modal__close" @click="close">
                            <the-svg-icon name="close" is-common :size-w="16"></the-svg-icon>
                        </button>
                        <div class="confirm-city-modal__title">Вы находитесь здесь?</div>
                        <div class="confirm-city-modal__city">{{ city }}</div>
                        <div class="confirm-city-modal__controls">
                            <base-button
                                class="confirm-city-modal__button"
                                :variant="variantCancelButton"
                                size="sm"
                                @click="clickHandler('select')"
                            >
                                Нет, другое
                            </base-button>
                            <base-button
                                class="confirm-city-modal__button"
                                :variant="variantSubmitButton"
                                size="sm"
                                @click="clickHandler('confirm')"
                            >
                                <span class="confirm-city-modal__button-text desktop-hidden"
                                    >Да, верно</span
                                >
                                <span class="confirm-city-modal__button-text mobile-hidden"
                                    >Да</span
                                >
                            </base-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<style lang="scss">
    .confirm-city-modal-wrap {
        position: relative;
        z-index: 2100;
        //transition: opacity 0.1s ease-in;
    }

    .confirm-city-modal {
        position: fixed;
        z-index: 1005;
        left: 0;
        bottom: 0;
        display: flex;
        align-items: flex-end;
        width: 100%;
        @include bp($bp-desktop-sm) {
            position: absolute;
            //left: 16px;
            left: calc((100vw - 1292px) / 2);
            display: block;
            top: 40px;
        }
    }

    .confirm-city-modal__overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        width: 100%;
        z-index: 1004;
        background-color: rgba(0, 0, 0, 0.25);
        @include bp($bp-desktop-sm) {
            display: none;
        }
    }

    .confirm-city-modal__close {
        position: absolute;
        top: 16px;
        right: 24px;
    }

    .confirm-city-modal__inner {
        position: relative;
        z-index: 1005;
        width: 100%;
        background-color: #fff;

        @include bp($bp-desktop-sm) {
            width: 300px;
        }
    }

    .confirm-city-modal__content {
        padding: 16px 24px 24px;
        @include bp($bp-tablet-sm) {
            max-width: 360px;
        }
    }

    .confirm-city-modal__title {
        margin-bottom: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
    }

    .confirm-city-modal__city {
        margin-bottom: 12px;
        font-size: 14px;
        line-height: 18px;
        min-height: 18px;
        font-weight: 700;
    }

    .confirm-city-modal__controls {
        display: flex;
    }

    .confirm-city-modal__button {
        flex-basis: 50%;
        flex-grow: 1;
        &:first-child {
            margin-right: 8px;
        }
        @include bp($bp-desktop-sm) {
            &:last-child {
                max-width: 84px;
            }
        }
    }
</style>
