import axios from "axios";
const log = console.log;

export function createApi(baseUrl) {
    let counter = 1;
    const id = (Math.random() * 1e6).toFixed().padStart(6, "0");

    return Object.assign({
        handlers: {
            catch: [],
        },

        // GET
        async get(url, data) {
            return this.send("GET", url + queryString(data, true));
        },
        // PUT
        async put(url, data) {
            return this.send("PUT", url, data);
        },
        // POST
        async post(url, data, isFile) {
            return this.send("POST", url, isFile ? data : queryString(data));
        },
        // PATCH
        async patch(url, data) {
            return this.send("PATCH", url, data);
        },
        // DELETE
        async delete(url, data) {
            return this.send("DELETE", url, data);
        },

        async send(method, url, data = "") {
            const number = counter++;
            log(`#${id}_№${number} → ${method} ${baseUrl}/${url}`, data);

            const headers = {};

            if (typeof data === "string") {
                headers.post = { "Content-Type": "application/x-www-form-urlencoded" };
            }

            if (typeof data === "object") {
                headers.post = { "Content-Type": "multipart/form-data" };
            }

            const t0 = new Date().getTime();
            return axios
                .request({
                    withCredentials: true,
                    method,
                    url: baseUrl + "/" + url,
                    headers,
                    data,
                    validateStatus(status) {
                        return status >= 200 && status < 300;
                    },
                })
                .then((response) => {
                    return response.data;
                })
                .catch((err) => {
                    log(`#${id}_№${number} ← Ошибка`, err);
                    const error = err.response
                        ? new HttpError(err.response.status, err.response.data)
                        : err;
                    this.handlers.catch.forEach((h) => h(error));
                    throw error;
                })
                .finally(() => {
                    const t1 = new Date().getTime();
                    log(`#${id}_№${number} ← ${t1 - t0}ms`);
                });
        },

        catch(handler) {
            this.handlers.catch.push(handler);
        },
    });
}

function queryString(obj, isGet = false) {
    let str = "";
    for (var p in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, p) && obj[p] !== undefined) {
            if (Array.isArray(obj[p])) {
                obj[p].forEach((x, index) => {
                    if (typeof x === "object") {
                        Object.keys(x).forEach((key) => {
                            str +=
                                "&" +
                                encodeURIComponent(p + `[${index}][${key}]`) +
                                "=" +
                                encodeURIComponent(x[key]);
                        });
                    } else {
                        str +=
                            "&" + encodeURIComponent(p + "[]") + "=" + encodeURIComponent(x);
                    }
                });
            } else {
                str += "&" + encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]);
            }
        }
    }
    return str.length > 0 ? (isGet ? "?" : "") + str.substr(1) : str;
}

function HttpError(status, data) {
    this.status = status;
    this.data = data;
}
