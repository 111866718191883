<script>
    export default {
        name: "TheCheckoutStepFour",
        props: {
            routerStep: Number,
        },
        created() {
            this.$emit("child-init", this.routerStep);
        },
        beforeRouteLeave(to, from, next) {
            this.$emit("set-empty-checkout");
            next();
        },
    };
</script>

<template>
    <div class="the-checkout-step-four"></div>
</template>
