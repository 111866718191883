<script>
    import { sendAnalytics } from "@/core/SendAnalytics";
    import BaseButton from "@main/packages/BaseButton";

    export default {
        name: "SubscribeForm",
        components: { BaseButton },
        props: {
            variantButton: {
                default: "v2",
                type: String,
            },
        },
        data() {
            return {
                email: "",
                successSubmit: false,
                errorSubmit: "",
            };
        },
        methods: {
            sendForm(e) {
                e.preventDefault();
                this.errorSubmit = "";
                if (this.email.length > 0) {
                    this.$store.optionalApi.subscribeTo({ email: this.email }).then((res) => {
                        if (res.error) {
                            this.errorSubmit = "Неверный email";
                        } else {
                            this.successSubmit = true;
                            sendAnalytics.send(sendAnalytics.events.subscribe);
                        }
                    });
                }
            },
        },
    };
</script>

<template>
    <form class="form-subscribe" @submit="sendForm">
        <label class="form-subscribe__field">
            <input
                class="form-subscribe__input"
                :class="{ error: errorSubmit, 'is-yellow': variantButton === 'v1' }"
                type="email"
                v-model="email"
                placeholder="Электронная почта"
            />
        </label>

        <base-button :variant="variantButton" size="md" class="form-subscribe__button">
            Подписаться
        </base-button>

        <div v-show="successSubmit" class="form-subscribe__success">Подписка оформлена!</div>
    </form>
</template>

<style lang="scss">
    .form-subscribe {
        position: relative;
        display: flex;
        flex-wrap: wrap;
    }

    .form-subscribe__field {
        flex-grow: 1;
    }

    .form-subscribe__input {
        width: 100%;
        font-size: 15px;
        line-height: 24px;
        padding: 10px 16px;
        background-color: #fff;
        border-radius: 10px;
        border: 2px solid #fff;

        &.error {
            border-color: var(--danger-text--light);
        }

        &.is-yellow {
            border-color: var(--secondary);
        }
    }

    .form-subscribe__button {
        margin-top: 8px;
        margin-left: 0;
        width: 100%;

        @include bp($bp-desktop-sm) {
            margin-top: 0;
            margin-left: 8px;
            width: auto;
        }
    }

    .form-subscribe__success {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 17px;
        line-height: 24px;
        color: var(--primary);
        border-radius: 3px;
        background-color: #fff;
        border: 2px solid var(--secondary);
        z-index: 1;
    }
</style>
