import {
    mapProfileLoyaltyAccount,
    mapProfileLoyaltyTransactions,
    mapProfileOrders,
    mapProfilePromoCoupons,
    mapProfilePromoSales,
    mapProfileUser,
    mapProfileUserAddresses,
} from "@/api/mapping/profile";

export default function ($api) {
    return {
        /**
         * Получение текущего пользователя
         * @returns {*}
         */
        getProfileUser() {
            return $api.get("api/auth/me/").then(mapProfileUser);
        },

        /**
         * Редактирование профиля пользователя
         * @param { Object } user
         * @returns {*}
         */
        updateProfileUser(user) {
            return $api.post("api/profile/update/", user);
        },

        /**
         * Изменение фотографии профиля пользователя
         * @param { Object }  photo
         * @returns {*}
         */
        updateProfileUserPhoto(photo) {
            return $api.post("api/profile/update-photo/", photo, true);
        },

        /**
         * Получение списка заказов пользователя
         * @returns {*}
         */
        getProfileOrders(page, type) {
            return $api
                .get(
                    `api/profile-orders/all/?type=${type}&sorts[date]=desc&paging[per_page]=12&paging[page]=${page}`
                )
                .then(mapProfileOrders);
        },

        /**
         * Получение списка адресов пользователя
         * @returns {*}
         */
        getProfileUserAddresses() {
            return $api.get("api/profile-addresses/all/").then(mapProfileUserAddresses);
        },

        /**
         * Добавление адреса пользователя
         * @param { Object } address
         * @returns {*}
         */
        addProfileUserAddress(address) {
            return $api.post("api/profile-addresses/add/", address);
        },

        /**
         * Изменение адреса пользователя
         * @param { Object } address
         * @returns {*}
         */
        updateProfileUserAddress(address) {
            return $api.post("api/profile-addresses/update/", address);
        },

        /**
         * Удаление адреса пользователя
         * @param { Number } id
         * @returns {*}
         */
        removeProfileUserAddress(id) {
            return $api.post("api/profile-addresses/remove/", { id });
        },

        /**
         * Установить адрес пользователя как основной
         * @param { Number } id
         */
        favoriteProfileUserAddress(id) {
            return $api.post("api/profile-addresses/favorite/", { id });
        },

        /**
         * Получение текущего аккаунта программы лояльности
         * @returns {*}
         */
        getProfileLoyaltyAccount() {
            return $api.get("api/profile-loyalty/account/").then(mapProfileLoyaltyAccount);
        },

        /**
         * Получение операций по счету пользователя в программе лояльности
         * @returns {*}
         */
        getProfileLoyaltyTransactions() {
            return $api
                .get("api/profile-loyalty/transactions/")
                .then(mapProfileLoyaltyTransactions);
        },

        /**
         * Зачисление подарочного сертификата на бонусный счет
         * @param { String }  card
         */
        setProfileGiftCertificate(card) {
            return $api.post("api/profile-loyalty/income-card/", { card });
        },

        /**
         * Получение списка персональных купонов
         * @returns {*}
         */
        getProfilePromoCoupons() {
            return $api.get("api/profile-promo/active-coupons/").then(mapProfilePromoCoupons);
        },

        /**
         * Получение списка активных промо акций
         * @returns {*}
         */
        getProfilePromoSales() {
            return $api.get("api/profile-promo/active-actions/").then(mapProfilePromoSales);
        },

        /**
         * Авторизация пользователя
         * @param { Object } data
         * @returns {*}
         */
        loginProfile(data) {
            return $api.post("api/auth/login/", data);
        },

        /**
         * Отправка кода для авторизации
         * @param  { String, Number } identifier
         * @returns {*}
         */
        sendAuthCode(identifier) {
            return $api.post("api/auth/send-code/", { identifier });
        },

        /**
         * Регистрация нового пользователя
         * @param { Object } data
         * @returns {*}
         */
        registerProfile(data) {
            return $api.post("api/auth/register/", data);
        },

        /**
         * Выход из личного кабинета
         * @returns {*}
         */
        logoutProfile() {
            return $api.get("api/auth/logout/");
        },
    };
}
