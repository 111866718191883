var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-menu-list"},[_c('router-link',{staticClass:"header-menu-list__image",attrs:{"tag":"div","to":{
            name: 'CatalogCategory',
            params: { slug: _vm.firstEl.slug },
        },"exact":""}},[_c('img',{attrs:{"src":_vm.apiUrl(_vm.firstEl.menuPicture),"alt":""}})]),_c('div',{staticClass:"header-menu-list__title",on:{"click":_vm.openDropdown}},[_vm._v(" "+_vm._s(_vm.firstEl.name)+" "),_c('router-link',{staticClass:"header-menu-list__title-text-clone",attrs:{"to":{
                name: 'CatalogCategory',
                params: { slug: _vm.firstEl.slug },
            },"exact":""}}),(!_vm.isDropdown)?_c('the-svg-icon',{staticClass:"header-menu-list__title-arrow",attrs:{"name":"arrow_right","is-common":"","size-w":16,"size-h":24}}):_c('router-link',{staticClass:"header-menu-list__title-text",attrs:{"to":{
                name: 'CatalogCategory',
                params: { slug: _vm.firstEl.slug },
            },"exact":""}},[_vm._v(" Перейти ")])],1),_c('transition',{attrs:{"name":"slide"}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDropdown),expression:"isDropdown"}],staticClass:"header-menu-list__list"},_vm._l((_vm.listCategory),function(category){return _c('li',{key:category.id,staticClass:"header-menu-list__item"},[_c('router-link',{staticClass:"header-menu-list__link",attrs:{"to":{
                        name: 'CatalogCategory',
                        params: { slug: category.slug },
                    },"exact":""}},[_vm._v(" "+_vm._s(category.name)+" "),_c('the-svg-icon',{attrs:{"name":"arrow_right","is-common":"","size-w":16,"size-h":24}})],1)],1)}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }