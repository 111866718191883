function state() {
    return {
        blog: [],
        detailBlog: {
            name: "",
        },
        otherBlog: [],
        blogProducts: [],
        loading: false,
    };
}

const getters = {};

const mutations = {
    SET_BLOG(state, value) {
        state.blog = value;
    },
    SET_DETAIL_BLOG(state, value) {
        state.detailBlog = value;
    },
    CLEAR_BLOG(state) {
        state.detailBlog = {
            name: "",
        };
        state.blogProducts = [];
        state.otherBlog = [];
    },
    SET_OTHER_BLOG(state, value) {
        state.otherBlog = value;
    },
    SET_PRODUCTS(state, value) {
        state.blogProducts = value;
    },
    START_LOADING(state) {
        state.loading = true;
    },
    STOP_LOADING(state) {
        state.loading = false;
    },
};

const actions = {
    async getBlog({ commit }) {
        commit("START_LOADING");
        return this.tbApi.getBlog().then((response) => {
            commit("SET_BLOG", response.items);
            commit("STOP_LOADING");
        });
    },
    async getDetailBlog({ commit }, slug) {
        commit("CLEAR_BLOG");
        commit("START_LOADING");
        return this.tbApi.getDetailBlog(slug).then((response) => {
            commit("SET_DETAIL_BLOG", response);
            commit("STOP_LOADING");
            return response;
        });
    },
    async getOtherBlog({ commit }, id) {
        return this.tbApi.getOtherBlog(id).then((response) => {
            commit("SET_OTHER_BLOG", response.items);
        });
    },
    async getProductsForBlog({ commit }, ids) {
        return this.tbApi.getProductsForBlog(ids).then((response) => {
            commit("SET_PRODUCTS", response.items);
        });
    },
    async clearBlog({ commit }) {
        commit("CLEAR_BLOG");
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
