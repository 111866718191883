/**
 * Задержка при выполнении
 *
 * @param { function } fn - Callback функция
 * @param { number }delay
 * @returns {(function(): void)|*}
 */
const debounce = (fn, delay) => {
    let timeoutID = null;
    return function () {
        clearTimeout(timeoutID);
        let args = arguments;
        timeoutID = setTimeout(() => fn.apply(this, args), delay);
    };
};
// TODO: проверить debounce

const throttle = (fn, wait) => {
    let time = 0;
    return function () {
        if (time + wait - Date.now() < 0) {
            fn();
            time = Date.now();
        }
    };
};

export { debounce, throttle };
