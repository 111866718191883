const description = "Купить оригинал по доступной цене на сайте travel-blue.ru";

export const metaTb = {
    home: {
        title: "Аксессуары для путешествий Travel Blue",
        description,
        slug: "",
    },
    catalog: {
        title: "Товары для путешественников Travel Blue",
        description:
            "Товары для сна и комфорта, аксессуары для багажа, сумки, рюкзаки и органайзеры, адаптеры и кабели. Купить по доступной цене",
        slug: "c",
    },
    delivery: {
        title: "Доставка и оплата",
        description,
        slug: "p-dostavka-i-oplata",
    },
    guarantee: {
        title: "Гарантия и возврат",
        description,
        slug: "p-garantiya",
    },
    order: {
        title: "Корзина",
        description,
        slug: "order",
    },
    store: {
        title: "Наши магазины",
        description,
        slug: "p-o-magazine",
    },
    blog: {
        title: "Блог Travel Blue: обзоры товаров для путешествий, советы туристам, интересные факты о разных странах",
        description:
            "Что взять в дорогу? Как защитить свой багаж? Как путешествовать с комфортом? Статьи, рекомендации, гайды и подборки полезных товар, которые помогут подготовиться к поездкам по России и миру от официального представителя бренда Travel Blue",
        slug: "blog",
    },
    search: {
        title: "Поиск аксессуаров для путешествий Travel Blue",
        description,
        slug: "search",
    },
};
