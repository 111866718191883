function state() {
    return {
        breadcrumbs: [],
        catalog: [],
        categoryNames: [],
        categories: null,
        pageTotal: 0,
        loading: false,
    };
}

const getters = {
    mainSection: (state) => state.categoryNames.filter((item) => item.depthLevel === 2),
    mainCategories: (state) => state.categoryNames.filter((item) => item.depthLevel === 3),
    formattedCategoryNames: (state) =>
        state.categoryNames
            .map((el) => {
                let filtered = state.categoryNames
                    .filter((item) => item.depthLevel === 3)
                    .filter((item) => {
                        return el.id == item.sectionId;
                    });

                if (filtered.length > 0) {
                    filtered.unshift(el);
                    return filtered;
                }
            })
            .filter(Boolean),
};

const mutations = {
    SET_CATALOG(state, { items, total }) {
        state.catalog = items;
        state.pageTotal = total;
    },
    SET_CATEGORY_NAMES(state, { items }) {
        state.categoryNames = items;
    },
    SET_CATEGORIES(state, items) {
        state.categories = items;
    },
    START_LOADING(state) {
        state.loading = true;
    },
    END_LOADING(state) {
        state.loading = false;
    },
    CLEAR_CATALOG(state, update = true) {
        if (update) {
            state.pageTotal = 0;
        }
        state.catalog = [];
    },
    SET_BREADCRUMBS(state, data) {
        state.breadcrumbs = data;
    },
};

const actions = {
    getCatalogForId({ commit }, { id, sort }) {
        if (sort) {
            commit("CLEAR_CATALOG", false);
        }

        commit("START_LOADING");
        return this.tbApi.getCatalogForId({ id, sort }).then((response) => {
            commit("SET_CATALOG", response);
            commit("END_LOADING");
        });
    },
    getCatalog({ commit }, { list }) {
        commit("START_LOADING");
        let listCategories = "";
        list.forEach((num) => (listCategories += `categoriesIds[]=${num}&`));

        return this.tbApi.getCatalogTB({ listCategories }).then((response) => {
            commit("SET_CATEGORIES", response);
            commit("END_LOADING");
        });
    },
    getCategoryNames({ commit }) {
        return this.tbApi.getCategoryNames().then((response) => {
            commit("SET_CATEGORY_NAMES", response);
        });
    },
    clearCatalog({ commit }) {
        commit("CLEAR_CATALOG");
    },
    getBreadCrumbs({ commit }, { mainCategory }) {
        return this.tbApi.getBreadCrumbs({ mainCategory }).then((response) => {
            commit("SET_BREADCRUMBS", response);
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
