import Vue from "vue";
import { createTemplateApp } from "@/createTemplateApp";

import "@/settings/additional";
import "@/settings/modals";
import "@tb/sprites";

import ThePreloader from "@tb/components/ThePreloader";
Vue.component("preloader", ThePreloader);

import App from "./App";
import TRAVEL_BLUE from "./config";
import { metaTb } from "./meta";
import { travelBlueRouter } from "./router";
import { travelBlueStores } from "./store";

Vue.config.productionTip = false;

export const createApp = (context) => {
    return createTemplateApp({
        context,
        renderApp: App,
        config: TRAVEL_BLUE,
        metaList: metaTb,
        routerList: travelBlueRouter,
        storeList: travelBlueStores,
    });
};
