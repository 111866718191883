/**
 * Шаблон настроек для сайтов
 *
 * @param  { String } useDeskId - id usedesk
 * @param  { String } themeColor - цветовая тема для браузера
 * @param  { String } yandexVerification - id яндекса
 * @param  { String } facebookVerification - id facebook pixel
 * @param  { Number } ymId - id яндекс метрики
 * @param  { String } name - название бренда
 * @param  { String } nameOfficial - официальное название бренда
 * @param  { Number } idGTM - id google tag manager
 * @param  { String } font - font family
 * @param  { Object } documents - site documents links
 * @return { Object }
 */
function createTemplate({
    useDeskId,
    themeColor,
    yandexVerification,
    facebookVerification = "",
    ymId,
    name,
    nameOfficial,
    idGTM,
    font,
    documents,
}) {
    return {
        useDeskId,
        themeColor,
        yandexVerification,
        facebookVerification,
        ymId,
        name,
        nameOfficial: nameOfficial ? nameOfficial : name,
        urlApi: createApi(name),
        idGTM,
        font,
        documents,
    };
}
/**
 * Формирование адреса api
 *
 * @param  { String } name - название бренда
 * @return { Object }
 */
function createApi(name) {
    const isTestApi = process.env.VUE_APP_API;
    if (name === "ledger") name = "ledgerwalletrussia";
    if (name === "mi-ku") name = "miku-russia";
    const domain = name === "zavtra" ? "co" : "ru";

    return process.env.NODE_ENV === "development" || isTestApi
        ? `https://test-api.${name}.${domain}`
        : `https://api.${name}.${domain}`;
}

module.exports = { createTemplate };
