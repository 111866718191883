<script>
    import { mapActions, mapState } from "vuex";
    import TheSvgIcon from "@main/packages/TheSvgIcon";
    import HeaderCartDropdown from "@tb/components/HeaderCartDropdown";
    import FreeDeliveryModal from "@/components/FreeDeliveryModal";

    export default {
        name: "HeaderCart",
        components: {
            FreeDeliveryModal,
            TheSvgIcon,
            HeaderCartDropdown,
        },
        props: {
            isOrderPage: Boolean,
            cart: Object,
            freeDeliveryCount: Number,
        },
        data() {
            return {
                stateModal: false,
            };
        },
        computed: {
            ...mapState("Root", ["stateFreeDeliveryModal"]),
            hasProduct() {
                return this.cart.number > 0;
            },
            resultToFreeDelivery() {
                return this.freeDeliveryCount - this.cart.totalPrice; // TODO: какой использовать price? TOTAL_PRICE vs REAL_TOTAL_PRICE
            },
        },
        watch: {
            stateFreeDeliveryModal: {
                handler(updatedState) {
                    this.stateModal = updatedState;
                },
            },
        },
        methods: {
            ...mapActions("Root", ["removeProductInCart"]),
            sleep(ms) {
                return new Promise((resolve) => setTimeout(resolve, ms));
            },
            async startAnimation() {
                this.$refs.cartIcon.classList.toggle("is-animated");
                await this.sleep(300);
                this.$refs.cartIcon.classList.toggle("is-animated");
            },
            removeProduct(productId) {
                this.removeProductInCart({ quantity: 0, productId });
            },
            hideModal() {
                this.stateModal = false;
            },
            goToOrder() {
                if (this.hasProduct) {
                    this.$router.push({
                        name: "Order",
                    });
                }
            },
        },
    };
</script>

<template>
    <div class="c-cart">
        <div
            class="c-cart__button"
            :class="{ 'c-cart__button--is-order': isOrderPage || !hasProduct }"
        >
            <div
                class="c-cart__line outline"
                @click="goToOrder"
                @keypress.enter="goToOrder"
                :tabindex="isOrderPage || !hasProduct ? '-1' : '0'"
            >
                <div class="c-cart__icon">
                    <div v-show="hasProduct" class="c-cart__icon-number" ref="cartIcon">
                        {{ cart.number }}
                    </div>
                    <the-svg-icon name="cart" :size-w="24"></the-svg-icon>
                </div>

                <div class="c-cart__text">Корзина</div>
            </div>
            <header-cart-dropdown
                v-if="hasProduct && !isOrderPage"
                :cart="cart"
                @remove-product="removeProduct"
            ></header-cart-dropdown>
        </div>

        <free-delivery-modal
            v-show="stateModal"
            :result-to-free-delivery="resultToFreeDelivery"
            :button-variants="['v1', 'v3']"
            @hide-modal="hideModal"
        ></free-delivery-modal>
        <!--        <div v-if="headerCart.NUM_PRODUCTS" class="c-cart__dropdown">-->
        <!--            <div class="c-cart__dropdown-wrap">-->
        <!--                <div class="c-cart__body">-->
        <!--                    <cart-item-->
        <!--                        :goods="item"-->
        <!--                        :index="i"-->
        <!--                        v-for="(item, i) in headerCart.ITEMS"-->
        <!--                        :key="item.PRODUCT_ID"-->
        <!--                    ></cart-item>-->
        <!--                </div>-->
        <!--                <div class="c-cart__footer">-->
        <!--                    <div class="c-cart__total">-->
        <!--                        <div class="c-cart__total-text">Товаров на сумму:</div>-->
        <!--                        <div class="c-cart__total-number">-->
        <!--                            {{ headerCart.REAL_TOTAL_PRICE | formatPrice }}₽-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                    <router-link :to="{ name: 'Order' }" class="c-cart__button">-->
        <!--                        Оформить заказ-->
        <!--                    </router-link>-->
        <!--                </div>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>

<style lang="scss">
    .c-cart {
    }

    .c-cart__button {
        display: none;

        @include bp($bp-desktop-sm) {
            display: block;
            margin-left: 50px;
            position: relative;
            padding: 12px 0;
            cursor: pointer;

            &:hover,
            &:focus-within {
                .c-cart-dropdown {
                    transform: translateY(0);
                    opacity: 1;
                    visibility: visible;
                }

                .c-cart__icon {
                    svg {
                        fill: var(--danger--lighter);
                    }
                }

                .c-cart__text {
                    color: var(--danger--lighter);
                }
            }
        }
    }

    .c-cart--hide {
        opacity: 0;
        pointer-events: none;
        visibility: hidden;
    }

    .c-cart__line {
        position: relative;
        display: flex;
        align-items: center;
        margin-left: 50px;
    }

    .c-cart__icon {
        position: relative;
        display: flex;
    }

    .c-cart__icon-number {
        position: absolute;
        top: -4px;
        right: -5px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        font-size: 11px;
        font-weight: 600;
        line-height: 16px;
        color: #fff;
        opacity: 1;
        text-align: center;
        background-color: var(--danger-text--light);

        &.is-animated {
            animation: grow-shrink 0.35s ease 1;
        }

        &.is-active {
            opacity: 1;
        }
    }

    @keyframes grow-shrink {
        30% {
            transform: scale(1.4);
        }
        65% {
            transform: scale(0.8);
        }
        100% {
            transform: scale(1);
        }
    }

    .c-cart__text {
        font-size: 15px;
        line-height: 24px;
        margin-left: 8px;
    }
</style>
