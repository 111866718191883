import TheCheckoutStepOne from "@/TheCheckout/TheCheckoutStepOne";
import TheCheckoutStepTwo from "@/TheCheckout/TheCheckoutStepTwo";
import TheCheckoutStepThree from "@/TheCheckout/TheCheckoutStepThree";
import TheCheckoutStepFour from "@/TheCheckout/TheCheckoutStepFour";

export const travelBlueRouter = [
    {
        path: "/",
        name: "Home",
        component: () => import(/* webpackChunkName: "TB-Home" */ "../pages/Home"),
    },
    {
        path: "/c",
        name: "Catalog",
        component: () => import(/* webpackChunkName: "TB-Catalog" */ "../pages/Catalog"),
    },
    {
        path: "/c/:slug",
        name: "CatalogCategory",
        // name: "CatalogSection_Category",
        component: () =>
            import(/* webpackChunkName: "TB-CatalogSection" */ "../pages/CatalogCategory"),
        props: (route) => ({
            //nameSection_Category: route.params.nameSection_Category,
            slug: route.params.slug,
        }),
    },
    {
        path: "/p/:slug",
        name: "Product",
        component: () => import(/* webpackChunkName: "TB-Product" */ "../pages/Product"),
        props: (route) => ({
            slug: route.params.slug,
        }),
    },
    {
        path: "/search",
        name: "Search",
        component: () => import(/* webpackChunkName: "TB-Search" */ "../pages/Search"),
        props: (route) => ({ term: route.query.term }),
    },
    {
        path: "/order",
        name: "Order",
        component: () => import(/* webpackChunkName: "TB-Order" */ "@/TheCheckout/index"),
        children: [
            {
                name: "TheCheckoutStepOne",
                path: "",
                component: TheCheckoutStepOne,
                props: {
                    routerStep: 0,
                },
            },
            {
                name: "TheCheckoutStepTwo",
                path: "2",
                component: TheCheckoutStepTwo,
                props: {
                    routerStep: 1,
                },
            },
            {
                name: "TheCheckoutStepThree",
                path: "3",
                component: TheCheckoutStepThree,
                props: {
                    routerStep: 2,
                },
            },
            {
                name: "TheCheckoutStepFour",
                path: "4",
                component: TheCheckoutStepFour,
                props: {
                    routerStep: 3,
                },
            },
        ],
    },
    {
        path: "/p-garantiya",
        name: "Guarantee",
        component: () => import(/* webpackChunkName: "TB-Guarantee" */ "../pages/Guarantee"),
    },
    {
        path: "/p-dostavka-i-oplata",
        name: "Payment",
        component: () => import(/* webpackChunkName: "TB-Payment" */ "../pages/Payment"),
    },
    {
        path: "/p-o-magazine",
        name: "Store",
        component: () => import(/* webpackChunkName: "TB-Store" */ "../pages/Store"),
    },
    {
        path: "/blog",
        name: "Blog",
        component: () => import(/* webpackChunkName: "TB-Blog" */ "../pages/Blog"),
    },
    {
        path: "/blog/:slug",
        name: "BlogDetail",
        component: () => import(/* webpackChunkName: "TB-BlogDetail" */ "../pages/BlogDetail"),
        props: (route) => ({
            slug: route.params.slug,
        }),
    },
    {
        path: "/personal",
        name: "Personal",
        component: () => import(/* webpackChunkName: "TB-Personal" */ "@/ThePersonal/index"),
        children: [
            {
                name: "ThePersonalMain",
                path: "/personal",
                component: () =>
                    import(
                        /* webpackChunkName: "TB-ThePersonalMain" */ "@/ThePersonal/routes/ThePersonalMain"
                    ),
            },
            {
                name: "ThePersonalDiscounts",
                path: "/personal/discounts",
                component: () =>
                    import(
                        /* webpackChunkName: "TB-ThePersonalDiscounts" */ "@/ThePersonal/routes/ThePersonalDiscounts"
                    ),
            },
            {
                name: "ThePersonalData",
                path: "/personal/data",
                component: () =>
                    import(
                        /* webpackChunkName: "TB-ThePersonalData" */ "@/ThePersonal/routes/ThePersonalData"
                    ),
            },
            {
                name: "ThePersonalOrders",
                path: "/personal/orders",
                component: () =>
                    import(
                        /* webpackChunkName: "TB-ThePersonalOrders" */ "@/ThePersonal/routes/ThePersonalOrders"
                    ),
            },
            {
                name: "ThePersonalAddresses",
                path: "/personal/addresses",
                component: () =>
                    import(
                        /* webpackChunkName: "TB-ThePersonalAddresses" */ "@/ThePersonal/routes/ThePersonalAddresses"
                    ),
            },
            {
                name: "ThePersonalBonuses",
                path: "/personal/bonuses",
                component: () =>
                    import(
                        /* webpackChunkName: "TB-ThePersonalBonuses" */ "@/ThePersonal/routes/ThePersonalBonuses"
                    ),
            },
            {
                name: "ThePersonalSupport",
                path: "/personal/support",
                component: () =>
                    import(
                        /* webpackChunkName: "TB-ThePersonalSupport" */ "@/ThePersonal/routes/ThePersonalSupport"
                    ),
            },
        ],
    },
    {
        path: "/404",
        name: "ErrorPage",
        component: () => import(/* webpackChunkName: "TB-404" */ "../pages/404"),
    },
    {
        path: "*",
        redirect: "/404",
    },
];
