function state() {
    return {
        searchItems: [],
        loading: false,
    };
}

const mutations = {
    SET_SEARCH(state, { items }) {
        state.searchItems = items;
    },
    START_LOADING(state) {
        state.loading = true;
    },
    END_LOADING(state) {
        state.loading = false;
    },
    CLEAR_CATALOG(state) {
        while (state.searchItems.length > 0) state.searchItems.pop();
    },
};

const actions = {
    async getSearch({ commit }, { term }) {
        commit("START_LOADING");
        return this.tbApi.getSearch({ term }).then((response) => {
            commit("SET_SEARCH", response);
            commit("END_LOADING");
        });
    },
    clearCatalog({ commit }) {
        commit("CLEAR_CATALOG");
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
