<script>
    import PaymentIcons from "@/components/PaymentIcons";

    export default {
        name: "TheFooterBottom",
        components: {
            PaymentIcons,
        },
        computed: {
            getCurrentYear() {
                return new Date().getFullYear();
            },
        },
    };
</script>

<template>
    <div class="the-footer-bottom">
        <div class="l-container">
            <div class="the-footer-bottom__inner">
                <div class="the-footer-bottom__copy">
                    Все права защищены. {{ getCurrentYear }} © Travel-blue.ru
                </div>
                <div class="the-footer-bottom__pay">
                    <div class="the-footer-bottom__pay-title">Принимаем к оплате</div>
                    <div class="the-footer-bottom__pay-list">
                        <payment-icons></payment-icons>
                    </div>
                </div>
                <div class="the-footer-bottom__links">
                    <a
                        :href="$root.$_documents.companyPolicy"
                        target="_blank"
                        class="the-footer-bottom__link"
                    >
                        Конфиденциальность
                    </a>
                    <a
                        :href="$root.$_documents.personalData"
                        target="_blank"
                        class="the-footer-bottom__link"
                    >
                        Персональные данные
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .the-footer-bottom {
        background-color: #fff;
    }

    .the-footer-bottom__inner {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 4px 0 65px;

        @include bp($bp-desktop-sm) {
            flex-direction: row;
            padding: 24px 0;
        }
    }

    .the-footer-bottom__pay {
        margin-bottom: 16px;
        @include bp($bp-desktop-sm) {
            position: relative;
            margin-bottom: 0;
            padding: 4px 12px;
        }
    }

    .the-footer-bottom__pay-title {
        margin-bottom: 8px;
        font-size: 11px;
        line-height: 18px;
        text-align: center;
        @include bp($bp-desktop-sm) {
            position: absolute;
            left: 50%;
            bottom: 100%;
            margin-bottom: 0;
            transform: translateX(-50%);
        }
    }

    .the-footer-bottom__pay-list {
        margin: 0 -16px;

        @include bp($bp-tablet-sm) {
            max-width: 100%;
            margin: 0;
        }
    }

    .the-footer-bottom__links {
        display: flex;
        margin: 0 -8px;
        @include bp($bp-desktop-sm) {
            display: block;
            margin: 0;
        }
    }

    .the-footer-bottom__link {
        display: block;
        margin: 0 8px;
        color: var(--primary-text);
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;

        @include bp($bp-desktop-sm) {
            display: inline-block;
            margin: 0 0 0 20px;
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .the-footer-bottom__copy {
        display: none;
        font-size: 15px;
        line-height: 18px;
        @include bp($bp-desktop-sm) {
            display: block;
        }
    }
</style>
