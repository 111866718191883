<script>
    import BaseButton from "@main/packages/BaseButton";
    import HeaderCartProduct from "@/components/HeaderCartProduct";

    export default {
        name: "HeaderCartDropdown",
        components: {
            BaseButton,
            HeaderCartProduct,
        },
        props: {
            cart: Object,
        },
        methods: {
            removeProduct(productId) {
                this.$emit("remove-product", productId);
            },
            goToOrder() {
                this.$router.push({ name: "Order" });
            },
        },
    };
</script>

<template>
    <div class="c-cart-dropdown">
        <div class="c-cart-dropdown__wrapper">
            <div class="c-cart-dropdown__body">
                <header-cart-product
                    :product="product"
                    :index="i"
                    v-for="(product, i) in cart.productsList"
                    :key="product.id"
                    @remove-product="removeProduct"
                ></header-cart-product>
            </div>

            <div class="c-cart-dropdown__footer">
                <div class="c-cart-dropdown__total">
                    <div class="c-cart-dropdown__total-text">Товаров на сумму:</div>
                    <div class="c-cart-dropdown__total-number">
                        {{ cart.totalPrice | formatPrice }}₽
                    </div>
                </div>
                <base-button
                    variant="v1"
                    size="md"
                    @click="goToOrder"
                    class="c-cart-dropdown__button"
                >
                    Оформить заказ
                </base-button>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .c-cart-dropdown {
        position: absolute;
        top: 50px;
        right: 0;
        width: 377px;
        background: var(--base-white);
        border-radius: 20px;
        box-sizing: border-box;
        opacity: 0;
        visibility: hidden;
        transform: translateY(-4px);
        box-shadow: 0px 5px 15px rgba(95, 113, 141, 0.15);
        transition: 0.2s ease-in-out all;
        z-index: 10;
    }

    .c-cart-dropdown__wrapper {
        position: relative;

        &:after,
        &:before {
            content: "";
            position: absolute;
            width: 16px;
            height: calc(100% - 40px);
            background-color: #fff;
            top: 20px;
            z-index: 1;
        }

        &:after {
            left: 0;
        }

        &:before {
            right: 0;
        }
    }

    .c-cart-dropdown__body {
        max-height: 316px;
        overflow: auto;
        padding-top: 4px;
    }

    .c-cart-dropdown__footer {
        padding: 0 16px 16px;
    }

    .c-cart-dropdown__total {
        padding: 12px 20px;
        display: flex;
        justify-content: space-between;
        margin: 8px 0;
        background-color: var(--neutral--light);
    }

    .c-cart-dropdown__total-text {
        font-size: 18px;
        line-height: 24px;
        color: var(--secondary-text);
    }

    .c-cart-dropdown__total-number {
        font-size: 20px;
        line-height: 24px;
        font-weight: 600;
        color: var(--primary-text);
    }

    .c-cart-dropdown__button {
        width: 100%;
    }
</style>
