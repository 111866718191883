<script>
    import { apiUrl } from "@/utils";
    import TheSvgIcon from "@main/packages/TheSvgIcon";

    export default {
        name: "TheTopBanner",
        components: {
            TheSvgIcon,
        },
        props: {
            banner: Object,
            city: String,
        },
        data() {
            return {
                stretchings: [],
                isVisible: true,
                mobileHeader: null,
            };
        },
        watch: {
            city() {
                this.getStretchingsByCity();
            },
            image(val) {
                if (val) {
                    if (
                        window.pageYOffset <= 0 &&
                        this.mobileHeader.classList.contains("fix-scroll-block")
                    ) {
                        this.mobileHeader.style.top = 49 + "px";
                        this.$emit("on-change-visibility", Boolean(val));
                    }
                }
            },
        },
        computed: {
            image() {
                return this.banner ? this.banner : this.stretchings[0];
            },
            computeCloseClass() {
                return this.hexToHSL(this.image.backgroundColor).l >= 50
                    ? "is-dark"
                    : "is-light";
            },
        },
        mounted() {
            if (!this.stretchings.length) {
                this.getStretchingsByCity();
            }
            this.mobileHeader = document.querySelector(".js-mobile-header");
        },
        methods: {
            apiUrl,
            getStretchingsByCity() {
                if (!this.banner) {
                    const city = this.city.split(",").slice(-1)[0]?.trim();
                    this.$store.optionalApi.getStretchingsByCity(city).then((response) => {
                        this.stretchings = response;
                        this.$emit("on-change-visibility", response.length > 0);
                    });
                }
            },
            closeBannerHandler() {
                this.isVisible = false;
                this.$emit("on-change-visibility", false);
            },
            hexToHSL(H) {
                let r = 0,
                    g = 0,
                    b = 0;
                if (H.length === 4) {
                    r = "0x" + H[1] + H[1];
                    g = "0x" + H[2] + H[2];
                    b = "0x" + H[3] + H[3];
                } else if (H.length === 7) {
                    r = "0x" + H[1] + H[2];
                    g = "0x" + H[3] + H[4];
                    b = "0x" + H[5] + H[6];
                }
                // Then to HSL
                r /= 255;
                g /= 255;
                b /= 255;
                let cmin = Math.min(r, g, b),
                    cmax = Math.max(r, g, b),
                    delta = cmax - cmin,
                    h = 0,
                    s = 0,
                    l = 0;

                if (delta === 0) h = 0;
                else if (cmax === r) h = ((g - b) / delta) % 6;
                else if (cmax === g) h = (b - r) / delta + 2;
                else h = (r - g) / delta + 4;

                h = Math.round(h * 60);

                if (h < 0) h += 360;

                l = (cmax + cmin) / 2;
                s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
                s = +(s * 100).toFixed(1);
                l = +(l * 100).toFixed(1);

                return { h, s, l };
            },
        },
    };
</script>

<template>
    <div class="top-banner js-top-banner" v-if="image && isVisible">
        <a
            :href="image.link"
            class="top-banner__link"
            target="_blank"
            :style="{
                backgroundColor: image.backgroundColor,
                backgroundImage: `url(${apiUrl(image.picture)})`,
            }"
        >
        </a>
        <button
            class="top-banner__close"
            :class="computeCloseClass"
            @click="closeBannerHandler"
        >
            <the-svg-icon name="close" :size-w="16"></the-svg-icon>
        </button>
    </div>
</template>

<style lang="scss">
    .top-banner {
        position: relative;
        z-index: 32;
        &:hover {
            .top-banner__close {
                opacity: 1;
            }
        }
    }

    .top-banner__link {
        display: block;
        width: 100%;
        padding-bottom: 49px;
        background: center center no-repeat;
        background-size: contain;

        @include bp($bp-tablet-sm) {
            height: 56px;
            padding-bottom: 0;
        }
    }

    .top-banner__close {
        position: absolute;
        top: 50%;
        right: 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        transform: translateY(-50%);
        transition: opacity 0.2s ease-in;
        opacity: 0.5;
        &.is-dark {
            filter: grayscale(1) brightness(0);
        }
        &.is-light {
            filter: brightness(0) invert(100%);
        }
    }
</style>
