export default {
    bind: function (el, binding, vnode) {
        el.event = function (event) {
            if (!(el === event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener("click", el.event);
        document.body.addEventListener("touchstart", el.event);
    },
    unbind: function (el) {
        document.body.removeEventListener("click", el.event);
        document.body.removeEventListener("touchstart", el.event);
    },
};
// export default {
//   bind: function (el, binding, vnode) {
//     el.clickOutsideEvent = function (event) {
//       // here I check that click was outside the el and his childrens
//       if (!el.contains(event.target)) {
//         // and if it did, call method provided in attribute value
//         vnode.context[binding.expression](event)
//       }
//     }
//     document.body.addEventListener('mousedown', el.clickOutsideEvent)
//   },
//   unbind: function (el) {
//     document.body.removeEventListener('mousedown', el.clickOutsideEvent)
//   },
// }
