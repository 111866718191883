import { mapCart } from "../mapping/root";

export default function ($api) {
    return {
        /**
         * КОРЗИНА
         */

        /**
         * Получить инфу о корзине
         *
         * @return { Object }
         */
        getCart() {
            return $api.get("api/basket/get/").then(mapCart);
        },
        /**
         * Добавить в корзину
         *
         * @param  { Number } quantity количество товара
         * @param  { Number } productId id товара или массив с id товаров
         * @return { Object }
         */
        addCart({ quantity, productId }) {
            return $api
                .post("api/basket/add/", { quantity, product_id: productId })
                .then(mapCart);
        },

        /**
         * Изменение количество товара в корзине
         *
         * @param  { Number } quantity количество товара
         * @param  { Number } productId id товара или массив с id товаров
         * @return { Object }
         */
        changeQuantityCart({ quantity, productId }) {
            return $api
                .post("api/basket/modify/", { quantity, product_id: productId })
                .then(mapCart);
        },
    };
}
