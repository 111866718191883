import { mapArray, mapObject, mapPage } from "../../core/mapping";
function mapBaseProduct() {
    return {
        id: "id",
        name: "name",
        price: "price",
        discountPrice: "discountPrice",
        discountPercent: "discountPercent",
        previewImage: ["previewImage", (preview) => mapImage(preview)],
    };
}
function mapBaseProductList() {
    return {
        ...mapBaseProduct(),
        shortName: "shortName",
        slug: "slug",
        offers: ["offers", (offer) => mapOffers(offer)],
        text: "text",
        inCart: "inCart",
        features: "features",
        canCompared: ["typeId", (countGroup) => countGroup > 0],
        showGiftIcon: "showGiftIcon",
        mainCategory: "mainCategory",
        security: "security",
        eol: "eol",
    };
}
export function mapCatalog(banners) {
    return mapPage(banners, {
        ...mapBaseProductList(),
        available: "available",
        additionalPreviewImage: ["additionalPreviewImage", (preview) => mapImage(preview)],
        listSpecifications: "listSpecifications",
        displayOnMain: "displayOnMain",
    });
}

export function mapCatalogMap(banners) {
    return mapPage(banners, {
        slug: "slug",
    });
}

function mapImage(data) {
    return mapObject(data, {
        original: "original",
        medium: "medium",
        small: "small",
    });
}

export function mapOffers(data) {
    return mapArray(data, {
        ...mapBaseProduct(),
        available: "available",
        quantity: "quantity",
        color: "color",
        size: "size",
        productId: "productId",
        vendorCode: "vendorCode",
        displayOnMain: "displayOnMain",
    });
}

export function mapProduct(data) {
    return mapObject(data, {
        ...mapBaseProductList(),
        categoriesIds: "categoriesIds",
        allSpecifications: "allSpecifications",
        similarProductsIds: "similarProductsIds",
        relatedProductsIds: "relatedProductsIds",
        gift: ["gift", (gift) => mapGift(gift)],
        sizesList: "productLinks",
        metaTitle: "title",
        metaKeywords: "keywords",
        metaDescription: "description",
        equipment: "equipment",
    });
}

export function mapGallery(gallery) {
    return mapPage(gallery, {
        id: "id",
        small: "small",
        medium: "medium",
        original: "original",
    });
}

export function mapDelivery({ deliveryItems }) {
    return mapArray(deliveryItems, {
        id: "id",
        name: "name",
        company: "company",
        price: "price",
        store: "store",
        type: "type",
        paySystems: "paySystems",
        deliveryId: "deliveryId",
        maxDate: "maxDate",
        minDate: "minDate",
        maxDays: "maxDays",
        minDays: "minDays",
    });
}

export function mapReviews({ items }) {
    return mapArray(items, {
        id: "id",
        onlyOnMonoSites: "onlyOnMonoSites",
        date: "date",
        author: "author",
        rating: "rating",
        source: "source",
        advantages: "advantages",
        disadvantages: "disadvantages",
        comment: "comment",
    });
}

export function mapStoresAmount(stores) {
    return mapPage(stores, {
        amount: "amount",
        id: ["store", (store) => store.id],
        name: ["store", (store) => store.name],
        city: ["store", (store) => store.city],
        route: ["store", (store) => store.route],
        active: ["store", (store) => store.active],
        html: ["store", (store) => store.description],
        sort: ["store", (store) => store.sort],
        // productId: "productId",
    });
}

function mapGift(gift) {
    return mapObject(gift, {
        name: "name",
        productId: "productId",
        slug: "slug",
    });
}

export function mapVideoProduct(stores) {
    return mapPage(stores, {
        id: "id",
        name: "name",
        service: "service",
        videoId: "externalVideoId",
        videoIdForFB: "embedVideoCode",
        showInSlider: "showInSlider",
    });
}

export function mapPartnerLinks(items) {
    return mapArray(items, {
        id: "id",
        link: "link",
        name: "partner",
    });
}
