import { mapArray, mapObject } from "@/core/mapping";

export function mapSiteData(data) {
    return mapObject(data, {
        name: "name",
        title: "title",
        topMenu: "topMenu",
        bottomMenu: "bottomMenu",
        topBanner: "topBanner",
        code: "code",
        mainMenu: ["mainMenu", (menu) => mapMainMenu(menu)],
        freeDelivery: "freeDelivery",
        options: [
            "replaceableTemplates",
            (templates) => createReplaceableTemplates(templates),
        ],
        phone: "phone",
    });
}

export function mapCart({ data }) {
    return mapObject(data, {
        number: "NUM_PRODUCTS",
        total: "TOTAL_PRICE",
        totalPrice: "REAL_TOTAL_PRICE",
        error: "ERROR_MESSAGE",
        upSale: "UP_SALE",
        productsList: [
            "ITEMS",
            (product) => {
                if (!product) return false;
                let obj = {};
                Object.keys(product).forEach((innerProducts) => {
                    obj[innerProducts] = mapProductsCart(product[innerProducts]);
                });
                return obj;
            },
        ],
    });
}

export function mapStatusOrders(response) {
    return mapObject(response, {
        error: "error",
        errorMessage: "errorMessage",
        orders: ["orders", (orders) => mapStatusOrder(orders)],
    });
}

function mapStatusOrder(orders) {
    return mapArray(orders, {
        number: "orderNumber",
        progress: "progress",
        deliveryDate: "deliveryDate",
        deliveryCompany: ["deliveryMethod", (data) => data.company],
        trackingNumber: "trackingNumber",
        history: "history",
    });
}

function mapMainMenu(menu) {
    return mapArray(menu, {
        name: "name",
        hash: ["link", (link) => link.replace("/#", "")],
    });
}

function mapProductsCart(data) {
    return mapObject(data, {
        id: "PRODUCT_ID",
        url: "DETAIL_PAGE_URL",
        name: "NAME",
        shortName: "SHORT_NAME",
        parent: "PARENT",
        price: "PRICE",
        realPrice: "REAL_PRICE",
        discountPrice: "PRICE_WITHOUT_DISCOUNT",
        quantity: "QUANTITY",
        maxQuantity: "MAX_QUANTITY",
        src: "SRC",
    });
}

function createReplaceableTemplates(data) {
    let createdTemplates = {};
    data.forEach((template) => {
        createdTemplates[template.code] = template.value;
    });
    return createdTemplates;
}
// export function mapQuestionsPage(page) {
//     return mapPage(page, {
//         ...mapBase(),
//         hasBestAnswer: "HasBestAnswer" // Вопрос имеет "лучший" ответ
//         // answerUsers: ["AnswerUsers", users => users.map(mapAuthor)]
//     });
// }
//
// export function mapPersonalAnswerQuestion(question) {
//     return mapObject(question, {
//         ...mapBase()
//     });
// }
//
// function mapBase() {
//     return {
//         id: "Id", // Идентификатор вопроса
//         summary: "Title", // Вопрос (суть вопроса коротко)
//         categoryId: "CategoryId", // Идентификатор категории вопроса
//         rating: "Rating", // Рейтинг вопроса (количество лайков)
//         complaintExist: "ComplaintExist", // Признак того, что на вопрос есть жалоба от текущего пользователя
//         hasExpertLike: "HasExpertLike",
//         viewsCount: "ViewCounter",
//         createdAt: ["CreateDate", createdAt => new Date(createdAt)],
//         updateDate: ["UpdateDate", updateDate => new Date(updateDate)],
//         commentsCount: "CommentsCount",
//         answersCount: "AnswersCount",
//         // questionType: 'QuestionType',
//         anonymously: "Anonymously",
//         // pay: 'Pay',
//         // waitDays: 'WaitDays',
//         images: "Images",
//         // waitDate: [ 'WaitDays', 'CreateDate', (days, create) => mapWaitDays({ days, create: new Date(create) }) ],
//         // author: ["Author", author => mapAuthor(author)],
//         revisionId: "RevisionId",
//         revisionNumber: "RevisionNumber",
//         deleted: "Deleted",
//         favorite: "Favorite",
//         rated: "Rated",
//         // isPaid: [ 'QuestionType', type => type === 'Pay' ],
//         comments: [() => []]
//         // timeIsOver: ['WaitDays', 'CreateDate', (days, create) => {
//         //   let obj = mapWaitDays({days, create: new Date(create)})
//         //   return isNegativeZero(obj.day) || obj.day < 0 && isNegativeZero(obj.hour) || obj.hour < 0
//         // }],
//     };
// }

// function isNegativeZero (x) {
//   return x === 0 && (1 / x) === -Infinity
// }
