<script>
    import BaseButton from "@main/packages/BaseButton";

    export default {
        name: "FreeDeliveryModal",
        components: { BaseButton },
        props: {
            resultToFreeDelivery: Number,
            buttonVariants: {
                type: Array,
                default: () => ["v2", "v3"],
            },
        },
        data() {
            return {
                freeDeliveryTimer: null,
            };
        },
        computed: {
            isFreeDelivery() {
                return this.resultToFreeDelivery >= 0;
            },
        },
        watch: {
            resultToFreeDelivery: {
                handler() {
                    clearTimeout(this.freeDeliveryTimer);
                    this.freeDeliveryTimer = setTimeout(() => {
                        this.hide();
                    }, 7500);
                },
            },
            $route() {
                this.hide();
            },
        },
        methods: {
            hide() {
                clearTimeout(this.freeDeliveryTimer);
                this.$emit("hide-modal");
            },
            goTo() {
                this.$router.push({ name: "Order" });
            },
        },
    };
</script>

<template>
    <div class="free-delivery">
        <div class="free-delivery__title">Товар добавлен</div>
        <div class="free-delivery__info">
            <div
                class="free-delivery__wrapper"
                :class="{ 'free-delivery__wrapper--center': !isFreeDelivery }"
            >
                <div v-if="isFreeDelivery" class="free-delivery__text">
                    До бесплатной доставки осталось:
                </div>
                <div v-if="isFreeDelivery" class="free-delivery__price">
                    {{ resultToFreeDelivery | formatPrice }}₽
                </div>
                <div v-if="!isFreeDelivery" class="free-delivery__text">
                    Мы доставим ваш заказ бесплатно!
                </div>
            </div>
        </div>
        <div class="free-delivery__box">
            <img
                v-show="!isFreeDelivery"
                class="free-delivery__success"
                :src="`/static/${$root.$_name}/checkout/free_box_success.png`"
                alt=""
            />
            <img :src="`/static/${$root.$_name}/checkout/free_box.jpg`" alt="" />
        </div>
        <div class="free-delivery__control">
            <base-button
                :variant="buttonVariants[0]"
                size="md"
                @click="goTo"
                class="free-delivery__btn"
            >
                Оформить
            </base-button>
            <base-button
                :variant="buttonVariants[1]"
                size="md"
                @click="hide"
                class="free-delivery__btn"
            >
                Смотреть еще
            </base-button>
        </div>
    </div>
</template>

<style lang="scss">
    .free-delivery {
        position: fixed;
        z-index: 2000;
        left: 50%;
        bottom: 30px;
        width: 304px;
        padding: 16px 20px;
        text-align: center;
        background: var(--base-white);
        border: 1px solid var(--modal-border-color);
        border-radius: var(--modal-radius);
        box-shadow: 0 5px 15px rgba(95, 113, 141, 0.15);
        transform: translateX(-50%);

        @include bp($bp-tablet-sm) {
            top: 110px;
            bottom: auto;
            width: var(--modal-width);
            padding: 40px;
        }
    }

    .free-delivery__title {
        margin-bottom: 8px;
        color: var(--primary-text);
        font-weight: var(--modal-heding-weight);
        font-size: var(--modal-heading-size);
        line-height: 24px;
    }

    .free-delivery__wrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;

        @include bp($bp-desktop-sm) {
            flex-direction: row;
            justify-content: center;
        }
    }

    .free-delivery__text {
        color: var(--modal-subheding-color);
        font-size: 14px;
        line-height: 24px;

        @include bp($bp-desktop-sm) {
            font-size: 16px;
        }
    }

    .free-delivery__box {
        position: relative;
        display: none;

        @include bp($bp-tablet-sm) {
            display: block;
        }

        img {
            margin: auto;
        }
    }

    .free-delivery__success {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .free-delivery__price {
        margin-left: 10px;
        color: var(--primary-text);
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
    }

    .free-delivery__control {
        display: flex;
        justify-content: center;
        margin: 32px -8px 0;
    }

    .free-delivery__btn {
        width: 50%;
        margin: 0 4px;
    }
</style>
