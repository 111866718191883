<script>
    import { isMobile, stickyScroll } from "@/utils";
    import HeaderBurger from "@/components/HeaderBurger";
    import ClickOutside from "@tb/directives/click-outside";
    import HeaderCart from "@tb/components/HeaderCart";
    import TheHeaderSearch from "@tb/components/TheHeaderSearch";
    import HeaderMenu from "@tb/components/HeaderMenu";

    export default {
        name: "TheHeaderMain",
        components: {
            HeaderCart,
            TheHeaderSearch,
            HeaderMenu,
            HeaderBurger,
        },
        props: {
            cart: Object,
            freeDeliveryCount: Number,
            phoneCallCenter: String,
            isOrderPage: Boolean,
        },
        data() {
            return {
                stateCatalog: false,
            };
        },
        mounted() {
            let el = this.$refs.scrollHeader;
            let elWrap = this.$refs.scrollHeaderWrap;

            window.addEventListener("keyup", this.onEscapeKeyUp);

            window.addEventListener("scroll", () => {
                stickyScroll({
                    el,
                    elWrap,
                    classNameDivider: "js-footer-stop-divider",
                    calcWidth: false,
                });
            });
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.stickyScroll);
            window.removeEventListener("keyup", this.onEscapeKeyUp);
        },
        directives: {
            ClickOutside,
        },
        methods: {
            toggleDesktopCatalog() {
                this.stateCatalog = !this.stateCatalog;
            },
            closeDesktopCatalog() {
                if (!isMobile()) {
                    this.stateCatalog = false;
                }
            },
            onEscapeKeyUp({ key }) {
                if (key === "Escape") {
                    this.stateCatalog = false;
                }
            },
        },
    };
</script>

<template>
    <div class="the-header-main" ref="scrollHeader">
        <div
            class="the-header-main__wrapper js-desktop-header"
            ref="scrollHeaderWrap"
            data-fix-on-scroll
        >
            <div class="l-container">
                <div class="the-header-main__scroll">
                    <div class="the-header-main__logo-wrapper">
                        <router-link
                            :to="{ name: 'Home' }"
                            class="the-header-main__logo"
                        ></router-link>
                    </div>

                    <button
                        class="the-header-main__catalog outline"
                        v-click-outside="closeDesktopCatalog"
                        @click="toggleDesktopCatalog"
                    >
                        <span class="the-header-main__catalog-line">
                            <header-burger :state="stateCatalog"></header-burger>
                        </span>
                        <span class="the-header-main__catalog-text">Каталог</span>
                    </button>

                    <div class="the-header-main__search">
                        <the-header-search></the-header-search>
                    </div>

                    <header-cart
                        :cart="cart"
                        :free-delivery-count="freeDeliveryCount"
                        :is-order-page="isOrderPage"
                    ></header-cart>
                </div>
            </div>
            <transition name="fade">
                <header-menu v-show="stateCatalog"></header-menu>
            </transition>
        </div>
    </div>
</template>

<style lang="scss">
    .the-header-main {
        display: none;
        @include bp($bp-desktop-sm) {
            position: relative;
            z-index: 20;
            display: block;
        }
    }

    .the-header-main__wrapper {
        @include bp($bp-desktop-sm) {
            width: 100%;
            left: 0;
            background-color: var(--base-white);
            z-index: z("header");
            padding: 24px 0;
            &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: var(--primary-border--light);
                z-index: 0;
                opacity: 1;
            }
        }
    }

    .the-header-main__scroll {
        @include bp($bp-desktop-sm) {
            position: relative;
            z-index: 30;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background-color: #fff;
        }
    }

    .the-header-main__logo-wrapper {
        @include bp($bp-desktop-sm) {
            position: relative;
            z-index: 9;
        }
    }

    .the-header-main__logo {
        @include bp($bp-desktop-sm) {
            display: block;
            margin: auto;
            width: 194px;
            height: 40px;
            background-image: url("~@tb/assets/icons/logo.svg");
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    .the-header-main__catalog {
        @include bp($bp-desktop-sm) {
            display: flex;
            align-items: center;
            padding: 12px;
            background-color: var(--secondary);
            border-radius: 3px;
            font-size: 15px;
            line-height: 24px;
            color: var(--primary-text);
            margin-left: 30px;
            cursor: pointer;
            &:hover {
                background-color: var(--secondary);
            }
        }
    }

    .the-header-main__catalog-text {
        @include bp($bp-desktop-sm) {
            margin-left: 8px;
        }
    }

    .the-header-main__search {
        @include bp($bp-desktop-sm) {
            flex-grow: 1;
        }
    }
</style>
