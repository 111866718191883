import * as types from "./mutation_types";

function state() {
    return {
        user: {},
        totalOrders: null,
        totalSales: 0,
        totalBonuses: 0,
    };
}

const mutations = {
    [types.SET_USER](state, user) {
        state.user = user;
    },
    [types.SET_USER_PHOTO](state, photo) {
        state.user.photo = photo;
    },
    [types.SET_TOTAL_ORDERS](state, count) {
        state.totalOrders = count;
    },
    [types.SET_TOTAL_SALES](state, count) {
        state.totalSales = count;
    },
    [types.SET_TOTAL_BONUSES](state, count) {
        state.totalBonuses = count;
    },
};

const actions = {
    loginProfile({ dispatch }, data) {
        return this.profileApi
            .loginProfile(data)
            .then((response) => {
                if (!response.error) {
                    dispatch("getProfileUser");
                }
                return response;
            })
            .catch(() => {
                return { error: 1 };
            });
    },
    logoutProfile({ commit }) {
        return this.profileApi.logoutProfile().then(() => {
            commit(types.SET_USER, {});
        });
    },
    registerProfile(_, data) {
        return this.profileApi.registerProfile(data).then((response) => {
            return response;
        });
    },
    getProfileUser({ commit }) {
        return this.profileApi.getProfileUser().then((user) => {
            if (user) {
                commit(types.SET_USER, user);
                return user;
            }
        });
    },
    updateProfileUser({ dispatch }, user) {
        return this.profileApi.updateProfileUser(user).then((response) => {
            if (response.success) {
                dispatch("getProfileUser");
            } else {
                return response.errors;
            }
        });
    },
    updateProfileUserPhoto(_, photo) {
        return this.profileApi.updateProfileUserPhoto(photo);
    },
    getProfileTotalOrders({ commit }) {
        return this.profileApi.getProfileOrders(1, "active").then(({ totalOrders }) => {
            commit(types.SET_TOTAL_ORDERS, totalOrders);
        });
    },
    getProfileTotalBonuses({ commit }) {
        return this.profileApi.getProfileLoyaltyAccount().then(({ balance }) => {
            commit(types.SET_TOTAL_BONUSES, balance);
        });
    },
    getProfileTotalSales({ commit }) {
        return this.profileApi.getProfilePromoSales().then((response) => {
            commit(types.SET_TOTAL_SALES, response.length);
        });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
