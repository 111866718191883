/**
 * Проверка на мобильные устройства
 *
 * @param { number } width - ширина экрана
 * @returns { boolean }
 */
const isMobile = (width = 1025) => {
    return (
        (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        ) &&
            window.matchMedia(`(max-width: ${width}px)`).matches) ||
        window.matchMedia(`(max-width: ${width}px)`).matches
    );
};
// TODO: прописать логику на установку мобильной версии

/**
 * Проверка на объект
 *
 * @param { object } obj - Проверяемый объект
 * @returns { boolean }
 */
const isObject = (obj) => {
    return Object.prototype.toString.call(obj) === "[object Object]";
};

const isEmpty = (mixed_var) => {
    return (
        mixed_var === "" ||
        mixed_var === 0 ||
        mixed_var === "0" ||
        mixed_var === null ||
        mixed_var === false
    );
};

export { isMobile, isObject, isEmpty };
