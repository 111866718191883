import Vue from "vue";
import { sync } from "vuex-router-sync";
import Service from "@/api/service";

import { initVueVariables } from "@/core/global";
import { createRouter } from "@/router";
import { createStore } from "@/store";
import { createApi } from "@/api";

import { metaInfo } from "@/core/MetaInfo";
import { sendAnalytics } from "@/core/SendAnalytics";

/**
 * Создания шаблона приложения
 *
 * @param { Object } config - конфигурация бренда(ymId, idGtm, color, ...)
 * @param { Array } routerList - список маршрутов
 * @param { Object } storeList - список хранилищ
 * @param { Object } metaList - список мета-данных для страниц
 * @param { Object } context - контекст приложения
 * @param { Object } renderApp - копмонент входной точки приложения
 * @returns {Object}
 */
export const createTemplateApp = ({
    config,
    routerList,
    storeList,
    metaList,
    context,
    renderApp,
}) => {
    initVueVariables(config);
    // create router
    const router = createRouter(routerList);
    // create store
    const store = createStore(storeList);
    // create api
    const api = createApi(Vue.prototype.$_url);

    metaInfo.setBrandName(Vue.prototype.$_nameOfficial);
    metaInfo.setMetaData(metaList);

    Service.install({ store, api });

    sendAnalytics.updateMetricId(Vue.prototype.$_ymId);

    sync(store, router);

    const app = new Vue({
        data: { url: context ? context.url : "" },
        router,
        store,
        render: (h) => h(renderApp),
    });

    if (process.env.VUE_APP_ENV === "client") {
        router.afterEach((to, from) => {
            if (from.name) {
                Vue.nextTick(() => {
                    let timeout = 100;
                    if (to.name === "Product") timeout = 1200;

                    setTimeout(() => {
                        sendAnalytics.send(sendAnalytics.events.pageview, {
                            toFullPath: to.fullPath,
                            fromFullPath: from.fullPath,
                        });
                    }, timeout);
                });
            }
        });

        router.onError((error) => {
            console.error("Failure Reason: ", error);
            console.warn(error.message);

            if (/Loading chunk.*failed./i.test(error.message)) {
                window.location.reload();
            }
        });

        // router.beforeEach((to, from, next) => {
        //     if (to.matched.some((record) => record.meta.requiresAuth)) {
        //         if (store.getters["Profile/isLoggedIn"]) {
        //             next();
        //             return;
        //         }
        //         next("/");
        //         // if (process.browser) {
        //         //     next("/");
        //         //     window.location.href = "/";
        //         // }
        //     } else {
        //         next();
        //     }
        // });
    }

    return { app, router, store };
};
