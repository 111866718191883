import Vue from "vue";

Vue.filter("formatCase", (value, titles) => declOfNum(value, titles));
Vue.filter("formatPrice", (value) => numberWithSpaces(value));

function declOfNum(number, titles) {
    const cases = [2, 0, 1, 1, 1, 2];
    return titles[
        number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]
    ];
}

function numberWithSpaces(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
