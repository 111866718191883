/**
 * Формирование списка для objectManager yandex map
 *
 * @param  { Object } pickupList
 * @return { Object }
 */
const createFeatures = ({
    lat,
    lng,
    count,
    company,
    id,
    address,
    service,
    date,
    time,
    price,
    iconImageHref,
    isMr,
}) => {
    return {
        type: "Feature",
        id: count,
        geometry: { type: "Point", coordinates: [lat, lng] },
        properties: {
            balloonAddress: address,
            balloonNameCompany: company,
            balloonId: id,
            balloonService: service,
            balloonDate: date,
            balloonTime: time,
            balloonPrice: price,
            balloonPriceText: price ? price + " ₽" : "Бесплатно",
            balloonIsMr: isMr,
        },
        options: {
            preset: "twirl#blueStretchyIcon",
            iconLayout: "default#imageWithContent",
            iconImageHref,
            iconImageSize: [42, 46],
            iconImageOffset: [-20, -40],
            company,
            idBalloon: id,
        },
    };
};
/**
 * Формирование списка для objectManager yandex map
 *
 * @param  { Object } pickupList
 * @return { Object }
 */
const setLabelsForCouriers = (companies) => {
    companies.reduce((res, obj) => (obj.price < res.price ? obj : res)).label = "cheap";
    companies.reduce((res, obj) => {
        let dayRes =
            res.maxDays === res.minDays ? res.maxDays : (res.maxDays + res.minDays) / 2;
        let dayObj =
            obj.maxDays === obj.minDays ? obj.maxDays : (obj.maxDays + obj.minDays) / 2;
        return dayObj < dayRes ? obj : res;
    }).label = "fast";
};

/**
 * Формирование списка для objectManager yandex map
 *
 * @param  { Object } pickupList
 * @return { Object }
 */
const getLabelName = (type) => {
    const filters = {
        fast: "Самая быстрая",
        cheap: "Самая дешёвая",
        optimal: "Оптимальная",
    };
    return filters[type];
};

export { createFeatures, getLabelName, setLabelsForCouriers };
