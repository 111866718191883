<script>
    export default {
        name: "TheSvgIcon",
        props: {
            // TODO: придумать список где все иконки для облегчения поиска и наглядного поиска
            name: {
                type: String,
                required: true,
            },
            sizeW: {
                type: Number,
            },
            sizeH: {
                type: Number,
            },
            isCommon: {
                type: Boolean,
            },
        },
        computed: {
            iconPath() {
                const transformedName = this.$root.$_name.replace("-", "");
                return this.isCommon
                    ? `/legacy/sprite.svg?v=1#${this.name}_common`
                    : `/legacy/sprite.svg?v=1#${this.name}_${transformedName}`;
            },
            /**
             * Высчитывает размер области иконки, если в пропсах укзана только size-w, то формат иконки квадрат
             *
             * @return { String }
             */
            calcSize() {
                return this.sizeW && !this.sizeH
                    ? `${this.sizeW}`
                    : `${this.sizeW}-${this.sizeH}`;
            },
            className() {
                return [
                    `svg-icon svg-icon--${this.name}`,
                    this.calcSize ? `svg-icon--${this.calcSize}` : "",
                ];
                // return `svg-icon svg-icon--${this.name} svg-icon--${this.calcSize}`;
            },
        },
    };
</script>

<template>
    <svg :class="className" xmlns="http://www.w3.org/2000/svg">
        <use :xlink:href="iconPath" xmlns:xlink="http://www.w3.org/1999/xlink" />
    </svg>
</template>

<style lang="scss">
    .svg-icon {
        fill: currentColor;
    }

    .svg-icon--madrobots {
        width: 253px;
        height: 28px;
    }

    .svg-icon--12 {
        @include box(12px);
    }

    .svg-icon--16 {
        @include box(16px);
    }

    .svg-icon--18 {
        @include box(18px);
    }

    .svg-icon--20 {
        @include box(20px);
    }

    .svg-icon--24 {
        @include box(24px);
    }

    .svg-icon--32 {
        @include box(32px);
    }

    .svg-icon--40 {
        @include box(40px);
    }

    .svg-icon--48 {
        @include box(48px);
    }

    .svg-icon--16-24 {
        width: 16px;
        height: 24px;
    }

    .svg-icon--48-24 {
        width: 48px;
        height: 24px;
    }

    .svg-icon--8-16 {
        width: 8px;
        height: 16px;
    }

    .svg-icon--490-530 {
        width: 294px;
        height: 318px;

        @include bp($bp-tablet-sm) {
            width: 490px;
            height: 530px;
        }
    }

    .svg-icon--76-86 {
        width: 76px;
        height: 86px;
    }
</style>
