const { createTemplate } = require("../../../src/settings/config");

const TRAVEL_BLUE = createTemplate({
    useDeskId: "155789_20940",
    themeColor: "#17479E",
    yandexVerification: "6c8103057e3706f3",
    ymId: 66376921,
    name: "travel-blue",
    idGTM: "5JSGZV9",
    documents: {
        personalData:
            "https://docs.google.com/document/d/e/2PACX-1vRZ9T8TG4VcBtL9nuNF6xo6Jf_1-eDopTXP4gp8wnMrnNSIVJe7DmXHqsZRC_OGqvolyUJ_hFIh1ZPH/pub",
        companyPolicy:
            "https://docs.google.com/document/d/e/2PACX-1vQSgzl4SZ6U_gYQo5otjflQ0awBHtI2UTpVi0pK1vJF5TL8CUGhrV-qiDrYA3MgFxIbL7-B3t4zs_TA/pub",
    },
    font: "<style>@font-face{font-family:'Montserrat';font-style:normal;font-weight:300;font-display:swap;src:local('Montserrat'),url('../fonts/300.woff2') format('woff2'),url('../fonts/300.woff') format('woff')}@font-face{font-family:'Montserrat';font-style:normal;font-weight:400;font-display:swap;src:local('Montserrat'),url('../fonts/400.woff2') format('woff2'),url('../fonts/400.woff') format('woff')}@font-face{font-family:'Montserrat';font-style:normal;font-weight:500;font-display:swap;src:local('Montserrat'),url('../fonts/500.woff2') format('woff2'),url('../fonts/500.woff') format('woff')}@font-face{font-family:'Montserrat';font-style:normal;font-weight:600;font-display:swap;src:local('Montserrat'),url('../fonts/600.woff2') format('woff2'),url('../fonts/600.woff') format('woff')}@font-face{font-family:'Montserrat';font-style:normal;font-weight:700;font-display:swap;src:local('Montserrat'),url('../fonts/700.woff2') format('woff2'),url('../fonts/700.woff') format('woff')}</style>",
});

module.exports = TRAVEL_BLUE;
