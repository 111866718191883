import * as types from "./mutation_types";

function state() {
    return {
        product: null,
        gallery: [],
        reviews: [],
    };
}

const mutations = {
    [types.RECEIVE_PRODUCT](state, data) {
        state.product = data;
    },
    [types.RECEIVE_GALLERY](state, { items }) {
        state.gallery = items;
    },
    [types.CLEAR_GALLERY](state) {
        state.gallery = [];
    },
    [types.CLEAN_PRODUCT](state) {
        state.product = null;
    },
    [types.SET_REVIEWS](state, reviews) {
        state.reviews = reviews;
    },
};

const actions = {
    getProduct({ commit }, { slug, isReset }) {
        commit(types.CLEAN_PRODUCT);
        commit(types.CLEAR_GALLERY);
        if (!isReset) {
            return this.productsApi.getProduct({ slug }).then((response) => {
                commit(types.RECEIVE_PRODUCT, response);
                return response;
            });
        }
    },
    getProductGallery({ commit }, { type = "productsIds", id }) {
        commit(types.CLEAR_GALLERY);
        return this.productsApi.getProductGallery({ type, id }).then((response) => {
            commit(types.RECEIVE_GALLERY, response);
        });
    },
    // eslint-disable-next-line no-unused-vars
    getDeliveryForProduct({ commit }, { city, offerId }) {
        return this.productsApi
            .getDeliveryForProduct({ city, offerId })
            .then((response) => response);
    },
    getProductReview({ commit }, { id }) {
        return this.productsApi.getProductReview({ id }).then((response) => {
            commit(types.SET_REVIEWS, response);
        });
    },
    // eslint-disable-next-line no-unused-vars
    getProductStoresAmount({ commit }, { offerId }) {
        return this.productsApi
            .getProductStoresAmount({ offerId })
            .then((response) => response);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
