// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@tb/assets/icons/logo.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".the-header-main{display:none}@media only screen and (min-width:992px){.the-header-main{position:relative;z-index:20;display:block}}@media only screen and (min-width:992px){.the-header-main__wrapper{width:100%;left:0;background-color:var(--1q0yiyi);z-index:30;padding:24px 0}.the-header-main__wrapper:after{content:\"\";position:absolute;bottom:0;left:0;width:100%;height:1px;background-color:var(--qgtf1y);z-index:0;opacity:1}}@media only screen and (min-width:992px){.the-header-main__scroll{position:relative;z-index:30;display:flex;align-items:center;justify-content:space-between;background-color:#fff}}@media only screen and (min-width:992px){.the-header-main__logo-wrapper{position:relative;z-index:9}}@media only screen and (min-width:992px){.the-header-main__logo{display:block;margin:auto;width:194px;height:40px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:contain;background-repeat:no-repeat}}@media only screen and (min-width:992px){.the-header-main__catalog{display:flex;align-items:center;padding:12px;border-radius:3px;font-size:15px;line-height:24px;color:var(--z2kad5);margin-left:30px;cursor:pointer}.the-header-main__catalog,.the-header-main__catalog:hover{background-color:var(--11mivz)}}@media only screen and (min-width:992px){.the-header-main__catalog-text{margin-left:8px}}@media only screen and (min-width:992px){.the-header-main__search{flex-grow:1}}", ""]);
// Exports
module.exports = exports;
