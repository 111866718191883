<script>
    import { mapState } from "vuex";
    import { isMobile, getVariable } from "@/utils";
    import SelectCityConfirmation from "@/components/SelectCityConfirmation";
    import TheHeaderTop from "@tb/components/TheHeaderTop";
    import TheHeaderMain from "@tb/components/TheHeaderMain";
    import TheHeaderMobile from "@tb/components/TheHeaderMobile";
    import TheHeaderSearch from "@tb/components/TheHeaderSearch";

    export default {
        name: "TheHeader",
        components: {
            SelectCityConfirmation,
            TheHeaderMain,
            TheHeaderTop,
            TheHeaderMobile,
            TheHeaderSearch,
        },
        props: {
            isOrderPage: Boolean,
            isTopBanner: Boolean,
        },
        data() {
            return {
                isActiveConfirmCityDialog: false,
                isVisibleConfirmCityDialog: true,
                isSearchScroll: false,
            };
        },
        computed: {
            ...mapState("Root", {
                topMenu: (state) => state.site.topMenu,
                freeDeliveryCount: (state) => state.site.freeDelivery,
                phoneCallCenter: (state) => state.site.options.phoneCallCenter,
                city: (state) => state.city,
                cart: (state) => state.cart,
            }),
        },
        mounted() {
            window.addEventListener("scroll", () => {
                if (window.matchMedia("(min-width: 992px)").matches) {
                    this.isVisibleConfirmCityDialog = window.scrollY <= 0;
                }
            });

            if (isMobile()) {
                let vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty("--vh", `${vh}px`);
            }

            if (!getVariable("city_selected")) {
                setTimeout(() => {
                    this.isActiveConfirmCityDialog = true;
                }, 200);
            }
        },
        methods: {
            changeSearchStateHandler(state) {
                this.isSearchScroll = state;
            },
        },
    };
</script>

<template>
    <header class="the-header">
        <select-city-confirmation
            v-show="isActiveConfirmCityDialog && isVisibleConfirmCityDialog"
            :city="city"
            variant-submit-button="v1"
            variant-cancel-button="v3"
            @on-close="isActiveConfirmCityDialog = false"
        ></select-city-confirmation>

        <the-header-top
            :top-menu="topMenu"
            :phone-call-center="phoneCallCenter"
            :city="city"
        ></the-header-top>

        <the-header-main
            :free-delivery-count="freeDeliveryCount"
            :phone-call-center="phoneCallCenter"
            :cart="cart"
            :is-order-page="isOrderPage"
        ></the-header-main>

        <the-header-mobile
            :top-menu="topMenu"
            :city="city"
            :cart="cart"
            :free-delivery-count="freeDeliveryCount"
            :is-order-page="isOrderPage"
            :phone-call-center="phoneCallCenter"
            :is-top-banner="isTopBanner"
            @on-change-search-state="changeSearchStateHandler"
        ></the-header-mobile>

        <div
            class="the-header__search"
            :class="{ 'the-header__search--scroll': isSearchScroll }"
        >
            <div class="the-header__search-wrap">
                <the-header-search></the-header-search>
            </div>
        </div>
    </header>
</template>

<style lang="scss">
    @import "~@tb/style/TopNavigationMenu.scss";
    @import "~@tb/style/HeaderStatusOrder.scss";
    @import "~@tb/style/SelectCityConfirmation.scss";
    @import "~@tb/style/SelectCity.scss";

    .the-header {
        position: relative;
        z-index: 31;
    }

    .the-header__search {
        width: 100%;
        left: 0;
        bottom: 0;
        height: 56px;
        .the-header__search-wrap {
            top: 0;
            transition: 0.3s ease all;
        }
        @include bp($bp-desktop-sm) {
            display: none;
        }
    }

    .the-header__search--scroll {
        .the-header__search-wrap {
            position: fixed;
            top: 55px;
            left: 0;
            border-bottom-color: var(--primary-border--light);
        }
    }

    .the-header__search-wrap {
        width: 100%;
        padding: 6px 16px 8px;
        background-color: #fff;
        z-index: 60;
        border-bottom: 1px solid transparent;
    }
</style>
