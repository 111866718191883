const error = process.env.NODE_ENV !== "production" ? console.warn : () => {};

/**
 * Мапирует объект, используя конфигурацию маппинга
 *
 * @param  {Object} object Мапируемый объект
 * @param  {Object} mapping Конфигурация маппинга
 * @param  {Object} options Дополнительные настройки маппинга (preventWarns - не показывать ошибки уровня warning в консоле)
 * @return {Object} Смапированный объект
 */
export function mapObject(object, mapping, { preventWarns = false } = {}) {
    const warns = [];
    const errors = [];
    const result = {};

    if (typeof object !== "object" || object === null) {
        errors.push("Невозможно выполнить маппинг для переданного object");
        return undefined;
    }

    const getValue = (key) => {
        if (!Object.prototype.hasOwnProperty.call(object, key)) {
            warns.push(`Объект не имеет свойства ${key}`);
        }
        return object[key];
    };

    Object.keys(mapping).forEach((key) => {
        const mappingRule = mapping[key];

        // Если правило маппинга строка
        if (typeof mappingRule === "string") {
            result[key] = getValue(mappingRule);
            return;
        }

        // Если правило маппинга массив
        if (Array.isArray(mappingRule)) {
            const mappingFunc = mappingRule[mappingRule.length - 1];
            if (typeof mappingFunc !== "function") {
                errors.push(
                    `Не поддерживаемый тип маппинга для ${key}. Последним элементом ожидается функция.`
                );
                return;
            }
            const args = [];
            for (let i = 0; i < mappingRule.length - 1; ++i) {
                args.push(getValue(mappingRule[i]));
            }
            result[key] = mappingFunc(...args);
            return;
        }

        errors.push(`Не поддерживаемый тип маппинга для ${key}`);
    });

    let log = "";
    if (errors.length) {
        log += " Ошибки: " + errors.join(", ");
    }
    if (warns.length && !preventWarns) {
        log += " Предупреждения: " + warns.join(", ");
    }
    if (log.length > 0) {
        error(`Маппинг завершен с ошибками ${log}`, { object, mapping });
    }
    return result;
}

/**
 * Маппирует массив объектов
 *
 * @param  {Array} array Массив объектов
 * @param  {Object|Function} mapping Объект конфигурации или функция маппинга
 * @return {Array} Массив смаппированных объектов
 */
export function mapArray(array, mapping) {
    if (array === null || array === undefined) {
        return [];
    }
    if (typeof mapping === "function") {
        return array.map(mapping);
    }
    return array.map((item) => mapObject(item, mapping));
}

/**
 * Мапирует объект {current_page, per_page, total, items} в объект {number, size, total, items}
 *
 * @return {Object} Смапированный объект {number, size, total, items}
 */
export function mapPage(page, mapping) {
    if (typeof page !== "object" || page === null) {
        error(
            "Метод .mapPage() ожидает на вход объект {current_page, per_page, total, items}",
            { page, mapping }
        );
        return [];
    }
    return mapObject(page, {
        number: "current_page",
        size: "per_page",
        total: "total",
        items: ["items", (items) => mapArray(items, mapping)],
    });
}
