import { mapFaq } from "@/api/mapping/optional";

export default function ($api) {
    return {
        /**
         * Получение вопросов и ответов
         * @returns { Object }
         */
        getFaq() {
            return $api.get("api/faq/all?paging[per_page]=35").then(mapFaq);
        },
        /**
         * Отправить обратную связь
         * @returns { Object }
         */
        sendFeedback({ name, email, phone, theme, msg }) {
            return $api.post("api/form/feedback/", {
                name,
                email,
                phone,
                theme,
                msg,
            });
        },
    };
}
