<script>
    import SelectCity from "@/components/SelectCity";
    import HeaderStatusOrder from "@/components/HeaderStatusOrder";

    export default {
        name: "TopNavigationMenu",
        components: { HeaderStatusOrder, SelectCity },
        props: {
            menu: Array,
            city: String,
            isMobile: Boolean,
        },
        methods: {
            clickHandler() {
                this.$emit("on-click");
            },
        },
    };
</script>

<template>
    <nav class="c-nav-menu" :class="{ 'c-nav-menu--mobile': isMobile }">
        <div class="c-nav-menu__city">
            <select-city :city="city"></select-city>
        </div>

        <router-link
            v-for="(menuItem, id) in menu"
            :key="id"
            :to="{ name: menuItem.link }"
            class="c-nav-menu__link outline"
            @click.native="clickHandler"
        >
            {{ menuItem.name }}
        </router-link>

        <div class="c-nav-menu__order">
            <header-status-order></header-status-order>
        </div>

        <slot></slot>
    </nav>
</template>
