<script>
    import { mapState } from "vuex";
    import TheSvgIcon from "@main/packages/TheSvgIcon";

    export default {
        name: "TheNavMobile",
        components: {
            TheSvgIcon,
        },
        computed: {
            ...mapState("Root", ["cart"]),
            hasProduct() {
                return this.cart.number > 0;
            },
        },
    };
</script>

<template>
    <div class="app-mobile-nav">
        <div class="l-container">
            <div class="app-mobile-nav__list">
                <router-link :to="{ name: 'Home' }" class="app-mobile-nav__item">
                    <the-svg-icon name="home" :size-w="24"></the-svg-icon>
                    <div class="app-mobile-nav__name">Главная</div>
                </router-link>
                <router-link :to="{ name: 'Catalog' }" class="app-mobile-nav__item">
                    <the-svg-icon name="menu_list" :size-w="24"></the-svg-icon>
                    <div class="app-mobile-nav__name">Каталог</div>
                </router-link>
                <router-link
                    :to="{ name: 'Order' }"
                    class="app-mobile-nav__item"
                    :class="{ 'app-mobile-nav__item--disabled': !hasProduct }"
                >
                    <div v-if="hasProduct" class="app-mobile-nav__number" ref="cartIcon">
                        {{ cart.number }}
                    </div>
                    <the-svg-icon name="cart" :size-w="24"></the-svg-icon>
                    <div class="app-mobile-nav__name">Корзина</div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .app-mobile-nav {
        position: fixed;
        width: 100%;
        left: 0;
        bottom: 0;
        background: #fff;
        box-shadow: 0 0 16px rgba(0, 0, 0, 0.16);
        z-index: 30;
        padding: 8px 0 6px;

        @include bp($bp-desktop-sm) {
            display: none;
        }

        .l-container {
            overflow: visible;
        }
    }

    .app-mobile-nav__list {
        display: flex;
        justify-content: space-around;
    }

    .app-mobile-nav__item {
        position: relative;
        text-align: center;

        svg {
            fill: #000;
        }
    }

    .app-mobile-nav__item--disabled {
        pointer-events: none;
    }

    .app-mobile-nav__name {
        margin-top: 2px;
        font-size: 10px;
        line-height: 12px;
        color: var(--primary-text);
    }

    .app-mobile-nav__number {
        position: absolute;
        top: -3px;
        right: 4px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        font-size: 11px;
        font-weight: 600;
        line-height: 16px;
        color: #fff;
        opacity: 1;
        text-align: center;
        background-color: var(--danger-text--light);

        &.is-animated {
            animation: grow-shrink 0.35s ease 1;
        }

        &.is-active {
            opacity: 1;
        }
    }

    @keyframes grow-shrink {
        30% {
            transform: scale(1.4);
        }
        65% {
            transform: scale(0.8);
        }
        100% {
            transform: scale(1);
        }
    }
</style>
