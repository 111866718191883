<script>
    import TheSvgIcon from "@main/packages/TheSvgIcon";

    export default {
        name: "SelectCity",
        components: {
            TheSvgIcon,
        },
        props: {
            city: String,
        },
        methods: {
            showPopup() {
                this.$popup.show("modal-select-city", { city: this.city });
            },
        },
    };
</script>

<template>
    <button class="select-city outline" @click="showPopup">
        <the-svg-icon name="geo" class="select-city__ico" :size-w="24"></the-svg-icon>
        <span class="select-city__title">{{ city }}</span>
        <the-svg-icon
            class="select-city__arrow"
            name="arrow_right"
            is-common
            :size-w="16"
            :size-h="24"
        ></the-svg-icon>
    </button>
</template>
