<script>
    import TheFooterBottom from "@tb/components/TheFooterBottom";
    import TheFooterMain from "@tb/components/TheFooterMain";

    export default {
        name: "TheFooter",
        components: {
            TheFooterMain,
            TheFooterBottom,
        },
    };
</script>

<template>
    <footer class="the-footer">
        <div class="l-container">
            <the-footer-main></the-footer-main>
        </div>
        <the-footer-bottom></the-footer-bottom>
        <div class="js-footer-stop-divider"></div>
    </footer>
</template>

<style lang="scss">
    .the-footer {
        background-color: var(--primary);
    }
</style>
