import Vue from "vue";
import Vuex from "vuex";
import Root from "./modules/root";
import Product from "./modules/product";
import Profile from "./modules/profile";

Vue.use(Vuex);

export const createStore = (modules) => {
    return new Vuex.Store({
        modules: { Root, Product, Profile, ...modules },
    });
};
